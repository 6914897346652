<template>
<ProfileCard/>
</template>

<script>
import ProfileCard from "@/components/sponsor/ProfileCard";
export default {
  name: "ScholarshipSponsorDetails",
  components: {ProfileCard}
}
</script>

<style scoped>

</style>