<template>
  <div class="xl:mt-[5.78px] h-full">
    <div class="xl:mx-auto w-full xl:max-w-5xl h-full">
      <div class="bg-white px-4 sm:px-8 py-8">
         <div class="inline-flex items-center ">
           <h2 class="text-[20px]">Balances</h2>
           <select id="organisation_country"  name="organisation_country" autocomplete="country-name" class=" w-[100px] ml-3 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
             <option value="" selected>USD</option>
           </select>
         </div>
        <div class="mt-5 text-[30px] font-medium">
          USD 0.00
        </div>
        <p class="text-sm text-gray-500">Total balance in base currency of <span class="text-indigo-500">USD</span> </p>

        <ul role="list" class="divide-y divide-gray-200 text-gray-500 text-sm md:max-w-[50%] mt-10">
          <li  class=" py-4">
             <div class="flex justify-between">
               <p>Total Cash Inflow</p>
               <span>USD 0</span>
             </div>
          </li>

          <li  class="py-4">
            <div class="flex justify-between">
              <p>Total Cash Outflow</p>
              <span>USD 0</span>
            </div>
          </li>
        </ul>

      </div>

      <div class="bg-white px-4 sm:px-8 py-8 mt-8">
        <h3 class="mb-[40px]">Payments  <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
       100
  </span></h3>

        <div class="flex flex-col sm:flex-row mb-[21px] justify-between  ">

          <div class="relative z-0 inline-flex shadow-sm rounded-md">
            <router-link :to="{name:'Inflow'}"   type="button" :class="`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none`  ">
              Inflow
            </router-link>
            <router-link :to="{name:'paymentRequest'}"   type="button" :class="`relative inline-flex items-center px-4 py-2  border-y border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none`  ">
              Payment Request
            </router-link>
            <router-link :to="{name:'Outflow'}"  type="button" :class="`-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none  `  ">
              Outflow
            </router-link>
          </div>

          <div class="md:w-[40%] my-4 sm:my-0" >
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <input type="text" name="search" id="search" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" placeholder="Search" />
            </div>
          </div>

          <div class="flex">
                  <button type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
                      <path d="M4 6H14M1.5 1H16.5M6.5 11H11.5" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="ml-[13px]">Filters</span>
                  </button>

                  <button type="button" class="ml-[12px] inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    <span class="inline-block">Export</span>
                  </button>

          </div>

        </div>
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted} from "vue";
import {getPartnerPayments} from "@/store/payments";

export default {
  name: "PaymentsIndex",
}
</script>

<style scoped>

</style>