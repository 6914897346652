<template>
  <div class="flex items-center p-4 border border-gray-200 rounded-lg">
    <RoundProfileImage :size="12" username="Jane Doe"></RoundProfileImage>
    <div class="text-gray-500 ml-4">
      <p>Steward Scholars</p>
      <p>olivia@untitledui.com</p>
    </div>
  </div>
</template>
<script>
import RoundProfileImage from "@/components/elements/Images/RoundProfileImage.vue"

export default {
  name: 'UserProfileSummary',
  components: {RoundProfileImage}
}
</script>
