<template>
  <div class="space-y-8 divide-gray-200 sm:space-y-5">
    <div >
      <div class="my-5 px-4 sm:px-0">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Manage your team
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Invite team members to help you review scholarship applications, create new scholarships and more.
        </p>
      </div>
    </div>
    <div class="bg-light p-4">
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs" class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
          <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <a v-for="tab in tabs" :key="tab.name" :href="tab.href" :class="[tab.current ? 'border-black text-black' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm']" :aria-current="tab.current ? 'page' : undefined">
              <span>{{ tab.name }}</span>
            </a>
          </nav>
        </div>
      </div>

      <div class="sm:grid grid-cols-12 gap-6 mt-4">
        <div class="col-span-12 sm:col-span-8 ">
          <label for="headline" class="block text-sm font-medium text-gray-700 sr-only">Organisation Name</label>
          <input type="text"  readonly  id="headline" placeholder="Email"  class="mt-1 block w-full bg-gray-50 rounded-md border-gray-300 shadow-sm outline-0 focus:ring-indigo-500 ring-0 sm:text-sm">
        </div>
        <div class="col-span-12 sm:col-span-4 mt-4">
          <select id="organisation_country"  name="organisation_country" autocomplete="country-name" class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
            <option value="" selected>Operations</option>
          </select>
        </div>

        <div class="col-span-12  mt-4">
          <label for="organisation_bio" class="block text-sm font-medium text-gray-70 sr-only">Personalized message (Optional)</label>
          <textarea   maxlength="275" id="organisation_bio" name="organisation_bio" rows="3"
                     class="mt-1 block w-full rounded-md border-gray-300 shadow-sm
           focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Personalized message (Optional)"></textarea>
        </div>

        <button type="submit" class=" mt-4 sm:mt-0 sm:ml-3 sm:col-span-3 col-span-12 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-900 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Send Invite
        </button>
      </div>
    </div>
    <div class="mt-6">
      <div class="flex justify-between items-center flex-col sm:flex-row p-4 sm:p-0">
        <div class="text-xl text-black-500 font-medium mb-7 flex items-center"> Teams <div class="bg-[#E7E7E7] py-1 px-2 ml-2 text-center rounded-full text-sm min-w-[36px]">1</div></div>
        <div class="border mt-3 md:mt-0 md:mx-3 border-gray-300 bg-white w-full sm:w-[50%] h-11 px-[10px] text-gray-500 placeholder-gray-500 flex space-x-2 items-center rounded-lg"><img src="/img/search.c1845c97.svg"><input class="h-full w-full outline-none border-none shadow-none text-base font-normal" placeholder="Search"></div>
      </div>

      <div class="mt-6 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full">
          <div class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalise tracking-wider">
                 Member
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalise tracking-wider">
                  Role
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalise tracking-wider">
                  Date joined
                </th>

                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalise tracking-wider">
                  Actions
                </th>
              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
              <tr>
                <td class="px-6 py-4 whitespace-nowrap w-[55%]">
                  <div class="flex items-center">
                    <div class="w-8 h-8">
                      <div class="rounded-full w-8 h-8 flex justify-center items-center overflow-hidden">
                        <div
                            class="rounded-full w-8 h-8 border border-gray-300 flex justify-center items-center overflow-hidden">
                          SK
                        </div>
                      </div>
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-500">Samson Kasumba</div>
                      <div class="text-sm text-gray-500">mugenyihenry2@gmail.com</div>
                    </div>
                  </div>
                </td>
                <td>Owner</td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm text-gray-900">Nov 15, 2022, 12:41 PM</div>
                </td>

                <td>
                  <div class="flex text-sm">
                    <button class="text-red-700">Delete</button>
                    <button class="ml-3 text-gray-800">Edit</button>
                  </div>
                </td>

              </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { CreditCardIcon, OfficeBuildingIcon, UserIcon, UsersIcon } from '@heroicons/vue/solid'

const tabs = [
  { name: 'Invite by email', href: '#', icon: UserIcon, current: true },
  { name: 'Invite by link', href: '#', icon: OfficeBuildingIcon, current: false },
]
export default {
  name: "TeamSettings",
  setup(){
    return {tabs}
  }
}
</script>

<style scoped>

</style>