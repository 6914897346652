<template>
  <div v-if="activeScholarship" >
    <img :src="activeScholarship.image" class="w-full" alt="">
  </div>

  <div v-if="activeScholarship" class="border border-gray-100 mt-12 md:p-8 rounded-2xl">
    <div class="bg-gray-100 p-3 flex justify-between flex-col sm:flex-row">
      <div class="flex flex-col">
        <span class="text-[10px]"> <span v-if="user.user_type !== 'scholar'">Target amount </span> <span v-else> Amount </span> </span>
        <span class="text-[30px] font-medium">{{activeScholarship.currency.short_code}}  {{commaSeparator(activeScholarship.amount)}}</span>
      </div>

      <div class="flex flex-col my-4 sm:my-0" v-if="user.user_type !== 'scholar'" >
        <span class="text-[10px]">Amount Raised</span>
        <span class="text-[30px] font-medium">{{activeScholarship.currency.short_code}}  0</span>
      </div>

      <div class="flex justify-between">

      <button v-if="user.user_type === 'partner'"  @click="$router.push({name:'sponsor-scholarship', params:{id:activeScholarship.id}})"  type="button" class="self-center inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
        <span>Fund Scholarship</span>
      </button>

        <button v-if="user.user_type === 'partner'" @click="showPayoutModal = true"  type="button" class=" ml-[13px]  self-center inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-gray-500">
          Withdraw funds
        </button>
        <Withdraw :show="showPayoutModal" @close="showPayoutModal = false"/>
      </div>
    </div>

    <div class="flex mt-4 justify-between flex-wrap">
      <div class="flex flex-col">
        <span class="text-[12px] text-gray-600">Application status</span>
        <span class="text-[12px] inline-flex mt-[6px] items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 self-start">Open</span>
      </div>
      <div class="flex flex-col" v-if="activeScholarship.application_deadline">
        <span class="text-[12px] text-gray-600">Application deadline</span>
        <span>{{shortFormDate(activeScholarship.application_deadline)}}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-[12px] text-gray-600">Scholarships to award</span>
        <span>{{activeScholarship.winners}}</span>
      </div>

      <div class="flex flex-col mt-4 md:mt-0">
        <span class="text-[12px] text-gray-600">Education level</span>
        <span>{{activeScholarship.eligibility?.education_level}}</span>
      </div>
    </div>
  </div>

  <div class="my-8" v-if="activeScholarship">
    <h2 class="text-2xl md:text-3xl">Description</h2>
    <div class="mt-4 text-[18px] text-gray-500 leading-40 content">
      <p>
        {{activeScholarship.description}}
      </p>
    </div>
  </div>
</template>

<script>
import {activeScholarship} from "@/store/scholarship";
import {longFormDate,shortFormDate} from "@/helpers/date";
import {commaSeparator} from "@/helpers/display";
import Withdraw from "@/components/WithdrawModal";
import {user} from "@/store/auth";
import {ref} from 'vue'
export default {
  name: "ScholarshipDetails",
  components:{Withdraw},
  setup(){
    const showPayoutModal = ref(false)
    return {activeScholarship,longFormDate,shortFormDate,commaSeparator,showPayoutModal,user}
  }
}
</script>

<style scoped>
.content p{
  margin: 28px 0;
}
</style>