<template>
  <navbar></navbar>
  <div class="sm:px-[80px] py-[50px] py-[86px] mx-auto max-w-[100rem]" v-if="activePartner">
    <div class="flex grow items-center mb-4 lg:mb-0 mt-[86px] px-6 ">
      <RoundProfileImage :size="12" :url="activePartner?.logo" :username="activePartner?.business_name"  />

      <div class="ml-5 ">
        <span class="text-4xl">{{activePartner.business_name}}</span>
        <p class="text-gray-500 mt-2" >{{activePartner.organisation_headline}}</p>
      </div>
    </div>
    <div class="my-[30px] px-4">
      <p class="text-gray-500 text-lg">
          {{activePartner.organisation_bio}}
      </p>
    </div>
    <div class="flex w-full px-4">
      <a target="_blank" :href="activePartner.website"  v-if="activePartner.website" type="button" class="inline-flex mr-4 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M7.93997 14.5138C7.31997 14.2838 6.76997 13.8338 6.41997 13.1938C5.61997 11.7338 6.10997 9.8338 7.52997 8.9538L9.86996 7.50378C11.28 6.62378 13.1 7.10377 13.9 8.55377C14.7 10.0138 14.21 11.9138 12.79 12.7938L12.48 13.0138" stroke="#0086C9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.11 9.45312C16.73 9.68313 17.28 10.1331 17.63 10.7731C18.43 12.2331 17.94 14.1331 16.52 15.0131L14.1799 16.4631C12.7699 17.3431 10.9499 16.8631 10.1499 15.4131C9.34995 13.9531 9.83995 12.0531 11.2599 11.1731L11.57 10.9531" stroke="#0086C9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0086C9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
        <span class="ml-[13px] hidden md:inline-block">Website</span>
      </a>
      <a target="_blank" :href="activePartner.facebook"  v-if="activePartner.facebook" type="button" class="inline-flex  mr-4 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clip-path="url(#clip0_3596_17430)">
            <path d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z" fill="#1877F2"/>
            <path d="M16.6711 15.4688L17.2031 12H13.875V9.75C13.875 8.80102 14.34 7.875 15.8306 7.875H17.3438V4.92188C17.3438 4.92188 15.9705 4.6875 14.6576 4.6875C11.9166 4.6875 10.125 6.34875 10.125 9.35625V12H7.07812V15.4688H10.125V23.8542C11.3674 24.0486 12.6326 24.0486 13.875 23.8542V15.4688H16.6711Z" fill="white"/>
          </g>
          <defs>
            <clipPath id="clip0_3596_17430">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <span class="ml-[13px] hidden md:inline-block">Facebook</span>
      </a>
      <a target="_blank" :href="activePartner.twitter"  v-if="activePartner.twitter" type="button" class="inline-flex mr-4 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M7.54752 21.7512C16.6042 21.7512 21.5578 14.2479 21.5578 7.74101C21.5578 7.52789 21.5578 7.31573 21.5434 7.10453C22.507 6.40748 23.3389 5.54441 24 4.55573C23.1014 4.95413 22.148 5.21528 21.1718 5.33045C22.1998 4.71514 22.9692 3.74723 23.3366 2.60693C22.3701 3.18054 21.3126 3.58475 20.2099 3.80213C19.4675 3.01271 18.4856 2.48997 17.4162 2.31481C16.3468 2.13966 15.2494 2.32184 14.294 2.83318C13.3385 3.34452 12.5782 4.1565 12.1307 5.14348C11.6833 6.13045 11.5735 7.23739 11.8186 8.29301C9.8609 8.19481 7.94576 7.68604 6.19745 6.79973C4.44915 5.91343 2.90676 4.66939 1.6704 3.14837C1.04073 4.23236 0.847872 5.5156 1.1311 6.73679C1.41433 7.95798 2.15234 9.02532 3.19488 9.72149C2.41123 9.69853 1.64465 9.48712 0.96 9.10517V9.16757C0.960311 10.3044 1.35385 11.4062 2.07387 12.2859C2.79389 13.1657 3.79606 13.7693 4.9104 13.9944C4.18548 14.1922 3.42487 14.2211 2.68704 14.0789C3.00181 15.0573 3.61443 15.9128 4.43924 16.5259C5.26405 17.139 6.25983 17.479 7.28736 17.4985C6.26644 18.3009 5.09731 18.8942 3.84687 19.2444C2.59643 19.5947 1.28921 19.6949 0 19.5394C2.25183 20.9844 4.87192 21.7509 7.54752 21.7474" fill="#1DA1F2"/>
        </svg>
        <span class="ml-[13px] hidden md:inline-block">Twitter</span>
      </a>
      <a :href="activePartner.linkedin"  v-if="activePartner.linkedin" type="button" class="inline-flex mr-4 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
          <g clip-path="url(#clip0_3596_19350)">
            <path d="M22.7283 0H2.27167C1.80179 0 1.35116 0.186657 1.01891 0.518909C0.686657 0.851161 0.5 1.30179 0.5 1.77167V22.2283C0.5 22.6982 0.686657 23.1488 1.01891 23.4811C1.35116 23.8133 1.80179 24 2.27167 24H22.7283C23.1982 24 23.6488 23.8133 23.9811 23.4811C24.3133 23.1488 24.5 22.6982 24.5 22.2283V1.77167C24.5 1.30179 24.3133 0.851161 23.9811 0.518909C23.6488 0.186657 23.1982 0 22.7283 0ZM7.65333 20.445H4.045V8.98333H7.65333V20.445ZM5.84667 7.395C5.43736 7.3927 5.03792 7.2692 4.69873 7.04009C4.35955 6.81098 4.09584 6.48653 3.94088 6.10769C3.78591 5.72885 3.74665 5.31259 3.82803 4.91145C3.90941 4.51032 4.1078 4.14228 4.39816 3.85378C4.68851 3.56529 5.05782 3.36927 5.45947 3.29046C5.86112 3.21165 6.27711 3.25359 6.65495 3.41099C7.03279 3.56838 7.35554 3.83417 7.58247 4.17481C7.80939 4.51546 7.93032 4.91569 7.93 5.325C7.93386 5.59903 7.88251 5.87104 7.77901 6.1248C7.67551 6.37857 7.52198 6.6089 7.32757 6.80207C7.13316 6.99523 6.90185 7.14728 6.64742 7.24915C6.393 7.35102 6.12067 7.40062 5.84667 7.395ZM20.9533 20.455H17.3467V14.1933C17.3467 12.3467 16.5617 11.7767 15.5483 11.7767C14.4783 11.7767 13.4283 12.5833 13.4283 14.24V20.455H9.82V8.99167H13.29V10.58H13.3367C13.685 9.875 14.905 8.67 16.7667 8.67C18.78 8.67 20.955 9.865 20.955 13.365L20.9533 20.455Z" fill="#0A66C2"/>
          </g>
          <defs>
            <clipPath id="clip0_3596_19350">
              <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
            </clipPath>
          </defs>
        </svg>
        <span class="ml-[13px] hidden md:inline-block">LinkedIn</span>
      </a>
      <a target="_blank" :href="activePartner.instagram"  v-if="activePartner.instagram" type="button" class="mr-4 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
        <img class="w-[28px] h-[28px]" src="../../assets/ui-icons/instagram.jpg" alt="">
        <span class="ml-[13px] hidden md:inline-block">Instagram</span>
      </a>

    </div>

    <div class="px-4">
      <h3 class="mt-[65px] text-2xl font-medium">Scholarship board</h3>

      <div class="flex mb-[21px] justify-between mt-8">
        <div class="md:w-[40%]">
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <input type="email" name="email" id="email" @input="getScholarshipsByPartner(activePartner.id,1,searchValue)" v-model="searchValue" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" placeholder="Search" />
          </div>
        </div>

<!--        <div class="flex h">-->
<!--          <button type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">-->
<!--              <path d="M4 6H14M1.5 1H16.5M6.5 11H11.5" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            </svg>-->
<!--            <span class="ml-[13px] hidden md:inline-block">Filters</span>-->
<!--          </button>-->


<!--        </div>-->

      </div>

      <h3 class="mt-8 text-2xl font-medium">All Scholarships</h3>

      <div v-if="scholarshipList.length > 0" class="grid grid-cols-2 my-6 gap-11">
          <div class="sm:col-span-1 col-span-2" v-for="scholarship in scholarshipList" :key="scholarship.id">
            <ScholarshipDetailSummary :scholarship="scholarship" />
          </div>
      </div>
       <div v-if="scholarshipList.length>0">
       <Pagination/>
     </div>
      <EmptyState v-if="scholarshipList.length === 0" title="No Scholarship records yet" description="Records will appear here once scholarships are available ">
        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" fill="none">
          <rect x="4.5" y="4.5" width="48" height="48" rx="24" fill="#F2F4F7"/>
          <path d="M26.55 19.0313L20.53 22.9613C18.6 24.2213 18.6 27.0413 20.53 28.3013L26.55 32.2313C27.63 32.9413 29.41 32.9413 30.49 32.2313L36.48 28.3013C38.4 27.0413 38.4 24.2313 36.48 22.9713L30.49 19.0413C29.41 18.3213 27.63 18.3213 26.55 19.0313Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M22.1301 29.5781L22.1201 34.2681C22.1201 35.5381 23.1001 36.8981 24.3001 37.2981L27.4901 38.3581C28.0401 38.5381 28.9501 38.5381 29.5101 38.3581L32.7001 37.2981C33.9001 36.8981 34.8801 35.5381 34.8801 34.2681V29.6281" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M37.8999 31.5V25.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <rect x="4.5" y="4.5" width="48" height="48" rx="24" stroke="#F9FAFB" stroke-width="8"/>
        </svg>
      </EmptyState>

    </div>
  </div>
</template>

<script>
import navbar from "@/components/Navbar";
import ScholarshipDetailSummary from "@/components/scholarshipDetailSummary";
import Pagination from "@/components/Pagination";
import EmptyState from "@/components/state/EmptyState";
import {useRoute} from "vue-router";
import {onMounted,watch,ref} from "vue";
import {getPartnerBySlug,activePartner} from "@/store/partner";
import {getScholarshipsByPartner,scholarshipList} from "@/store/scholarship";
import RoundProfileImage from "@/components/elements/Images/RoundProfileImage";
export default {
  name: "PartnersPage",
  components:{navbar,ScholarshipDetailSummary,Pagination,EmptyState,RoundProfileImage},
  setup(){
    const route = useRoute();
    const searchValue = ref('');
    onMounted(()=>{
       getPartnerBySlug(route.params.id);
    });
    watch(activePartner, (val)=>{
      getScholarshipsByPartner(val.id);
    })


    return{activePartner,getScholarshipsByPartner,scholarshipList,searchValue}
  }
}
</script>

<style scoped>

</style>