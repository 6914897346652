
import { defineComponent } from "vue";

export default defineComponent({
    name: 'AnimatedHamburger',
    props: {
        activeOnMount: {
            type: Boolean,
            default: false
        },
        active: Boolean
    },
});
