
import {activeScholarship,getScholarshipById,deleteScholarship} from "@/store/scholarship";
import {onBeforeMount, onMounted, ref} from "vue";
import {useRoute} from "vue-router"
import {longFormDate} from "@/helpers/date";
import RoundProfileImage from "@/components/elements/Images/RoundProfileImage.vue";
import {share} from "@/helpers/share";
import {ui} from "@/helpers/api";
import DeleteAlert from '@/components/DeleteAlert.vue';
import router from "@/router";
import {user} from "@/store/auth";
import TabGroup from "@/components/elements/TabGroup.vue";
import DropdownButton from "@/components/elements/DropdownButton.vue";
export default {
  name: "ShowScholarship",
  components:{RoundProfileImage,DeleteAlert,TabGroup,DropdownButton},
  setup(){
    const route = useRoute();
    const showDeleteModal = ref(false);
    const tabs: any = ref([]);
    const currentTab = ref();
    const options = ref([
      {
        name:'Put scholarship on hold',
        action:'hold'
      },
      {
        name:'Close Scholarship',
        action:'close'
      }
    ]);


    onBeforeMount(()=>{
      if (user.value.user_type === 'partner') {
         tabs.value = ['Details', 'Sponsorships','Payment requests','Applicants','winners']
      }
      if (user.value.user_type === 'scholar'){
        tabs.value = ['Details','My application']
      }
      currentTab.value = tabs.value[0];
    })

    onMounted(()=>{
      activeScholarship.value = null;
      getScholarshipById(route.params.id)
    })

    const callback = () => {
      showDeleteModal.value = false;
      router.back();
    }
 const setCurrentTab = (tab: string) =>  {
      currentTab.value = tab;
      const obj = [
        {
          name:'Details',
          href : 'scholarshipDetails'
        },
        {
          name:'Sponsorships',
          href : 'scholarshipSponsorship'
        },
        {
          name:'Applicants',
          href : 'scholarshipApplicants'
        },
        {
          name:'Details',
          href : 'scholarshipDetails'
        },
        {
          name:'My application',
          href : 'MyScholarshipSponsorship'
        },
      ]
      obj.forEach(el => {
        if (el.name === tab){
        router.push({name:el.href});
        }
      })
    }

    return {activeScholarship,
      longFormDate,currentTab,
      share,ui,showDeleteModal,
      deleteScholarship,callback,
      user,tabs,setCurrentTab,options}
  }

}
