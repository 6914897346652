
import DashboardHeader from "@/components/layout/dashboard/DashboardHeader.vue";
// import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
// import { mapActions, mapGetters, mapMutations } from "vuex";

// import HeaderView from "@/components/layout/dashboard/Header.vue";
// import WorkspaceSidebar from "@/components/layout/dashboard/WorkspaceSidebar.vue";
import NavigationSidebar from "@/components/layout/dashboard/NavigationSidebar.vue";
import {user} from "@/store/auth";
// import { keys } from "@/keys";
// import NewPayoutModal from "@/components/domains/payments/NewPayoutModal.vue";
// import { links } from "../router/index";

export default defineComponent({
  name: "DashboardView",
  components: {
    // Navbar,
    DashboardHeader,
    // HeaderView,
    // WorkspaceSidebar,
    NavigationSidebar,
    // NewPayoutModal,
  },
  beforeMount() {
    if (!user.value){
      window.location.href = '/login'
    }
    // if (!user.value.email_verified_at){
    //   window.location.href = '/verify-email'
    // }
  },
  computed: {
    // ...mapGetters(["currentUser", "payoutModal", "isAdmin"]),
    heading() {
      return this.$route.meta.heading;
    },
  },
  data() {
    return {
      sidebarActive: false,
      currentUser: true,
    };
  },
  methods: {
    // ...mapActions(["whoAmI", "fetchBankDetails", "fetchBanks"]),
    // ...mapMutations(["togglePayoutModal"]),
    toggleSidebar() {
      this.sidebarActive = !this.sidebarActive;
    },
  },
  // mounted() {
  //   // fetch user info
  //   this.whoAmI();

  //   this.fetchBankDetails();

  //   // fetch supported banks list
  //   this.fetchBanks();
  // },
  watch: {
    $route() {
      this.sidebarActive = false;
    },
  },
});
