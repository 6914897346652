<template>
  <button @click="$router.back()" type="button" class="inline-flex  items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="20" viewBox="0 0 20 20" width="20"><g stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path d="m7.97484 4.94531-5.05834 5.05829 5.05834 5.0584"/><path d="m17.0831 10h-14.02499"/></g></svg>
    <span>Back to Description</span>
  </button>

  <h2 class="text-xl my-10">Scholarship Eligibility</h2>
  <form id="eligibilityForm" @submit.prevent = submitScholarshipEligibility($event,moveForward,activeScholarship.id) action="#" method="POST">

    <div class="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div class="sm:col-span-3  col-span-6">

        <div class="flex mt-4">
          <div class="relative flex items-start mr-20">
            <div class="flex items-center h-5">
              <input value="all" id="student_type_all" v-model="studentType" aria-describedby="comments-description" name="student_type" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
            </div>
            <div class="ml-3 text-sm">
              <label for="student_type_all" class="font-medium text-gray-700">Available to multiple scholar/students</label>
            </div>
          </div>
        </div>


        <div class="mt-4" v-if="studentType == 'all'">
          <label for="frequency" class="block text-sm font-medium text-gray-700">
            Educational level
          </label>
          <div class="mt-1">

            <input type="hidden" name="education_level" v-model="education_level">
            <SelectElement :border="true"  @updated='val => education_level = val'  :items="['Any','High School','Undergraduate','Bachelor’s degree program','Master’s degree program']" :selected="0"/>
          </div>
        </div>

        <div class="flex mt-4">
          <div class="relative flex items-start mr-20">
            <div class="flex items-center h-5">
              <input value="only" id="student_type_only" v-model="studentType" aria-describedby="comments-description" name="student_type" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 " />
            </div>
            <div class="ml-3 text-sm">
              <label for="student_type_only" class="font-medium text-gray-700">Only available to a specific school</label>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-6 gap-6 mt-4" v-if="studentType === 'only'">
          <div class="col-span-6 sm:col-span-3">
            <label for="organisation_country" class="block text-sm font-medium text-gray-700">Country</label>
            <input type="hidden" name="education_level" v-model="country">
            <SelectElement :border="true"  @updated='val => country = val'  :items="countries" :selected="0"/>

          </div>
          <div class="col-span-6 sm:col-span-3">
            <label for="state" class="block text-sm font-medium text-gray-700">State</label>
            <input type="text"  name="state" id="state" autocomplete="family-name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
          </div>


          <div class="mt-1">
            <label for="frequency" class="block text-sm font-medium text-gray-700">
              Educational level
            </label>
            <div class="mt-1">

              <input type="hidden" name="education_level" v-model="education_level">
              <SelectElement :border="true"  @updated='val => education_level = val'  :items="['Any','High School','Undergraduate','Bachelor’s degree program','Master’s degree program']" :selected="0"/>

            </div>
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="organisation_address" class="block text-sm font-medium text-gray-700">School</label>
            <input type="text"  name="school" id="school" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
          </div>

        </div>



        <div class="mt-4">
          <label for="description" class="block text-sm font-medium text-gray-700">
            Describe Ideal applicant
          </label>
          <div class="mt-1">
            <textarea id="description"  name="applicant_description" placeholder="Enter a description..." rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
          </div>
        </div>

        <div class="mt-4">
          <label for="description" class="block text-sm font-medium text-gray-700">
            Scholars submission requirements
          </label>

          <div class="mt-4 grid grid-cols-4">
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="comments" v-model="hasEssay" value="essay" aria-describedby="comments-description" name="submission_requirements[]" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
              </div>
              <div class="ml-3 text-sm">
                <label for="comments" class="font-medium text-gray-700">Essay</label>
              </div>
            </div>
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="candidates" v-model="hasLink" value="link" aria-describedby="candidates-description" name="submission_requirements[]" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
              </div>
              <div class="ml-3 text-sm">
                <label for="candidates" class="font-medium text-gray-700">Link</label>
              </div>
            </div>
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="offers"  v-model="hasFile" value="file" aria-describedby="offers-description" name="submission_requirements[]" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
              </div>
              <div class="ml-3 text-sm">
                <label for="offers" class="font-medium text-gray-700">File</label>
              </div>
            </div>

          </div>
        </div>




        <div class="mt-4" v-if="hasEssay">
          <label for="essay_requirements" class="block text-sm font-medium text-gray-700">
            Eassy requirements
          </label>
          <div class="mt-1">
            <textarea id="essay_requirements" name="essay_requirements" placeholder="Eassy requirements" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
          </div>
        </div>

        <div v-if="hasLink">
        <div class="mt-4" v-for="(item,index) in link_title_count" :key="item" >
          <label for="link_requirements" class="block text-sm font-medium text-gray-700">
            Link Title
          </label>
          <div class="mt-1 flex">
            <input type="text" placeholder="Enter title of link"  :name="'link_titles'+index"  class="links grow mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
            <button @click="link_title_count.push(index+1)" type="button" class="inline-flex shrink-0 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3 bg-gray-700 hover:bg-gray-900 focus:outline-none ">
              <span class="md:inline-block">+ Add</span>
            </button>
          </div>
        </div>
        </div>

        <div v-if="hasFile">
          <div class="mt-4" v-for="(item,index) in file_title_count" :key="item" >
            <label for="link_requirements" class="block text-sm font-medium text-gray-700">
              File Title
            </label>
            <div class="mt-1 flex">
              <input type="text" placeholder="Enter title of file to upload"  :name="'file_titles'+index"  class="files grow mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
              <button @click="file_title_count.push(index+1)" type="button" class="inline-flex shrink-0 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3 bg-gray-700 hover:bg-gray-900 focus:outline-none ">
                <span class="md:inline-block">+ Add</span>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--    buttons-->
    <div class="mt-8 flex justify-between">

      <button type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
        <span class="md:inline-block">Cancel</span>
      </button>
      <div>
        <button type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
          <span class="md:inline-block">Save to draft</span>
        </button>

        <button type="submit" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3 bg-black hover:bg-gray-700 focus:outline-none ">
          <span class="md:inline-block">Publish</span>
        </button>

      </div>

    </div>
  </form>
</template>

<script>
import {submitScholarshipEligibility,activeScholarship} from "@/store/scholarship";
import router from "@/router";
import SelectElement from "@/components/elements/SelectElement";
import {onMounted,ref} from "vue";
import {countries,getCountries} from "@/helpers/countries";
export default {
  name: "CreateScholarshipEligibility",
  components:{SelectElement},
  setup(){
    const specificSchools = ref(false);
    const hasEssay = ref(false);
    const hasLink = ref(false);
    const hasFile = ref(false);
    const hasImages = ref(false);
    const studentType = ref()
    const education_level = ref()
    const country = ref();
    const link_title_count = ref([0]);
    const file_title_count = ref([0]);

    const moveForward = () => {
      router.push({name:'create-scholarship-successful'})
    }
    onMounted(()=>{
      document.getElementById('eligibilityForm').reset();
      getCountries();

    })
    return {
   activeScholarship,submitScholarshipEligibility,
      moveForward,specificSchools,hasImages,hasEssay,hasFile,hasLink,
      studentType,education_level,country,countries,link_title_count,
      file_title_count
    }
  }
}
</script>

<style scoped>

</style>