<template>
  <navbar></navbar>
  <div class="sm:sm:px-[80px] py-[86px] mx-auto max-w-[100rem] px-3">
    <button @click="$router.back()" type="button" class="inline-flex  mt-[50px]  md:mt-[80px]   items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="20" viewBox="0 0 20 20" width="20"><g stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path d="m7.97484 4.94531-5.05834 5.05829 5.05834 5.0584"/><path d="m17.0831 10h-14.02499"/></g></svg>
      <span>Back to scholarship</span>
    </button>

    <div class="bg-gray-100 p-6 mt-8" v-if="activeScholarship">
      <h1 class=" text-4xl lg:text-5xl lg:max-w-[80%] font-medium ">{{activeScholarship.title}}</h1>
      <p class="my-8">Published {{ longFormDate(activeScholarship.created_at) }}</p>
      <div class="flex justify-between flex-col md:flex-row ">
        <div class="flex grow items-center mb-4 lg:mb-0 ">
          <RoundProfileImage :size="10" :url="activeScholarship.partner?.logo || null" :username="activeScholarship.partner?.business_name"  />
          <span class="ml-5 text-xl font-medium">{{activeScholarship.partner.business_name}}</span>
        </div>

<!--        <button class="flex text-gray-500">-->
<!--          <svg class="mr-[15px]" xmlns="http://www.w3.org/2000/svg" fill="none" height="24" viewBox="0 0 24 24" width="24"><path d="m8.59 13.51 6.83 3.98m-.01-10.98-6.82 3.98m12.41-5.49c0 1.65685-1.3431 3-3 3s-3-1.34315-3-3 1.3431-3 3-3 3 1.34315 3 3zm-12 7c0 1.6569-1.34315 3-3 3s-3-1.3431-3-3 1.34315-3 3-3 3 1.3431 3 3zm12 7c0 1.6569-1.3431 3-3 3s-3-1.3431-3-3 1.3431-3 3-3 3 1.3431 3 3z" stroke="#667085" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></svg>-->
<!--          Share-->
<!--        </button>-->
      </div>
    </div>

    <div class="grid grid-cols-12 my-8 gap-6 ">
       <div class="rounded-lg bg-gray-25 border border-gray-100 p-6 col-span-12 sm:col-span-8">
         <div v-if="activeScholarship" >
           <img :src="activeScholarship.image" class="w-full" alt="">
         </div>

         <div class="my-8" v-if="activeScholarship">
           <h2 class="text-2xl">Description</h2>
           <div class="mt-4 text-[18px] text-gray-500 leading-40 content">
             <p>
               {{activeScholarship.description}}
             </p>
           </div>
         </div>
         <div class="my-4" v-if="activeScholarship">
           <h2 class="text-[24px] ">Who is our ideal candidate</h2>
           <div class="mt-4 text-[18px] text-gray-500 leading-40 content">
             <p>
               {{activeScholarship.eligibility.applicant_description}}
             </p>
           </div>
         </div>

         <div class="border-t mt-8 pt-8">
           <button @click="$router.push({name:'apply-scholarship', params:{id:activeScholarship.id}})" type="button" class="inline-flex items-center px-[22px]  py-[12px] border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3 bg-black hover:bg-gray-700 focus:outline-none ">
             <span class="md:inline-block">Apply now</span>
           </button>
         </div>
       </div>
      <div class="sm:col-span-4 col-span-12" v-if="activeScholarship">
        <div class="px-5 py-6 bg-gray-25 border border-gray-100 rounded-lg">
          <div class="flex flex-col mt-4 justify-between flex-wrap">
            <div class="flex flex-col p-3 bg-gray-100 rounded-lg">
              <span class="text-[10px]">Amount</span>
              <span class="text-[36px] font-medium">{{activeScholarship.currency.short_code}}  {{commaSeparator(activeScholarship.amount)}}</span>
            </div>
            <div class="flex flex-col my-4">
              <span class="text-[12px] text-gray-600">Application status</span>
              <span class="text-[12px] inline-flex mt-[6px] items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 self-start">Open</span>
            </div>
            <div class="flex flex-col mt-4" v-if="activeScholarship.application_deadline">
              <span class="text-[12px] text-gray-600">Application deadline</span>
              <span class="font-medium">{{shortFormDate(activeScholarship.application_deadline)}}</span>
            </div>
            <div class="flex flex-col mt-4">
              <span class="text-[12px] text-gray-600">Scholarships to award</span>
              <span class="font-medium">{{activeScholarship.winners}}</span>
            </div>

            <div class="flex flex-col mt-4">
              <span class="text-[12px] text-gray-600">Education level</span>
              <span class="font-medium">{{activeScholarship.eligibility?.education_level}}</span>
            </div>

            <div class="mt-5">

              <button v-if="activeScholarship.sponsorship?.accept_contribution" @click="$router.push({name:'sponsor-scholarship', params:{id:activeScholarship.id}})" type="button" class="flex mb-3 w-full items-center px-3 py-2 border justify-center border-gray-900 shadow-sm  text-base leading-4 font-medium rounded-md text-white  bg-black hover:bg-gray-700 focus:outline-none ">
                <span class="md:inline-block">Donate/Contribute </span>
              </button>

              <button @click="share(activeScholarship.title, ui+$route.fullPath)" type="button" class="flex  w-full  items-center px-3 py-2 border  justify-center  border-gray-300 shadow-sm text-base  leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
                <span class="md:inline-block">Share   </span>
                <svg class="ml-3" xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                  <path d="M6.15833 11.2557L11.85 14.5724M11.8417 5.4224L6.15833 8.73906M16.5 4.16406C16.5 5.54477 15.3807 6.66406 14 6.66406C12.6193 6.66406 11.5 5.54477 11.5 4.16406C11.5 2.78335 12.6193 1.66406 14 1.66406C15.3807 1.66406 16.5 2.78335 16.5 4.16406ZM6.5 9.9974C6.5 11.3781 5.38071 12.4974 4 12.4974C2.61929 12.4974 1.5 11.3781 1.5 9.9974C1.5 8.61668 2.61929 7.4974 4 7.4974C5.38071 7.4974 6.5 8.61668 6.5 9.9974ZM16.5 15.8307C16.5 17.2114 15.3807 18.3307 14 18.3307C12.6193 18.3307 11.5 17.2114 11.5 15.8307C11.5 14.45 12.6193 13.3307 14 13.3307C15.3807 13.3307 16.5 14.45 16.5 15.8307Z" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>



            </div>
          </div>
        </div>

        <div class="rounded-md bg-error-50 p-4 my-8" v-if="activeScholarship.deadline_note">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <rect width="32" height="32" rx="16" fill="#F04438"/>
                <path d="M16 13.2188V14.8838" stroke="#FCFCFD" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M16.0104 11C14.1704 11 12.6804 12.49 12.6804 14.33V15.38C12.6804 15.72 12.5404 16.23 12.3654 16.52L11.7304 17.58C11.3404 18.235 11.6104 18.965 12.3304 19.205C14.7204 20 17.3054 20 19.6954 19.205C20.3704 18.98 20.6604 18.19 20.2954 17.58L19.6604 16.52C19.4854 16.23 19.3454 15.715 19.3454 15.38V14.33C19.3404 12.5 17.8404 11 16.0104 11Z" stroke="#FCFCFD" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M17.665 19.4062C17.665 20.3213 16.915 21.0712 16 21.0712C15.545 21.0712 15.125 20.8813 14.825 20.5813C14.525 20.2813 14.335 19.8613 14.335 19.4062" stroke="#FCFCFD" stroke-miterlimit="10"/>
              </svg>
            </div>
            <div class="ml-3">
              <div class="mt-2 text-sm text-black">
                <p>
                  <span class="font-medium">Rolling Deadline.</span> {{activeScholarship.deadline_note}}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="activeScholarship.sponsorship?.accept_contribution"  class="rounded-2xl pb-6 bg-white border border-[#f2f4f7]">
          <div class="pl-6 py-4 mb-4 font-medium border-b border-[#f2f4f7] text-gray-800 text-base"> Recent donors</div>
          <div class="space-y-6 px-2 xl:px-5" v-if="paymentList.length > 0">
            <div  class="flex justify-between items-center" v-for="payment in paymentList" :key="payment.id">
              <div class="flex space-x-[10px] items-center">
                   <RoundProfileImage :username="payment.customer_name"/>
                <div>
                  <div class="text-gray-700 text-sm font-medium">{{ payment.customer_name }}</div>
                  <div class="text-gray-400 text-xs">{{shortFormDate(payment.created_at)}}</div>
                </div>
              </div>
              <div class="text-gray-700 text-base font-bold italic pr-1">{{payment.currency}} <span
                  class="hidden xl:inline">{{payment.amount}}</span><span class="xl:hidden">{{payment.payout_amount}}</span></div>
            </div>

          </div>
          <EmptyState v-if="paymentList.length == 0" title="No Sponsors records yet" description="your records will appear here once you have sponsored scholars" button="Create a scholarship"  @action="$router.push({name:'sponsor-scholarship', params:{id:activeScholarship.id}})" />


        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import {getScholarshipById,activeScholarship} from "@/store/scholarship";
import {useRoute} from "vue-router";
import {onMounted} from "vue";
import {longFormDate,shortFormDate} from "@/helpers/date";
import {getScholarshipsByPayments,paymentList} from "@/store/payments";
import RoundProfileImage from "@/components/elements/Images/RoundProfileImage";
import EmptyState from "@/components/state/EmptyState";
import {commaSeparator} from "@/helpers/display";
import {ui} from "@/helpers/api";
import {share} from "@/helpers/share";
export default {
  name: "PublicShowScholarship",
  components:{Navbar,RoundProfileImage,EmptyState},
  setup(){
    const route = useRoute();
    onMounted(()=>{
      getScholarshipById(route.params.id);
      getScholarshipsByPayments(route.params.id);
    });
    return {activeScholarship,longFormDate,shortFormDate,paymentList,commaSeparator,ui,share}
  }
}
</script>

<style scoped>

</style>