
import { defineComponent,ref } from "vue";
import {user} from "@/store/auth";
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import {onBeforeMount} from 'vue';

export default defineComponent({
  name: "NavigationSidebar",
  components:{Disclosure,DisclosurePanel,DisclosureButton},
  setup(){
    const routes: any = ref([]);
    onBeforeMount(()=>{
       if (user.value.user_type === 'partner'){
          routes.value = [
           {
             name:'Overview',
             icon:"home.svg"
           },
          {
              name:'Scholarship Funds',
              icon:"teacher.svg",
              children:[
                {
                  name:'Scholarships',
                },
                {
                  name:'Sponsors',
                },
                {
                  name:'Scholars',
                }
              ]
            },
            {
             name:'Payments',
             icon:"moneys.svg"
           },

           {
             name:'Communications',
             icon:"message.svg"
           },

           {
             name:'settings',
             icon:"setting.svg"
           },
         ]
       }
       else {
       routes.value = [
         {
           name:'Overview',
           icon:"home.svg"
         },
         {
           name:'Scholarships',
           icon:"teacher.svg",
         },
         {
           name:'settings',
           icon:"setting.svg"
         },

       ];
       }
    })
    return {
   routes,user
    }
  },
});
