import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09d99f4d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-full bg-gray-100" }
const _hoisted_2 = { class: "h-20" }
const _hoisted_3 = { class: "main__content" }
const _hoisted_4 = { class: "flex flex-col sm:justify-center items-center px-4 sm:pt-0" }
const _hoisted_5 = { class: "w-full sm:max-w-md px-4 py-4 bg-white shadow-sm rounded-lg my-20" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "sm:mx-auto sm:w-full sm:max-w-md mt-3" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "sm:mx-auto sm:w-full sm:max-w-md mt-3"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "mt-6 mb-2 text-center text-2xl sm:text-3xl font-medium text-[#101828]" }
const _hoisted_12 = { class: "mb-4 text-gray-25 text-base text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar = _resolveComponent("navbar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_navbar)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_ctx.show_title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_ctx.show_icon_with_router)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: _ctx.icon_link
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("img", {
                            class: "mx-auto h-16 w-16 object-cover",
                            src: require(`@/assets/${_ctx.icon}`),
                            alt: "Steward"
                          }, null, 8, _hoisted_8)
                        ])
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  : _createCommentVNode("", true),
                (!_ctx.show_icon_with_router)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("img", {
                        class: "mx-auto h-16 w-16 object-cover",
                        src: require(`@/assets/${_ctx.icon}`),
                        alt: "Steward"
                      }, null, 8, _hoisted_10)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("h2", _hoisted_11, _toDisplayString(_ctx.title), 1),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.subtitle), 1)
              ]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "form-content", {}, undefined, true),
          _renderSlot(_ctx.$slots, "other-content", {}, undefined, true)
        ])
      ])
    ])
  ]))
}