<template>
  <form @submit.prevent='updatePassword'  class="space-y-8 divide-y divide-gray-200">
    <input type="hidden" name="_method" value="put">
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div>
        <div class="my-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Password
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Update your password here.
          </p>
        </div>

        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Password
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="col-span-6 max-w-lg">
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="grid grid-cols-6 gap-6">

                    <div class="col-span-6 sm:col-span-3">
                      <label for="old_password" class="block text-sm font-medium text-gray-700">Old password </label>
                      <div class="relative password_container">
                        <input

                            class="text_field_input pr-10"
                            id="old_password"
                            type="password"
                            name="old_password"
                            required
                            autocomplete="do-not-autofill"
                            placeholder="Enter your Old password"
                        />
                        <div
                            onclick="document.getElementById('old_password').setAttribute('type','text'); document.getElementById('old_password').parentElement.classList.toggle('show-icon')"
                            class="absolute inset-y-0 right-0 pr-3 flex items-center"
                        >
                          <svg
                              class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                          >
                            <path
                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                            ></path>
                            <path
                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                            ></path>
                          </svg>
                        </div>

                        <div
                            onclick="document.getElementById('old_password').setAttribute('type','password');document.getElementById('old_password').parentElement.classList.toggle('show-icon')"
                            class="absolute inset-y-0 right-0 pr-3 flex items-center hidden"
                        >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
                              viewBox="0 0 16 16"
                          >
                            <path
                                d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                            ></path>
                            <path
                                d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                            ></path>
                            <path
                                d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div class="sm:col-span-3"></div>


                    <div class="col-span-6 sm:col-span-3">
                      <label for="state" class="block text-sm font-medium text-gray-700">New password</label>
                      <div class="relative password_container">
                        <input
                            v-model="password"
                            class="text_field_input pr-10"
                            id="password"
                            type="password"
                            name="password"
                            required
                            autocomplete="do-not-autofill"
                            placeholder="Enter your password here"
                            @input="checkLetters"
                        />
                        <div
                            onclick="document.getElementById('password').setAttribute('type','text'); document.getElementById('password').parentElement.classList.toggle('show-icon')"
                            class="absolute inset-y-0 right-0 pr-3 flex items-center"
                        >
                          <svg
                              class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                          >
                            <path
                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                            ></path>
                            <path
                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                            ></path>
                          </svg>
                        </div>

                        <div
                            onclick="document.getElementById('password').setAttribute('type','password');document.getElementById('password').parentElement.classList.toggle('show-icon')"
                            class="absolute inset-y-0 right-0 pr-3 flex items-center hidden"
                        >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
                              viewBox="0 0 16 16"
                          >
                            <path
                                d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                            ></path>
                            <path
                                d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                            ></path>
                            <path
                                d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <div class=" relative">
                        <label
                            class="block font-medium text-sm text-gray-700"
                            for="password_confirmation"
                        >
                          Confirm password
                        </label>

                        <div class="relative password_container">
                          <input
                              v-model="password_confirmation"
                              class="text_field_input pr-10"
                              id="password_confirmation"
                              type="password"
                              name="password_confirmation"
                              required
                              autocomplete="do-not-autofill"
                              placeholder="Confirm your password"
                          />
                          <div
                              onclick="document.getElementById('password_confirmation').setAttribute('type','text'); document.getElementById('password_confirmation').parentElement.classList.toggle('show-icon')"
                              class="absolute inset-y-0 right-0 pr-3 flex items-center"
                          >
                            <svg
                                class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                            >
                              <path
                                  d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                              ></path>
                              <path
                                  d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                              ></path>
                            </svg>
                          </div>

                          <div
                              onclick="document.getElementById('password_confirmation').setAttribute('type','password');document.getElementById('password_confirmation').parentElement.classList.toggle('show-icon')"
                              class="absolute inset-y-0 right-0 pr-3 flex items-center hidden"
                          >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
                                viewBox="0 0 16 16"
                            >
                              <path
                                  d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                              ></path>
                              <path
                                  d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                              ></path>
                              <path
                                  d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="text-xs text-[#ff2e2e] font-light mt-1">
                          {{ password_confirmation_error_message }}
                        </div>
                      </div>
                    </div>


                  </div>


                  <div class="mt-4 relative">
                    <div class="my-7">
                      <div class="flex items-center mt-4">
                        <img
                            v-if="uppercase"
                            src="@/assets/onboarding/filled_checkbox.svg"
                            alt=""
                        />
                        <img
                            v-else
                            src="@/assets/onboarding/empty_checkbox.svg"
                            alt=""
                        />

                        <span class="font-medium text-gray-700 ml-3 text-sm"
                        >Must contain at least one uppercase letter</span
                        >
                      </div>
                      <div class="flex items-center mt-4">
                        <img
                            v-if="number"
                            src="@/assets/onboarding/filled_checkbox.svg"
                            alt=""
                        />
                        <img
                            v-else
                            src="@/assets/onboarding/empty_checkbox.svg"
                            alt=""
                        />

                        <span class="font-medium text-gray-700 ml-3 text-sm"
                        >Must contain at least one number</span
                        >
                      </div>
                      <div class="flex items-center mt-4">
                        <img
                            v-if="special_character"
                            src="@/assets/onboarding/filled_checkbox.svg"
                            alt=""
                        />
                        <img
                            v-else
                            src="@/assets/onboarding/empty_checkbox.svg"
                            alt=""
                        />

                        <span class="font-medium text-gray-700 ml-3 text-sm"
                        >Must contain at least one special character</span
                        >
                      </div>
                      <div class="flex items-center mt-4">
                        <img
                            v-if="length"
                            src="@/assets/onboarding/filled_checkbox.svg"
                            alt=""
                        />
                        <img
                            v-else
                            src="@/assets/onboarding/empty_checkbox.svg"
                            alt=""
                        />

                        <span class="font-medium text-gray-700 ml-3 text-sm"
                        >Must contain a minimum of 8 characters</span
                        >
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>



        </div>
      </div>



    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Cancel
        </button>
        <button type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-900 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, watch} from "vue";
import {updatePassword} from "@/store/auth";
export default {
  name: "PasswordSettings",

  setup(){
    const password_confirmation_error_message = ref("");
    const password_confirmation = ref("");
    const password = ref("");
    const number = ref(false);
    const special_character = ref(false);
    const length = ref(false);
    const uppercase = ref(false);

    watch(password, (currentValue) => {
      if (password_confirmation.value.length) {
        password_confirmation_error_message.value =
            currentValue !== password_confirmation.value
                ? "Passwords Don't Match"
                : "";
      }
    });
    watch(password_confirmation, (currentValue) => {
      if (password_confirmation.value.length) {
        password_confirmation_error_message.value =
            currentValue !== password.value ? "Passwords Don't Match" : "";
      }
    });


    const checkLetters = () => {
      const numbers = /[0-9]/;
      const uppercaseLetters = /[A-Z]/;
      const symbols = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      number.value = password.value.match(numbers) ? true : false;
      uppercase.value = password.value.match(uppercaseLetters) ? true : false;
      special_character.value = password.value.match(symbols) ? true : false;
      length.value = password.value.length >= 8 ? true : false;
    };

    return {
      password_confirmation_error_message,
      password,password_confirmation,number,
      special_character,length,uppercase,
      checkLetters,updatePassword
    };
  }
}
</script>

<style scoped>
.password_container.show-icon > div:last-of-type {
  display: none;
}

.password_container.show-icon > div:first-of-type {
  display: flex;
}

.password_container.show-icon > div:last-of-type {
  display: flex;
}

.password_container.show-icon > div:first-of-type {
  display: none;
}
</style>