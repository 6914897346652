<template>
  <div class="xl:mt-[5.78px] h-full">
    <div class="xl:mx-auto w-full xl:max-w-5xl h-full bg-white p-4">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name:"SponsorList",
}
</script>