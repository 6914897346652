<template>
  <div >
    <div  class="space-y-8 divide-y divide-gray-200 sm:space-y-5 border-b">
      <div class="my-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Manage Banks and Cards
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Add your bank account to receive payouts.
        </p>
      </div>
    </div>

    <div class="my-6">
      <div class="flex justify-between items-center">
        <div class="text-xl text-black-500 font-medium mb-7 flex items-center"> Bank accounts
        </div>

        <button type="button" class=" flex bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M5 10H15" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 15V5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Add new bank
        </button>
      </div>

    </div>

    <EmptyState title="No bank accounts added yet" description="All your beneficiaries will appear here"> <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
      <rect x="4" y="4" width="48" height="48" rx="24" fill="#F2F4F7"/>
      <path d="M28.37 18.1537L37.37 21.7537C37.72 21.8937 38 22.3137 38 22.6837V26.0037C38 26.5537 37.55 27.0037 37 27.0037H19C18.45 27.0037 18 26.5537 18 26.0037V22.6837C18 22.3137 18.28 21.8937 18.63 21.7537L27.63 18.1537C27.83 18.0738 28.17 18.0738 28.37 18.1537Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M38 38H18V35C18 34.45 18.45 34 19 34H37C37.55 34 38 34.45 38 35V38Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20 34V27" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24 34V27" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28 34V27" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M32 34V27" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M36 34V27" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17 38H39" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28 24.5C28.8284 24.5 29.5 23.8284 29.5 23C29.5 22.1716 28.8284 21.5 28 21.5C27.1716 21.5 26.5 22.1716 26.5 23C26.5 23.8284 27.1716 24.5 28 24.5Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F9FAFB" stroke-width="8"/>
    </svg></EmptyState>

    </div>
</template>

<script>
import EmptyState from "@/components/state/EmptyState";
export default {
  name: "BankSettings",
  components:{EmptyState}
}
</script>

<style scoped>

</style>