<template>
  <Menu v-model="selected" as="div" class="relative inline-block text-left">
    <div>

      <MenuButton class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
        <slot>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M15.8333 8.33594C16.75 8.33594 17.5 9.08594 17.5 10.0026C17.5 10.9193 16.75 11.6693 15.8333 11.6693C14.9167 11.6693 14.1667 10.9193 14.1667 10.0026C14.1667 9.08594 14.9167 8.33594 15.8333 8.33594Z" stroke="#292D32" stroke-width="1.5"/>
            <path d="M4.16683 8.33594C5.0835 8.33594 5.8335 9.08594 5.8335 10.0026C5.8335 10.9193 5.0835 11.6693 4.16683 11.6693C3.25016 11.6693 2.50016 10.9193 2.50016 10.0026C2.50016 9.08594 3.25016 8.33594 4.16683 8.33594Z" stroke="#292D32" stroke-width="1.5"/>
            <path d="M9.99984 8.33594C10.9165 8.33594 11.6665 9.08594 11.6665 10.0026C11.6665 10.9193 10.9165 11.6693 9.99984 11.6693C9.08317 11.6693 8.33317 10.9193 8.33317 10.0026C8.33317 9.08594 9.08317 8.33594 9.99984 8.33594Z" stroke="#292D32" stroke-width="1.5"/>
          </svg>
        </slot>
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <MenuItem @click="$emit('selected', item.action)" v-for="item in items" :key="item" v-slot="{ active }">
            <a v-if="item.action === 'close' || item.action.indexOf('delete') !== -1" href="#" :class="[active ? 'bg-gray-100 text-red-900' : 'text-red-700', 'block px-4 py-2 text-sm']">{{item.name}}</a>
            <a v-else href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-500', 'block px-4 py-2 text-sm']">{{item.name}}</a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {defineProps,ref,watch} from 'vue';
const props = defineProps({
  items: []
});

const selected = ref();
watch(selected,(val)=>{
  console.log(val);
});
</script>


<style scoped>

</style>