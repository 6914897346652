<template>
  <div class="bg-success bg-cover bg-no-repeat h-full mx-auto max-w-4xl mt-20">
    <h2 class="text-xl text-center my-8">Application sucessfully submitted 🚀  </h2>
    <div class="flex justify-center ">
      <ScholarshipDetailCard v-if="activeScholarship" class="md:max-w-[60%]" :scholarship="activeScholarship" />
    </div>

    <div class="flex justify-center mt-11">
      <a  href="/scholarships" class="flex  self-baseline items-center px-4 py-4  shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none ">
        <span class="md:inline-block text-base">Back to home</span>
      </a>
    </div>
  </div>
</template>

<script>
import ScholarshipDetailCard from "@/components/ScholarshipDetailCard";
import CopyPublicLink from "@/components/CopyPublicLink";
import {activeScholarship,getScholarshipById} from "@/store/scholarship";
import {onMounted} from "vue";
export default {
  name: "ApplicationSuccessful",
  components:{ScholarshipDetailCard},
  setup(){

    return {activeScholarship}
  }
}
</script>

<style scoped>

</style>