<template>
  <div class="xl:mt-[5.78px] h-full">
    <div class="xl:mx-auto w-full xl:max-w-5xl h-full">
      <div class="bg-white py-8 px-2 sm:px-8">
        <div class="my-8">
          <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select id="tabs" @change="switchTab" v-model="currentTab" name="tabs" class="block w-full focus:ring-black focus:border-black border-gray-300 rounded-md">
              <option v-for="tab in tabs" :key="tab.name" :value="tab.href" >{{ tab.name }}</option>
            </select>
          </div>
          <div class="hidden sm:block">
            <nav class="relative z-0 rounded-lg  flex  divide-gray-200" aria-label="Tabs">
              <router-link @click="currentTab = tab.href "  v-for="(tab, tabIdx) in tabs" :key="tab.name" :to="{name:tab.href}" :class="[currentTab === tab.href ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10']" :aria-current="tab.current ? 'page' : undefined">
                <span>{{ tab.name }}</span>
                <span aria-hidden="true" :class="[currentTab === tab.href  ? 'bg-black' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
              </router-link>
            </nav>
          </div>
        </div>
    <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>

import {useRoute} from "vue-router";
import {ref, onBeforeMount} from "vue";
import router from "@/router";
import {user} from "@/store/auth";
export default {
  name:"SettingsPage",
setup(){
  const route = useRoute();
  const currentTab = ref(route.name);
  const tabs = ref([]);

  onBeforeMount(() => {
    if(user.value.user_type === 'partner'){
      tabs.value = [
        { name: 'My profile', href: 'PersonalSettings', current: route.name === 'PersonalSettings' },
        { name: 'Organisation profile', href: 'OrganisationSettings', current: route.name === 'OrganisationSettings' },
        { name: 'Password & preferences', href: 'PasswordSettings', current: route.name === 'PasswordSettings' },
        { name: 'Teams', href: 'TeamSettings', current: route.name === 'PasswordSettings' },
        { name: 'Bank and Cards', href: 'BankSettings', current: route.name === 'PasswordSettings' },
      ]
    }else if (user.value.user_type === 'sponsor'){
      tabs.value = [
        { name: 'My profile', href: 'PersonalSettings', current: route.name === 'PersonalSettings' },
        { name: 'Password & preferences', href: 'PasswordSettings', current: route.name === 'PasswordSettings' },
      ]
    }else{
      tabs.value = [
        { name: 'My profile', href: 'PersonalSettings', current: route.name === 'PersonalSettings' },
        { name: 'Password & preferences', href: 'PasswordSettings', current: route.name === 'PasswordSettings' },
      ]
    }

  })


  const switchTab = (env) => {
    router.push({name:env.target.value});
  }

  return {tabs,currentTab,switchTab}
}
}
</script>

<style scoped>

</style>