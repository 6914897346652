import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import Notifications from "@kyvg/vue3-notification";
import VueGtag from "vue-gtag";
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

const app = createApp(App);
app.use(router);
app.use(Notifications);
app.use(LoadingPlugin);
app.use(VueGtag, {
    config: { id: process.env.VUE_APP_ANALYTICS }
},router);
app.mount("#app");
