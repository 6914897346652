<template>
  <button @click="$router.back()" type="button" class="inline-flex  items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="20" viewBox="0 0 20 20" width="20"><g stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path d="m7.97484 4.94531-5.05834 5.05829 5.05834 5.0584"/><path d="m17.0831 10h-14.02499"/></g></svg>
    <span>Back to Description</span>
  </button>

  <h2 class="text-xl my-10">Scholarship Eligibility</h2>
  <form v-if="activeScholarship" id="eligibilityForm"  @submit.prevent = submitScholarshipEligibility($event,moveForward,activeScholarship.id) action="#" method="POST">

    <div class="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div class="sm:col-span-3  col-span-6">

        <div class="mt-4">
          <label for="frequency" class="block text-sm font-medium text-gray-700">
            Educational level
          </label>
          <div class="mt-1">
            <select id="education_level" v-model="activeScholarship.eligibility.education_level" required  name="education_level"  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
              <option value="" selected>Select an option</option>
              <option>Any</option>
              <option>High School</option>
              <option>Undergraduate</option>
              <option>Bachelor’s degree program</option>
              <option>Master’s degree program</option>

            </select>
          </div>
        </div>

        <div class="mt-4">
          <label for="description" class="block text-sm font-medium text-gray-700">
            Describe Ideal applicant
          </label>
          <div class="mt-1">
            <textarea id="description" v-model="activeScholarship.eligibility.applicant_description"  name="applicant_description" placeholder="Enter a description..." rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
          </div>
        </div>

        <div class="mt-4">
          <label for="description" class="block text-sm font-medium text-gray-700">
            Scholars submission requirements
          </label>

          <div class="mt-4 grid grid-cols-4">
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="comments" v-model="hasEssay" value="essay" aria-describedby="comments-description" name="submission_requirements[]" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
              </div>
              <div class="ml-3 text-sm">
                <label for="comments" class="font-medium text-gray-700">Essay</label>
              </div>
            </div>
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="candidates" v-model="hasLink" value="link" aria-describedby="candidates-description" name="submission_requirements[]" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
              </div>
              <div class="ml-3 text-sm">
                <label for="candidates" class="font-medium text-gray-700">Link</label>
              </div>
            </div>
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="offers"  v-model="hasFile" value="file" aria-describedby="offers-description" name="submission_requirements[]" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
              </div>
              <div class="ml-3 text-sm">
                <label for="offers" class="font-medium text-gray-700">File</label>
              </div>
            </div>

            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="offers" v-model="hasImages" value="images" aria-describedby="offers-description" name="submission_requirements[]" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
              </div>
              <div class="ml-3 text-sm">
                <label for="offers" class="font-medium text-gray-700">Images</label>
              </div>
            </div>
          </div>
        </div>




        <div class="mt-4" v-if="hasEssay">
          <label for="essay_requirements" class="block text-sm font-medium text-gray-700">
            Eassy requirements
          </label>
          <div class="mt-1">
            <textarea id="essay_requirements" name="essay_requirements" placeholder="Eassy requirements" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
          </div>
        </div>

        <div class="mt-4" v-if="hasLink">
          <label for="link_requirements" class="block text-sm font-medium text-gray-700">
            Link requirements
          </label>
          <div class="mt-1">
            <textarea id="link_requirements" name="link_requirements" placeholder="Link requirements" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
          </div>
        </div>


        <div class="mt-4" v-if="hasFile">
          <label for="file_requirements" class="block text-sm font-medium text-gray-700">
            File requirements
          </label>
          <div class="mt-1">
            <textarea id="file_requirements" name="file_requirements" placeholder="File requirements" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
          </div>
        </div>

        <div class="mt-4" v-if="hasImages">
          <label for="image_requirements" class="block text-sm font-medium text-gray-700">
            Image requirements
          </label>
          <div class="mt-1">
            <textarea id="image_requirements" name="image_requirements" placeholder="Image requirements" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
          </div>
        </div>


        <div class="relative flex items-start mt-4">
          <div class="flex items-center h-5">
            <input id="specific_schools" v-model="specificSchools" aria-describedby="comments-description" name="specific_schools" type="checkbox" value="1" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
          </div>
          <div class="ml-3 text-sm">
            <label for="specific_schools" class="font-medium text-gray-700">Only available to specific schools</label>
          </div>
        </div>

        <div class="mt-4" v-if="specificSchools">
          <label for="first-name" class="block text-sm font-medium text-gray-700">
            Eligible schools
          </label>
          <div class="mt-1">
            <input type="text" name="eligible_schools" id="first-name" autocomplete="given-name" placeholder="Enter Value" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
          </div>
        </div>

      </div>

    </div>
    <!--    buttons-->
    <div class="mt-8 flex justify-between">

      <button type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
        <span class="md:inline-block">Cancel</span>
      </button>
      <div>
<!--        <button type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">-->
<!--          <span class="md:inline-block">Save to draft</span>-->
<!--        </button>-->

        <button type="submit" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3 bg-black hover:bg-gray-700 focus:outline-none ">
          <span class="md:inline-block">Continue</span>
        </button>

      </div>

    </div>
  </form>
</template>

<script>
import {submitScholarshipEligibility, activeScholarship, getScholarshipById} from "@/store/scholarship";
import router from "@/router";
import {onMounted,ref} from "vue";
import {useRoute} from "vue-router";
export default {
  name: "EditScholarshipEligibility",
  setup(){
    const specificSchools = ref(false);
    const hasEssay = ref(false);
    const hasLink = ref(false);
    const hasFile = ref(false);
    const hasImages = ref(false);
    const route = useRoute();
    const moveForward = () => {
         router.push({name:'create-scholarship-sponsorship'})
    }
    onMounted(()=>{
      // document.getElementById('eligibilityForm').reset();
      activeScholarship.value = null;
      getScholarshipById(route.params.id)
    })
    return {
   activeScholarship,submitScholarshipEligibility,
      moveForward,specificSchools,hasImages,hasEssay,hasFile,hasLink
    }
  }
}
</script>

<style scoped>

</style>