<template>
  <form method="post" @submit.prevent='updateUser($event,user.id)'  class="space-y-8 divide-y divide-gray-200">
    <input type="hidden" name="_method" value="put">
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div>
        <div class="my-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Organization info
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Update your photo and company details here.
          </p>
        </div>

        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="photo" class="block text-sm font-medium text-gray-700">
              <span class="font-medium"> Your photo</span>
              <span class="text-sm text-gray-500 block">This will be displayed on your profile.</span>
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="flex items-center max-w-lg">
                <RoundProfileImage :url="user.logo" :size="12" class="self-start" :username="user.business_name" />
                <ImageUpload name="logo" class="grow  ml-5 "/>
              </div>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Email address
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="col-span-6 max-w-lg">
                <label for="email-address" class="block text-sm font-medium text-gray-700">Email address</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                      <path d="M18.3332 3.0026C18.3332 2.08594 17.5832 1.33594 16.6665 1.33594H3.33317C2.4165 1.33594 1.6665 2.08594 1.6665 3.0026M18.3332 3.0026V13.0026C18.3332 13.9193 17.5832 14.6693 16.6665 14.6693H3.33317C2.4165 14.6693 1.6665 13.9193 1.6665 13.0026V3.0026M18.3332 3.0026L9.99984 8.83594L1.6665 3.0026" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <input type="email" v-model="user.organisation_email" name="organisation_email" id="organisation_email" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" placeholder="you@example.com" />
                </div>
              </div>
            </div>
          </div>




          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="photo" class="block text-sm font-medium text-gray-700">
              <span class="font-medium">Bio</span>
              <span class="text-sm text-gray-500 block">Write a short introduction.</span>
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-lg flex rounded-md ">
                <div class="bg-white py-5 w-full">
                  <div class="grid grid-cols-6 gap-6">

                    <div class="col-span-6 sm:col-span-6">
                      <label for="headline" class="block text-sm font-medium text-gray-700">Organisation Name</label>
                      <input type="text"  readonly v-model="user.business_name" id="headline"  class="mt-1 block w-full bg-gray-50 rounded-md border-gray-300 shadow-sm outline-0 focus:ring-indigo-500 ring-0 sm:text-sm">
                    </div>


                    <div class="col-span-6 sm:col-span-6">
                      <label for="headline" class="block text-sm font-medium text-gray-700">What do you do?</label>
                      <input type="text" name="organisation_headline" placeholder="Enter company description " v-model="user.organisation_headline" id="headline"  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                      <label for="organisation_bio" class="block text-sm font-medium text-gray-700">Purpose for the scholarship funds</label>
                      <textarea @input="reduceWords" maxlength="275" id="organisation_bio" v-model="user.organisation_bio" name="organisation_bio" rows="3"  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="Enter a description..."></textarea>
                      <p class="text-xs text-gray-500 mt-1">{{wordCount}} characters left</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Phone number
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="col-span-6 max-w-lg">
                <label for="phone-number" class="block text-sm font-medium text-gray-700">Phone Number</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 flex items-center">
                    <label for="code" class="sr-only">Country</label>
                    <select id="code" :value="user.organisation_code? user.organisation_code : '256'" name="organisation_code" autocomplete="country" class="max-w-[100px] focus:ring-indigo-500 focus:border-indigo-500 border-r h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
                      <option value="256">+256 Uganda</option>
                      <option v-for="code in countries" :key="code">+{{code.phone_code}} {{code.name}}</option>
                    </select>
                  </div>
                  <input type="text" name="organisation_phone" :value="user.organisation_phone" id="organisation_phone" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-28 sm:text-sm border-gray-300 rounded-md" placeholder="+1 (555) 987-6543" />
                </div>
              </div>
            </div>
          </div>


          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Address
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="col-span-6 max-w-lg">
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="grid grid-cols-6 gap-6">

                    <div class="col-span-6 sm:col-span-6">
                      <label for="organisation_address" class="block text-sm font-medium text-gray-700">Organisation address </label>
                      <input type="text" :value="user.organisation_address" name="organisation_address" id="organisation_address" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="organisation_country" class="block text-sm font-medium text-gray-700">Country</label>
                      <select id="organisation_country" v-model="user.organisation_country" name="organisation_country" autocomplete="country-name" class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="" selected>Select an option</option>
                        <option v-for="code in countries" :value="code.short_code" :key="code">{{code.name}}</option>

                      </select>
                    </div>

<!--                    <div class="col-span-6 sm:col-span-3">-->
<!--                      <label for="organisation_state" class="block text-sm font-medium text-gray-700">State</label>-->
<!--                      <input type="text" :value="user.organisation_state" name="organisation_state" id="organisation_state" autocomplete="given-name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">-->
<!--                    </div>-->

<!--                    <div class="col-span-6 sm:col-span-3">-->
<!--                      <label for="organisation_area" class="block text-sm font-medium text-gray-700">Area</label>-->
<!--                      <input type="text" :value="user.organisation_area" name="organisation_area" id="organisation_area" autocomplete="family-name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">-->
<!--                    </div>-->

                    <div class="col-span-6 sm:col-span-3">
                      <label for="organisation_city" class="block text-sm font-medium text-gray-700">City</label>
                      <input type="text" :value="user.organisation_city" name="organisation_city" id="organisation_city" autocomplete="family-name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>


          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Social media accounts
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="col-span-6 max-w-lg">
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="grid grid-cols-6 gap-6">

                    <div class="col-span-6 sm:col-span-3">
                      <label for="company-website" class="block text-sm font-medium text-gray-700">Facebook</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm ">
                  http://
                </span>
                        </div>
                        <input type="text" :value="user.facebook" name="facebook" id="facebook" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
                      </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3">
                      <label for="company-website" class="block text-sm font-medium text-gray-700">Linkedin</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm ">
                  http://
                </span>
                        </div>
                        <input type="text" :value="user.linkedin" name="linkedin" id="linkedin" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
                      </div>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="company-website" class="block text-sm font-medium text-gray-700">Twitter</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm ">
                  http://
                </span>
                        </div>
                        <input type="text" :value="user.twitter" name="twitter" id="twitter" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
                      </div>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="instagram" class="block text-sm font-medium text-gray-700">instagram</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm ">
                  http://
                </span>
                        </div>
                        <input type="text" :value="user.instagram" name="instagram" id="instagram" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
                      </div>
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                      <label for="company-website" class="block text-sm font-medium text-gray-700">Organisation Website</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm ">
                  http://
                </span>
                        </div>
                        <input type="text" :value="user.website" name="website" id="website" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>



    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Cancel
        </button>
        <button type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-900 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import ImageUpload from "@/components/elements/ImageUpload";
import RoundProfileImage from "@/components/elements/Images/RoundProfileImage";
import {countries,getCountries} from "@/helpers/countries";
import {onMounted, ref} from "vue";
import {user,updateUser} from "@/store/auth";
import {notify} from "@kyvg/vue3-notification";
export default {
  name: "OrganisationSettings",
  components:{ImageUpload,RoundProfileImage},
  setup(){
    const wordCount = ref(275);
    onMounted(()=>{
      getCountries();
      reduceWords();
    })

    const reduceWords = () => {
     const target =  document.getElementById('organisation_bio')
      const maxLength = target.getAttribute("maxlength");
      const currentLength = target.value.length;

      if (currentLength >= maxLength) {
        notify({
          text:'You have reached the maximum number of characters.',
          type:'error'
        })
      }
      wordCount.value = maxLength - currentLength;
    }
    return {countries,user,updateUser,wordCount,reduceWords};
  }
}
</script>

<style scoped>

</style>