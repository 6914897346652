<template>
  <form  @submit.prevent="updateUser($event, user.id)" class="space-y-8 divide-y divide-gray-200" method="post" >
    <input type="hidden" name="_method" value="put">
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div>
        <div class="my-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Personal info
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Update your photo and personal details here.
          </p>
        </div>

        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="photo" class="block text-sm font-medium text-gray-700">
             <span class="font-medium"> Your photo</span>
              <span class="text-sm text-gray-500 block">This will be displayed on your profile.</span>
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="flex items-center max-w-lg">
               <RoundProfileImage :url="user.image" :size="12" class="self-start" :username="user.first_name+' '+user.last_name" />
               <ImageUpload class="grow  ml-5 "/>
              </div>
            </div>
          </div>


          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="username" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Name
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-lg flex rounded-md ">
                <div class="bg-white py-5 w-full">
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-6">
                      <label for="title" class="block text-sm font-medium text-gray-700">Title </label>
                      <select id="country" :value="user.title || ''" name="title" autocomplete="country-name" class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3  focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option selected value="">Select title</option>
                        <option v-for="title in titles" :key="title" :value="title">{{title}}</option>
                      </select>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="first-name" class="block text-sm font-medium text-gray-700">First name</label>
                      <input type="text" required :value="user.first_name"  name="first_name" id="first-name" autocomplete="given-name" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="last_name" class="block text-sm font-medium text-gray-700">Last name</label>
                      <input type="text" :value="user.last_name" name="last_name" id="last_name" autocomplete="family-name" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                      <label for="other_name" class="block text-sm font-medium text-gray-700">Other name (optional)</label>
                      <input type="text" :value="user.other_name" name="other_name" id="other_name" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-6" v-if="user.user_type === 'scholar'">
                      <label for="other_name" class="block text-sm font-medium text-gray-700">Nationality</label>
                      <input type="text"  name="other_name" id="nationality" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-3"  v-if="user.user_type === 'scholar'">
                      <label for="ethnicity" class="block text-sm font-medium text-gray-700">Ethnicity</label>
                      <input type="text"   name="ethnicity" id="ethnicity" autocomplete="given-name" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-3"  v-if="user.user_type === 'scholar'">
                      <label for="religion" class="block text-sm font-medium text-gray-700">Religion</label>
                      <input type="text"  name="religion" id="religion" autocomplete="family-name" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Email address
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="col-span-6 max-w-lg">
                <label for="email-address" class="block text-sm font-medium text-gray-700">Email address</label>
                <div class="mt-1 relative rounded-md ">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                      <path d="M18.3332 3.0026C18.3332 2.08594 17.5832 1.33594 16.6665 1.33594H3.33317C2.4165 1.33594 1.6665 2.08594 1.6665 3.0026M18.3332 3.0026V13.0026C18.3332 13.9193 17.5832 14.6693 16.6665 14.6693H3.33317C2.4165 14.6693 1.6665 13.9193 1.6665 13.0026V3.0026M18.3332 3.0026L9.99984 8.83594L1.6665 3.0026" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <input readonly :value="user.email" type="email" name="email" id="email" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" placeholder="you@example.com" />
                </div>
              </div>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Phone number
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="col-span-6 max-w-lg">
                <label for="phone-number" class="block text-sm font-medium text-gray-700">Phone Number</label>
                <div class="mt-1 relative rounded-md ">
                  <div class="absolute inset-y-0 left-0 flex items-center">
                    <label for="code" class="sr-only">Country</label>
                    <select id="code" :value="user.code? user.code : '256'" name="code" autocomplete="country" class="max-w-[100px] focus:ring-indigo-500 focus:border-indigo-500 border-r h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
                      <option value="256">+256 Uganda</option>
                      <option v-for="code in countries"  :value="code.phone_code"  :key="code">+{{code.phone_code}} {{code.name}}</option>
                    </select>
                  </div>
                  <input type="text" name="phone" :value="user.phone" id="phone_number" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-28 sm:text-sm border-gray-300 rounded-md" placeholder="+1 (555) 987-6543" />
                </div>
              </div>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"  v-if="user.user_type === 'scholar'">
            <label for="photo" class="block text-sm font-medium text-gray-700">
              <span class="font-medium">Bio</span>
              <span class="text-sm text-gray-500 block">Write a short introduction.</span>
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-lg flex rounded-md ">
                <div class="bg-white py-5 w-full">
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-6">
                      <textarea @input="reduceWords" maxlength="275" id="scholar_bio" name="scholar_bio" rows="3"  class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="Enter a description..."></textarea>
                      <p class="text-xs text-gray-500 mt-1">{{wordCount}} characters left</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"  v-if="user.user_type === 'scholar'">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Work Experience
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="col-span-6 max-w-lg">
                <div class="mt-1 relative rounded-md ">
                  <div class="grid grid-cols-6 gap-6">

                    <div class="col-span-6 sm:col-span-3">
                      <label for="employer" class="block text-sm font-medium text-gray-700">Employer</label>
                      <input type="text"  name="employer" id="employer" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="job_title" class="block text-sm font-medium text-gray-700">Job title</label>
                      <input type="text"  name="job_title" id="job_title" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="job_title" class="block text-sm font-medium text-gray-700">Industry Skills</label>
                      <input type="text"  name="industry_skills" id="industry_skills" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="job_status" class="block text-sm font-medium text-gray-700">Job status</label>
                      <select id="job_status"  name="job_status" class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3  focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="" selected>Select an option</option>
                      </select>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input id="candidates" aria-describedby="candidates-description" name="candidates" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="candidates" class="font-medium text-gray-700">I currently work here</label>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"  v-if="user.user_type === 'scholar'">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Date of Birth & Gender
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="col-span-6 max-w-lg">
                <div class="mt-1 relative rounded-md ">
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-3">
                      <label for="gender" class="block text-sm font-medium text-gray-700">Gender</label>
                      <select id="gender"  name="gender"  class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3  focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="" selected>Select an option</option>
                        <option value="male" >Male</option>
                        <option value="female" >Female</option>
                        <option value="private" >Prefer not to say</option>
                      </select>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="city" class="block text-sm font-medium text-gray-700">Birthday</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <input type="date" name="birthday" id="birthday" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency" />
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>


          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"  v-if="user.user_type === 'scholar'">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Education
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="col-span-6 max-w-lg">
                <div class="mt-1 relative rounded-md ">
                  <div class="grid grid-cols-6 gap-6">

                    <div class="col-span-6 sm:col-span-3">
                      <label for="employer" class="block text-sm font-medium text-gray-700">School/college/university</label>
                      <input type="text"  name="school_name" id="school_name" placeholder="school name" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="job_title" class="block text-sm font-medium text-gray-700">Degree</label>
                      <input type="text"  name="degree" id="degree" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>


                    <div class="col-span-6 sm:col-span-3">
                      <label for="field_of_study" class="block text-sm font-medium text-gray-700">Field of study</label>
                      <input type="text"  name="field_of_study" id="field_of_study" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="specialization" class="block text-sm font-medium text-gray-700">Specialization</label>
                      <input type="text"  name="specialization" id="specialization" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="year_of_entry" class="block text-sm font-medium text-gray-700">Year of entry</label>
                      <input type="text"  name="year_of_entry" id="year_of_entry" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="graduation_year" class="block text-sm font-medium text-gray-700">Graduation year</label>
                      <input type="text"  name="graduation_year" id="graduation_year" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="house" class="block text-sm font-medium text-gray-700">House/Hall</label>
                      <input type="text"  name="house" id="house" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>


          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Address
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="col-span-6 max-w-lg">
                <div class="mt-1 relative rounded-md ">
                  <div class="grid grid-cols-6 gap-6">

                    <div class="col-span-6 sm:col-span-6">
                      <label for="other_name" class="block text-sm font-medium text-gray-700">Home address </label>
                      <input type="text" :value="user.address" name="address" id="address" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="country" class="block text-sm font-medium text-gray-700">Country</label>
                      <select id="country" v-model="user.country" name="country" autocomplete="country-name" class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3  focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="" selected>Select an option</option>
                        <option v-for="code in countries" :value="code.short_code" :key="code">{{code.name}}</option>

                      </select>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="city" class="block text-sm font-medium text-gray-700">City</label>
                      <input type="text" name="city" id="city" :value="user.city"  autocomplete="family-name" class="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>


          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"  v-if="user.user_type === 'scholar'">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Social media accounts
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="col-span-6 max-w-lg">
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="grid grid-cols-6 gap-6">

                    <div class="col-span-6 sm:col-span-3">
                      <label for="company-website" class="block text-sm font-medium text-gray-700">Facebook</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm ">
                  http://
                </span>
                        </div>
                        <input type="text" :value="user.facebook" name="facebook" id="facebook" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
                      </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3">
                      <label for="company-website" class="block text-sm font-medium text-gray-700">Linkedin</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm ">
                  http://
                </span>
                        </div>
                        <input type="text" :value="user.linkedin" name="linkedin" id="linkedin" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
                      </div>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="company-website" class="block text-sm font-medium text-gray-700">Twitter</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm ">
                  http://
                </span>
                        </div>
                        <input type="text" :value="user.twitter" name="twitter" id="twitter" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
                      </div>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="instagram" class="block text-sm font-medium text-gray-700">instagram</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm ">
                  http://
                </span>
                        </div>
                        <input type="text" :value="user.instagram" name="instagram" id="instagram" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
                      </div>
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                      <label for="company-website" class="block text-sm font-medium text-gray-700">Website</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm ">
                  http://
                </span>
                        </div>
                        <input type="text" :value="user.website" name="website" id="website" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>



    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md  text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Cancel
        </button>
        <button type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent  text-sm font-medium rounded-md text-white bg-gray-900 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import ImageUpload from "@/components/elements/ImageUpload";
import RoundProfileImage from "@/components/elements/Images/RoundProfileImage";
import {countries,getCountries} from "@/helpers/countries";
import {onMounted, ref} from "vue";
import {user,updateUser} from "@/store/auth";
import {notify} from "@kyvg/vue3-notification";
const titles = [ 'Dr', 'Esq', 'Hon', 'Jr', 'Mr', 'Mrs', 'Ms', 'Messrs', 'Mmes', 'Msgr', 'Prof', 'Rev', 'Rt', 'Hon', 'Sr', 'St'];
export default {
  name: "PersonalSettings",
  components:{ImageUpload,RoundProfileImage},
  setup(){
    const wordCount = ref(275);
    onMounted(()=>{
      getCountries();
    })
    const reduceWords = () => {
      const target =  document.getElementById('scholar_bio')
      const maxLength = target.getAttribute("maxlength");
      const currentLength = target.value.length;


      if (currentLength >= maxLength) {
        notify({
          text:'You have reached the maximum number of characters.',
          type:'error'
        })
      }
      wordCount.value = maxLength - currentLength;
    }
    return {countries,user,updateUser,titles,reduceWords,wordCount};
  }
}
</script>

<style scoped>

</style>