
import { defineComponent } from "vue";

export default defineComponent({
  name: "TabGroup",
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    onClick: Function,
    currentTab: String,
    xlFillWidth: Boolean,
  },
  methods: {
    handleClick(tab: any) {
      this.onClick && this.onClick(tab);
    },
  },
  computed: {
    buttonWidth() {
      if (this.tabs.length === 0) return;
      return `w-[${1 / this.tabs.length}%]`;
    },
  },
});
