
import { defineComponent } from "vue";
import {checkAuth} from "@/store/auth";
import {onMounted} from "vue";

export default defineComponent({
  setup(){
   onMounted(() => {
     checkAuth();
   })
  }
});
