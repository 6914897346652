<template>
  <navbar></navbar>
  <div class="sm:px-[80px] pb-[86px] mx-auto max-w-[100rem] px-3">
    <button @click="$router.back()" type="button" class="inline-flex mt-50  mt-[80px] items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="20" viewBox="0 0 20 20" width="20"><g stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path d="m7.97484 4.94531-5.05834 5.05829 5.05834 5.0584"/><path d="m17.0831 10h-14.02499"/></g></svg>
      <span>Back to scholarship</span>
    </button>

<!--    <h1 class="text-2xl font-medium mt-8">Apply to scholarship</h1>-->

    <div class="mx-auto " v-if="activeScholarship">
      <div class="grid grid-cols-2 py-5 p-0 md:px-5 gap-[48.9px]">
        <div class="col-span-2 md:col-span-1 order-2 md:order-1">
          <h2 class="text-xl font-medium mb-6 border-b pb-4">Personal Information.</h2>
          <form action="" method="post" @submit.prevent="sendApplication($event, activeScholarship.id)">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6 sm:col-span-3">
                <label for="first_name" class="block text-sm font-medium text-gray-700">First name</label>
                <input type="text" name="first_name" id="first_name" autocomplete="given-name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
              </div>

              <div class="col-span-6 sm:col-span-3">
                <label for="last_name" class="block text-sm font-medium text-gray-700">Last name</label>
                <input type="text" name="last_name" id="last_name" autocomplete="family-name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
              </div>

              <div class="col-span-6">
                <label for="email-address" class="block text-sm font-medium text-gray-700">Email address</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                      <path d="M18.3332 3.0026C18.3332 2.08594 17.5832 1.33594 16.6665 1.33594H3.33317C2.4165 1.33594 1.6665 2.08594 1.6665 3.0026M18.3332 3.0026V13.0026C18.3332 13.9193 17.5832 14.6693 16.6665 14.6693H3.33317C2.4165 14.6693 1.6665 13.9193 1.6665 13.0026V3.0026M18.3332 3.0026L9.99984 8.83594L1.6665 3.0026" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <input type="email" name="email" id="email" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" placeholder="you@example.com" />
                </div>
              </div>

              <div class="col-span-6">
                <label for="phone-number" class="block text-sm font-medium text-gray-700">Phone Number</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 flex items-center">
                    <label for="code" class="sr-only">Country</label>
                    <select id="code" name="code" autocomplete="country" class="max-w-[100px] focus:ring-indigo-500 focus:border-indigo-500 border-r h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
                      <option value="234">+234</option>
<!--                      <option :value="code.phone_code" v-for="code in countries" :key="code">+{{code.phone_code}} {{code.name}}</option>-->
                    </select>
                  </div>
                  <input type="text" name="phone" id="phone_number" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-28 sm:text-sm border-gray-300 rounded-md" placeholder="0702666304" />
                </div>
              </div>

              <div class="col-span-6">
                <label for="address" class="block text-sm font-medium text-gray-700">Home address</label>
                <input type="text" name="address" id="address" autocomplete="email" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
              </div>

              <div class="col-span-6 sm:col-span-3">
                <label for="country" class="block text-sm font-medium text-gray-700">Country</label>
                <select id="country" name="country" autocomplete="country-name" class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                  <option value="" selected>Select an option</option>
                  <option v-for="code in countries" :value="code.short_code" :key="code">{{code.name}}</option>

                </select>
              </div>

              <div class="col-span-6 sm:col-span-3">
                <label for="state" class="block text-sm font-medium text-gray-700">State</label>
                <input type="text" name="state" id="state" autocomplete="given-name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
              </div>

              <div class="col-span-6 sm:col-span-3">
                <label for="area" class="block text-sm font-medium text-gray-700">Area</label>
                <input type="text" name="area" id="area" autocomplete="family-name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
              </div>

              <div class="col-span-6 sm:col-span-3">
                <label for="city" class="block text-sm font-medium text-gray-700">City</label>
                <input type="text" name="city" id="city" autocomplete="family-name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
              </div>

              <div class="col-span-6 sm:col-span-3">
                <label for="company-website" class="block text-sm font-medium text-gray-700">Facebook</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm ">
                  http://
                </span>
                  </div>
                  <input type="url" name="facebook" id="facebook" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
                </div>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <label for="company-website" class="block text-sm font-medium text-gray-700">Linkedin</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm ">
                  http://
                </span>
                  </div>
                  <input type="url" name="linkedin" id="linkedin" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
                </div>
              </div>

              <div class="col-span-6 sm:col-span-3">
                <label for="company-website" class="block text-sm font-medium text-gray-700">Twitter</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm ">
                  http://
                </span>
                  </div>
                  <input type="url" name="twitter" id="twitter" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
                </div>
              </div>

              <div class="col-span-6 sm:col-span-3">
                <label for="company-website" class="block text-sm font-medium text-gray-700">Personal Website</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm ">
                  http://
                </span>
                  </div>
                  <input type="url" name="website" id="website" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
                </div>
              </div>

            </div>

            <h2 class="text-xl font-medium mb-6 border-b pb-4 my-4 col-span-6">Scholarship Information.</h2>

            <div class="mt-4">
              <label for="frequency" class="block text-sm font-medium text-gray-700">
                Educational level
              </label>
              <div class="mt-1">

                <input type="hidden" name="education_level" v-model="education_level">
                <SelectElement :border="true"  @updated='val => education_level = val'  :items="['Secondary','High School','Undergraduate','Bachelor’s degree program','Master’s degree program']" :selected="0"/>
              </div>
            </div>

            <div class="col-span-6 mt-4">
              <label for="essay" class="block text-sm font-medium text-gray-700">{{activeScholarship.eligibility?.essay_requirements || 'Write an essay'}} </label>
              <div class="mt-1">
                <textarea id="essay" name="essay" rows="3"  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          placeholder="Enter your Essay..."></textarea>
              </div>
            </div>

          <template v-if="activeScholarship.eligibility.linkRequirements">
            <div class="col-span-6 mt-4"   v-for="(link_requirement,index) in activeScholarship.eligibility.linkRequirements" :key="link_requirement">
              <label :for="'address'+index" class="block text-sm font-medium text-gray-700">{{link_requirement.name}}</label>
              <input type="url" name="address" :data-id="link_requirement.id" :id="'address'+index" autocomplete="email" class="links mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
            </div>
          </template>


            <div class="col-span-6 mt-4" v-if="activeScholarship.eligibility.fileRequirements"  >
              <template v-for="(file_requirement,index) in activeScholarship.eligibility.fileRequirements" :key="file_requirement" >
              <label for="about" class="block text-sm font-medium text-gray-700">{{file_requirement.name}}</label>
               <input type="file" :data-id="file_requirement.id"   @input="handleChange($event,'document_show_'+index)" :name="'document_'+index" :id="'document_'+index" class="invisible absolute files">
              <div class="mt-1">
                <button  @click="triggerFile('document_'+index)" type="button" class="inline-flex items-center px-4 py-2 border  shadow-sm text-base font-medium rounded-md text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-0">
                  <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10.1669 9.83645L8.99187 11.0114C8.34187 11.6614 8.34187 12.7198 8.99187 13.3698C9.64187 14.0198 10.7002 14.0198 11.3502 13.3698L13.2002 11.5198C14.5002 10.2198 14.5002 8.11146 13.2002 6.80313C11.9002 5.50312 9.79188 5.50312 8.48355 6.80313L6.46689 8.81978C5.35023 9.93644 5.35023 11.7448 6.46689 12.8614" stroke="#FCFCFD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.99984 18.3307C14.6022 18.3307 18.3332 14.5998 18.3332 9.9974C18.3332 5.39502 14.6022 1.66406 9.99984 1.66406C5.39746 1.66406 1.6665 5.39502 1.6665 9.9974C1.6665 14.5998 5.39746 18.3307 9.99984 18.3307Z" stroke="#FCFCFD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                  Upload document
                </button>
                <span :id="'document_show_'+index" class="block"> {{selectedDocument}}</span>
              </div>
                <p class="text-gray-500 text-sm mt-4">Allowed attachment formats .pdf; maximum attachment size 2 MB</p>
              </template>
            </div>

            <div class="border-t mt-8 pt-8 flex justify-end">
              <button @click="$router.back()" type="button" class="inline-flex items-center px-3 py-2 border border-gray-900 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
                <span class="md:inline-block">Cancel</span>
              </button>

              <button type="submit" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3 bg-black hover:bg-gray-700 focus:outline-none ">
                <span class="md:inline-block">Apply</span>
              </button>

            </div>


          </form>
        </div>
        <div v-if="activeScholarship" class="col-span-2 md:col-span-1 order-1 md:order-2">
          <ScholarshipDetailCard :scholarship="activeScholarship"/>
        </div>
      </div>
    </div>



  </div>

</template>

<script>
import Navbar from "@/components/Navbar";
import ScholarshipDetailCard from "@/components/ScholarshipDetailCard";
import {getScholarshipById,activeScholarship} from "@/store/scholarship";
import {onMounted,watch,ref} from "vue";
import {useRoute} from "vue-router";
import {countries,getCountries} from "@/helpers/countries";
import {status,sendApplication} from "@/store/application";
import ImageUpload from "@/components/elements/ImageUpload";
import SelectElement from "@/components/elements/SelectElement";
import router from "@/router";
export default {
  name: "AppleToScholarship",
  components:{Navbar,ScholarshipDetailCard,SelectElement},
  setup(){
    const route = useRoute();
    const selectedDocument = ref();
    const education_level = ref();
    watch(status,(newVal)=>{
         if (newVal === 'sent'){
             router.push({name:'application-sent'})
         }
    })
    onMounted(()=>{
      getCountries();
      getScholarshipById(route.params.id);
    })

    const triggerFile = (id) => {
      document.getElementById(id).click();
    }

    const handleChange = (evn,id) => {
         document.getElementById(id).innerText =  evn.target.files[0].name;
    }

    return {activeScholarship,countries,
      getScholarshipById,sendApplication,
      triggerFile,selectedDocument,handleChange,education_level}
  }
}
</script>

<style scoped>

</style>