import {host} from "@/helpers/api";
import {ref} from 'vue';
const file  = ref();

const saveFile =  (input: any )  => {
    const form = new FormData()
    form.append('file', input.files[0])
   return  fetch(`https://staging.joinsteward.com/api/upload`,
        {
            method:"post",
            body:form,
            headers:{'Accept':'application/json, text/plain',
                'Authorization' : 'Bearer '+localStorage.getItem('token'),
            },

        });
}

export {saveFile}