<template>
  <onboarding-layout
    title="Verify your email"
    subtitle=""
    icon="onboarding/verify_email.svg"
  >
    <template v-slot:other-content>
      <div class="mb-4">
        <div class="text-gray-500 text-center">
          We sent an email to
          <span class="font-medium italic text-black">{{ email }}</span
          ><br />
          Please verify your email to proceed.
        </div>
        <div class="flex items-center justify-end mt-4">
          <a class="block-button"> Go to mail </a>
        </div>
        <div
          class="text-center my-7 cursor-pointer"
          @click="resendVerificationCode"
        >
          Resend email
        </div>
        <div class="bg-[#F9FAFB] px-4 py-2">
          <p>Having troubles verifying? check for one of these</p>
          <ul
            style="list-style-type: disc"
            class="text-gray-500 text-xs tracking-wide px-7 py-2"
          >
            <li>The email is in your spam folder.</li>
            <li>The email address you entered had a typo.</li>
            <li>
              You accidentally entered another email address. (Usually happens
              with auto-complete.)
            </li>
            <li>
              We can’t deliver the email to this address. (Usually because of
              corporate firewalls or filtering.)
            </li>
          </ul>
        </div>
      </div>
    </template>
  </onboarding-layout>
</template>

<script>
import {resendVerificationCode} from "@/store/auth";
import { ref } from "vue";
import onboardingLayout from "@/components/onboarding/onboardingLayout.vue";
export default {
  components: { onboardingLayout },
  name: "EmailVerificationView",
  setup() {
    const email = ref(sessionStorage.getItem("email"));
    return { resendVerificationCode, email };
  },
};
</script>

<style scoped></style>
