<template>
<div class="p-5 border border-gray-300 rounded-[12px] bg-white w-full">
  <div class="header ">
    <img class="w-full h-[128px] object-cover rounded-t-lg" :src="scholarship.image" :alt="scholarship.title">
  </div>
  <div class="flex justify-between mt-4">
    <h3 class="text-gray-800 font-bold grow ">{{scholarship.title}}</h3>
<!--    <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-success-800">-->
<!--   Top picks-->
<!--  </span>-->
  </div>

  <div class="flex grow items-center mt-3 lg:mb-0 ">
     <RoundProfileImage :size="5" :url="scholarship.partner?.logo" :username="scholarship.partner?.business_name"  />
    <span class="ml-3 text-sm text-gray-700">{{scholarship.partner?.business_name || ''}}</span>
  </div>

  <div class="bg-gray-100 rounded-lg p-[8px] mt-4">
    <p class="text-gray-500 text-sm">
      {{scholarship.summary}}
    </p>
  </div>

  <div v-if="!hide" class="flex mt-4 justify-between flex-wrap">
    <div class="flex flex-col">
      <span class="text-[12px] text-gray-600">Amount</span>
      <span>{{scholarship.currency?.short_code}} {{commaSeparator(scholarship.amount)}}</span>
    </div>
    <div class="flex flex-col">
      <span class="text-[12px] text-gray-600">Scholarships awarded</span>
      <span>{{scholarship.winners}} winners</span>
    </div>

    <div class="flex flex-col md:mt-0">
      <span class="text-[12px] text-gray-600">Education level</span>
      <span>{{scholarship.eligibility?.education_level || 'Any'}}</span>
    </div>
  </div>


</div>
</template>

<script>
import RoundProfileImage from "@/components/elements/Images/RoundProfileImage";
import {commaSeparator} from "@/helpers/display";
export default {
  name: "ScholarshipDetailCard",
  props:['scholarship','hide'],
  components:{RoundProfileImage},
  setup(){
    return {commaSeparator}
  }
}
</script>

<style scoped>

</style>