import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.nullUser)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([_ctx.dimensions]),
        style: _normalizeStyle(_ctx.style)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(`rounded-full ${_ctx.dimensions} flex justify-center items-center overflow-hidden`)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`rounded-full ${_ctx.dimensions} border border-gray-100 flex justify-center items-center overflow-hidden`)
          }, null, 2)
        ], 2)
      ], 6))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass([_ctx.dimensions]),
        style: _normalizeStyle(_ctx.style)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(`rounded-full ${_ctx.dimensions} flex justify-center items-center overflow-hidden`)
        }, [
          (_ctx.url)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "object-cover w-full h-full",
                src: _ctx.url,
                alt: _ctx.username ? `${_ctx.username}'s profile picture` : 'a profile picture'
              }, null, 8, _hoisted_1))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(`rounded-full ${_ctx.dimensions} border border-gray-300 flex justify-center items-center overflow-hidden`)
              }, _toDisplayString(_ctx.username
            ?.split(" ")
            .slice(0, 2)
            .map((name) => name.toUpperCase().charAt(0))
            .join("")), 3))
        ], 2)
      ], 6))
}