
import TabGroup from "@/components/elements/TabGroup.vue";
import { defineComponent } from "vue";
import {commaSeparator} from "@/helpers/display";
import HeaderCell from "@/components/elements/Tables/SimpleTable/HeaderCell.vue";
import DataCell from "@/components/elements/Tables/SimpleTable/DataCell.vue";
import DataRow from "@/components/elements/Tables/SimpleTable/DataRow.vue";
import {getScholarships,scholarshipList} from "@/store/scholarship";
import EmptyState from '@/components/state/EmptyState.vue'
import { longFormDate } from "@/helpers/date";
import { Frequency, Scholarship } from "@/types";
import Pagination from "@/components/Pagination.vue";
import {user} from "@/store/auth";
import StatusElement from "@/components/elements/StatusElement.vue";

const tabs = ["All", "One-time", "Recurring"];
export default defineComponent({
  components: {
    TabGroup,
    HeaderCell,
    DataCell,
    DataRow,
    EmptyState,
    Pagination,
    StatusElement
  },

  name: "ListScholarships",
  data() {
    return {
      frequency: "All",
      currentTab: tabs[0],
      tableData: [] as Scholarship[],
      searchValue: "",
      pages: 7,
      scholarships:scholarshipList
    };
  },
  mounted() {
    this.getTableData();
    getScholarships(null,null,1)
  },
  computed: {
    tabs() {
      if (user.value.user_type === 'scholar'){
       return  ['All scholarships','Scholarships Won'];
      }

      return tabs;
    },
    headers() {
      if (user.value.user_type === 'scholar'){
        return  [
          'Scholarship Description','Status','Rewards','Applications','Winners','Date'
        ]
      }

      return [
        "Scholarship Title",
        "Applications",
        "Winners",
        "status",
        "Amount awarded",
        "Date",
      ];
    },
  },

  methods: {
    commaSeparator,
    longFormDate,
    setCurrentTab(tab: string) {
      this.currentTab = tab;
    },
    getTableData() {
      const frequencies: Frequency = {
        All: "All",
        "One-time": "One-time",
        Recurring: "Annually",
      };
      if (this.currentTab == 'All'){
        getScholarships(null,null,1)
      }else {
        getScholarships(this.searchValue,this.currentTab,1)
      }
    },
    handleInput() {
      getScholarships(this.searchValue,this.frequency, 1)
    },

  },
  watch: {
    currentTab() {
      this.getTableData();
    },
  },
});
