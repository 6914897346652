<template>
  <EmptyState title="Communications is coming soon"
              description="This exciting feature is not available yet. Please watch this space."
  button="Back to home"  @action="$router.push({name:'home'})"
  >
    <img class="h-28 w-28 rounded-full" src="@/assets/rocket-light.gif">
  </EmptyState>
</template>

<script>
import EmptyState from "@/components/state/EmptyState";
export default {
  name: "IndexText",
  components:{EmptyState}
}
</script>

<style scoped>

</style>