import {notify} from "@kyvg/vue3-notification";
export const  format = (input:any) => {
    let nStr = input.target.value + '';
    nStr = nStr.replace(/,/g, "");
   const x = nStr.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? '.' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    input.target.value = x1 + x2;
}

const fallbackCopyTextToClipboard = (text:any) =>  {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}
export  const copyTextToClipboard = (text:any) =>{
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function() {
       notify({
           text:'Link has been added to your clipboard',
           type:'success'
       })
    }, function(err) {
        notify({
            text:'Async: Could not copy text: '+ err,
            type:'error'
        })
    });
}