<template>
  <navbar></navbar>
  <div class="sm:px-[80px] py-[50px] mx-auto max-w-[100rem] px-3">
    <button @click="$router.back()" type="button" class="inline-flex  items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="20" viewBox="0 0 20 20" width="20"><g stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path d="m7.97484 4.94531-5.05834 5.05829 5.05834 5.0584"/><path d="m17.0831 10h-14.02499"/></g></svg>
      <span>Back to scholarship</span>
    </button>

    <h1 class="text-2xl font-medium mt-8">Sponsor a scholar</h1>

    <div class="mx-auto max-w-7xl" v-if="activeScholarship">
        <div class="grid grid-cols-2 px-0 py-5  md:px-5 gap-[48.9px]">
          <div class="col-span-2 md:col-span-1 order-2 md:order-1">
          <h2 class="text-lg font-medium mb-6">Frequency</h2>
          <form @submit.prevent='makePayment($event, activeScholarship.id)'  action="">

            <div class="grid grid-cols-6 gap-6 mt-6">


              <div class="col-span-6 sm:col-span-6">
                <label for="first-name" class="block text-sm font-medium text-gray-700">Name</label>
                <input type="text" required v-model="name"   name="name" id="first-name" autocomplete="given-name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
              </div>

              <div class="col-span-6 sm:col-span-6">
                <label for="other_name" class="block text-sm font-medium text-gray-700">Email</label>
                <input type="email"
                       name="email" id="email" v-model="email" required class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
              </div>
            </div>

            <div class="mt-6">
              <label for="amount" class="block text-sm font-medium text-gray-700">
                 Amount
              </label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <input v-model="amount" type="text" required oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');" name="amount" @keyup="format"  id="amount" class="block w-full rounded-md border-gray-300  pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Enter amount here" />
                <div class="absolute inset-y-0 right-0 flex items-center">
                  <label for="currency" class="sr-only">Currency</label>
                  <select v-model="selectedCurrency" id="currency" name="currency" class="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    <option>USD</option>
                    <option>UGX</option>
                    <option>NGN</option>
                  </select>
                </div>
              </div>
              <p class="text-gray-500 text-sm mt-[6px]">Amount must be at least {{activeScholarship.sponsorship?.currency || 'USD' }}  {{minimumAmount}}</p>

            </div>
<!--            <div class="mt-6">-->
<!--              <label for="payment_method" class="block text-sm font-medium text-gray-700">-->
<!--                Payment type-->
<!--              </label>-->
<!--              <div class="mt-1">-->
<!--                <select id="payment_method" required  name="payment_method"  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">-->
<!--                  <option value="" selected>Select payment type</option>-->
<!--                  <option value="mobile_money">Mobile Money</option>-->
<!--                  <option value="card">Card</option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->

            <div class="mt-6">
              <label for="frequency" class="block text-sm font-medium text-gray-700">
                Frequency
              </label>
              <div class="mt-1">
                <select id="frequency" required  name="frequency"  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                  <option value="" selected>Select frequency of payment</option>
                  <option value="one-time">One Time</option>
                  <option value="monthly">Monthly</option>
                  <option value="annually">Annually</option>
                </select>
              </div>
            </div>

            <div class="relative flex items-start mt-6">
              <div class="flex items-center h-5">
                <input id="pay_fee" aria-describedby="comments-description" name="fee_paid_by" value="user" type="checkbox" class="focus:ring-0 h-4 w-4 text-green-400 border-green-300 rounded" />
              </div>
              <div class="ml-3 text-sm">
                <label for="pay_fee" class=" text-gray-700">Pay transaction fee for this donation</label>
              </div>
            </div>

            <div class="is_anonymous flex items-start mt-6">
              <div class="flex items-center h-5">
                <input id="is_anonymous" value="1" aria-describedby="comments-description" name="is_anonymous" type="checkbox" class="focus:ring-0 h-4 w-4 text-green-400 border-green-300 rounded" />
              </div>
              <div class="ml-3 text-sm">
                <label for="is_anonymous" class="text-gray-700">Donate anonymously</label>
              </div>
            </div>

            <button type="submit" @click="makePayment" class="flex w-full justify-center mt-8 items-center px-3 py-[10px] border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white  bg-black hover:bg-gray-700 focus:outline-none ">
              <span class="md:inline-block font-medium">Continue</span>
            </button>


          </form>
          </div>

          <div class="col-span-2 md:col-span-1 order-1 md:order-2">
            <ScholarshipDetailCard v-if="activeScholarship" :scholarship="activeScholarship"/>
          </div>
        </div>
    </div>



  </div>

</template>

<script>
import Navbar from "@/components/Navbar";
import ScholarshipDetailCard from "@/components/ScholarshipDetailCard";
import {activeScholarship,getScholarshipById} from "@/store/scholarship";
import {format} from "@/helpers/input";
import {onMounted, ref, watch} from "vue";
import {useRoute,useRouter} from "vue-router";
import {getRate} from "@/store/payments";
import {ui} from "@/helpers/api"
export default {
  name: "MakePayment",
  components:{Navbar,ScholarshipDetailCard},
  setup(){
    const route = useRoute();
    const router = useRouter();
    activeScholarship.value = null;
    const selectedCurrency   =  ref('USD');
    const minimumAmount = ref( 10 )
    const amount = ref(  )
    const email = ref()
    const name = ref()

    onMounted(()=>{
      console.log(ui+router.resolve({name:'payment-received'}).fullPath )
      getScholarshipById(route.params.id)
    });

    const updateCurrency = (rate) => {
      minimumAmount.value = (rate * minimumAmount.value).toFixed(2);
    }

    watch(selectedCurrency,(newVal)=>{
      getRate(newVal,activeScholarship.sponsorship?.minimum_amount || 10 ,updateCurrency);
    })

    watch(activeScholarship,(newVal)=>{
      if(newVal){
        minimumAmount.value = newVal.sponsorship?.minimum_amount || 10
      }

    })

    function makePayment() {
     window.FlutterwaveCheckout({
        public_key: "FLWPUBK_TEST-969525a06581d8c56c21af73ba3c1bfa-X",
        tx_ref: "txr_"+Date.now(),
        amount: amount.value,
        currency: selectedCurrency.value,
        payment_options: "card, banktransfer, ussd",
        redirect_url: ui+router.resolve({name:'payment-received'}).fullPath ,
        meta: {
          scholarship_id: activeScholarship.value.id,
        },
        customer: {
          email: email.value,
          name: name.value,
        },
        customizations: {
          title: activeScholarship.value.title,
          description: "Payment for "+ activeScholarship.value.title,
          logo: activeScholarship.value.image,
        },
      });
    }

    return {activeScholarship,
      makePayment,format,
      selectedCurrency,
      minimumAmount,amount,email,name}
  }
}
</script>

<style scoped>

</style>