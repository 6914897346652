<template>
    <h3 class="pl-4 mb-[40px]">Sponsors  <span class=" inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
    {{partnerSponsors.length}}
  </span>
    </h3>

    <div class="flex mb-[21px] justify-between pl-4 "  v-if="partnerSponsors.length > 0">
      <div class="md:w-[40%]">
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <input v-model="searchValue" @input="handleSearch" type="text" name="search" id="search" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" placeholder="Search" />
        </div>
      </div>

      <div class="flex">
        <!--      <button type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">-->
        <!--        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">-->
        <!--          <path d="M4 6H14M1.5 1H16.5M6.5 11H11.5" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>-->
        <!--        </svg>-->
        <!--        <span class="ml-[13px] hidden md:inline-block">Filters</span>-->
        <!--      </button>-->

        <!--      <button type="button" class="ml-[12px] inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">-->
        <!--        <span class="inline-block">Export</span>-->
        <!--      </button>-->

      </div>

    </div>
    <div class="flex flex-col" v-if="partnerSponsors.length > 0">
      <div class="-my-2 overflow-x-auto ">
        <div class="py-2 align-middle inline-block min-w-full">
          <div class="overflow-hidden border-b border-gray-200">
            <table class="min-w-full divide-y divide-gray-200 ">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">
                  Sponsors
                </th>

                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">
                  Donation
                </th>

                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">
                  Date
                </th>

              </tr>
              </thead>
              <tbody v-if="partnerSponsors.length> 0" class="bg-white divide-y divide-gray-200">
              <tr v-for="sponsor in partnerSponsors" :key="sponsor.email" @click="$router.push({name:'sponsor-profile',params:{id:sponsor.id}})">
                <td class="px-6 py-4 whitespace-nowrap w-[75%]">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <RoundProfileImage :size="10" :username="sponsor.customer_name" />
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-500">
                        {{ sponsor.customer_name }}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ sponsor.email }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm text-gray-500">{{sponsor.currency}} {{commaSeparator(sponsor.amount)}}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{longFormDate(sponsor.created_at)}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination/>

    </div>
    <EmptyState class="mb-20" v-else title="No Sponsors records yet" description="your records will appear here once you have sponsored scholars">

    </EmptyState>

</template>

<script>
import Pagination from "@/components/Pagination";
import RoundProfileImage from "@/components/elements/Images/RoundProfileImage";
import EmptyState from "@/components/state/EmptyState";
import {onMounted, ref} from "vue";
import {getPartnerSponsors, partnerSponsors} from "@/store/partner";
import {user} from "@/store/auth";
import {longFormDate} from "@/helpers/date";
import {commaSeparator} from "@/helpers/display";

export default {
  components:{Pagination,RoundProfileImage,EmptyState},
  name: "SponsorList",
  setup() {
    const searchValue =  ref('');
    onMounted(()=>{
      getPartnerSponsors(user.value.id)
    })
    const  handleSearch = () => {
      console.log('search');
    }
    return {
      Pagination,
      longFormDate,
      searchValue,
      handleSearch,
      commaSeparator,
      partnerSponsors
    }
  },
}
</script>

<style scoped>

</style>