<template>
  <div class="mt-6">
    <div class="py-2 align-middle inline-block min-w-full">
      <div v-if="outFlowList.length > 0" class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200 whitespace-nowrap">
          <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalise tracking-wider">
              Destination
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalise tracking-wider">
              Amount
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalise tracking-wider">
              Balance before
            </th>

            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalise tracking-wider">
              Balance after
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalise tracking-wider">
              Date
            </th>
          </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
          <tr>
            <td class="px-6 py-4 whitespace-nowrap ">
              <div class="text-sm text-gray-500">********5475/021000089</div>
            </td>
            <td>
              <div class="text-sm text-gray-500"> USD 1,000.00</div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="text-sm text-gray-500">USD 1,000.00</div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="text-sm text-gray-500">USD 0.00</div>
            </td>
            <td>
              <div class="text-sm text-gray-500">Aug 12, 2020 9:37 AM</div>

            </td>

          </tr>

          </tbody>
        </table>
      </div>
      <EmptyState  v-else   title="No Outflow Payment records yet" description="your records will appear here once you make any payouts" />
    </div>
  </div>
</template>

<script>
import {outFlowList} from "@/store/payments";
import EmptyState from "@/components/state/EmptyState.vue";
export default {
  name: "OutflowPayments",
  components: {EmptyState},
  setup(){
    return {outFlowList}
  }
}
</script>

<style scoped>

</style>