<template>
  <div>
    <TransitionRoot as="template" :show="show">
      <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="$emit('close')">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div>
                  <button @click="$emit('close')" class="absolute right-[10px] top-[13px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                      <circle cx="16" cy="16" r="16" fill="#D0D5DD"/>
                      <path d="M11.0168 11.0156L21.1992 21.198" stroke="#000409" stroke-width="1.16667" stroke-linecap="round"/>
                      <path d="M11.0168 21.2031L21.1992 11.0208" stroke="#000409" stroke-width="1.16667" stroke-linecap="round"/>
                    </svg>
                  </button>
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                    {{title}}
                  </DialogTitle>
                  <div class="mt-6">
                    <slot/>
                  </div>
                </div>
              </div>

            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default {
  name:'MainModal',
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  props:['show','title'],
  setup() {
    return {
    }
  },
}
</script>