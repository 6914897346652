
import { defineComponent } from "vue";

export default defineComponent({
    name: 'ImageUpload',
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        onChange: Function,
        value: {
            type: String
        },
      name: {
        type: String,
      }
    },
    data() {
        return {
            files: [],
        }
    },
    methods: {
        setFiles(e: any) {
            this.files = e.target.files;
        },
        dataURL(f: File) {
            return URL.createObjectURL(f);
        },
    },
    watch: {
        files() {
            if (this.onChange) this.onChange(this.files);
        },
    }
});
