import {host} from "@/helpers/api";
import {ref} from 'vue';
const user =  ref();
const token = ref();
const user_type = ref('scholar');
import {useLoading} from 'vue-loading-overlay'
const $loading = useLoading();
import {notify} from "@kyvg/vue3-notification";

const login = async  (event :any) => {
    const form = new FormData(event.target);

    const res =  await fetch(host+'/login',
        {
            method:"POST",
            headers:{'Accept':'application/json, text/plain',},
            body:form

        });
    const data = await res.json();
    user.value = data.user;
    token.value  = data.token;
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
}


const register = async  (event: any) => {
    const form = new FormData(event.target);

    const res =  await fetch(host+'/register',
        {
            method:"POST",
            headers:{'Accept':'application/json, text/plain',},
            body:form

        });
    const data = await res.json();
    user.value = data.user;
    token.value  = data.token;
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
}

const resendVerificationCode = async ()  => {
    const res  = await  fetch(`${host}/auth/resend/${user.value.email}`, {
        method:"POST",
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token')
        },
    })

    const data = await res.json();
    if (res.ok){
        notify({
            type:'success',
            text:data.message
        })
    }
}
const checkAuth = () => {
    if (localStorage.getItem('user')){
        user.value = JSON.parse(localStorage.getItem('user') || '');
    }
}

const updateUser = async (event:any,id:any) => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const form = new FormData(event.target);
    const res  = await  fetch(host+'/user/'+id, {
        method:"POST",
        body:form,
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token')
        },
    });

    const data = await res.json();
    if (res.ok){
        user.value = data;
        localStorage.setItem('user', JSON.stringify(data));
    }else{
        notify({
            text:data.message || 'We have experienced and error. Please try again',
            type:'error'
        })

    }
    loader.hide()
}


const logOut = async () => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const res = await fetch(`${host}/logout` ,{
        method:'post',
        headers:{
            'Content-Type': 'application/json',
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token')
        }
    });

    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href = '/login';
    loader.hide();
}

const updatePassword = async (event:any) => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const form = new FormData(event.target);
    const res  = await  fetch(host+'/update-password', {
        method:"POST",
        body:form,
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token')
        },
    });
    const data =  await res.json();
    if (res.ok){
        logOut();
    }else{
        notify({
            text:data.message || 'We have experienced and error. Please try again',
            type:'error'
        })

    }

    loader.hide();
}

export    {login,user,register,user_type,resendVerificationCode,checkAuth,updateUser,logOut,updatePassword}