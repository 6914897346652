import {encodeDataToURL, host,} from "@/helpers/api";
import {notify} from "@kyvg/vue3-notification";
import {ref} from "vue";
import {useLoading} from 'vue-loading-overlay'
import {saveFile} from "@/helpers/file";
const $loading = useLoading();
const status = ref();
const applicationList = ref([]);
const  activeApplication = ref();

 const sendApplication = async  (event:any, id:any) => {
     const loader = $loading.show({
         color: " #5271ff",
         opacity: .7,
     });
    const form = new FormData(event.target);
    const formObj :any = Object.fromEntries(form);
    const obj:any = {
        "first_name": formObj.first_name,
        "last_name": formObj.last_name,
        "email": formObj.email,
        "education_level" : formObj.education_level
    };

     obj.phone_number = {
        'countryCode' : formObj.code,
         'localFormat':formObj.phone
     }

     obj.address = {
         "street": "",
         "country": formObj.country,
         "state": formObj.state,
         "city": formObj.city,
         "area": formObj.area,
     }

     obj.socials  =  [
         { "type": "facebook", "url": formObj.facebook },
         { "type": "twiiter", "url": formObj.twitter },
         { "type": "web", "url": formObj.website }
     ];
     const element = event.target;
     obj.requirements = {
         "essay" :formObj.essay,
         "links": [],
         "files": []
     }

     element.querySelectorAll('.links').forEach((el :any) => {
          obj.requirements.links.push([
              {
                  "link_criteria_id": el.getAttribute('data-id'),
                  "url": el.value
              }
          ])
     });
   new Promise((resolve, reject) => {
       console.log(element.querySelectorAll('.files'));
       if ( element.querySelectorAll('.files').length === 0) {resolve('success');}
        element.querySelectorAll('.files').forEach((el :any,index :any,arr:any) => {
            saveFile(el).then(res => {
                res.text().then(path => {
                    obj.requirements.files.push([
                        {
                            "file_criteria_id": el.getAttribute('data-id'),
                            "url": path
                        }
                    ])
                    if (index + 1 === arr.length ){
                        resolve('success');
                    }
                    console.log(index,arr);
                }).catch(() =>reject('error'))
            }).catch(() => reject('error'))
        });
    }).then(async () => {
       const res =  await fetch(host+'/public/scholarship/'+id+'/apply',
           {
               method:"POST",
               headers:{
                   'Accept':'application/json, text/plain',
                   // 'Authorization' : 'Bearer '+localStorage.getItem('token'),
                   'Content-Type':'application/json',
               },
               body:JSON.stringify(obj)
           });
       const data =  await res.json();
       if (res.ok){
           status.value = 'sent';
       }else{
           notify({
               text:data.error || 'We have experienced and error. Please try again',
               type:'error'
           })
       }
   }).finally(() => {
       loader.hide();
   })

}

const getScholarshipsApplications =async (id: any,page = null,search = '') => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const url = host+'/scholarships/'+id+'/applications';
    const parameters: { q?: string |null,page?: number | null,  }  = {
        'q':null,
        'page':null
    };

    if (search){
        parameters ['q'] = search;
    }else{
        delete  parameters.q
    }

    if (page){
        parameters ['page'] = page;
    }else{
        delete  parameters.page
    }

    const res =  await fetch(url+'?'+ encodeDataToURL(parameters),{
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token')
        },
    });
    const data =  await res.json();
    if (res.ok){
        applicationList.value = data.data;
        if (page == 1 && data.data.length == 0){
            applicationList.value = [];
        }
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }

    loader.hide();
}

const getApplicationById = async (id:any) => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    try {


        const res = await fetch(host + '/applications/' + id, {
            headers: {
                'Accept': 'application/json, text/plain',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        });
        const data = await res.json();
        if (res.ok) {
            activeApplication.value = data;
        } else {
            // notify({
            //     text: data.message || 'We have experienced and error. Please try again',
            //     type: 'error'
            // })

        }
    }finally {
        loader.hide();
    }

}

export {sendApplication,status,applicationList,getScholarshipsApplications,getApplicationById,activeApplication};
