<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Listbox as="div" v-model="selectedValue" :multiple="multiple">
    <ListboxLabel class="block text-sm font-medium text-gray-700 sr-only">
      Assigned to
    </ListboxLabel>
    <div class="relative">
      <ListboxButton :class="[ border? ' border ': '' , 'bg-white relative w-full  border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 sm:text-sm']">
        <span class="block truncate">{{ selectedValue || placeholder }}</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="item in items" :key="item" :value="item" v-slot="{ active, selected }">
            <li :class="[active ? 'text-white bg-gray-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                {{ item }}
              </span>

              <span v-if="selected" :class="[active ? 'text-white' : 'text-gray-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import {onMounted, ref, watch} from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, SelectorIcon,ChevronDownIcon } from '@heroicons/vue/solid'


export default {
  name:'SelectElement',
  props:['items', 'multiple','selected','border','placeholder'],
  emits:['updated'],
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronDownIcon
  },
  setup(props,{emit}) {
    const selectedValue = ref(props.items[props.selected]);
    // const selectedValue = ref();
    watch(selectedValue,(newVal) => {
       emit('updated', newVal)
    });

    onMounted(() => {
      console.log(props.items);
      emit('updated', selectedValue.value);
    })


    return {
      selectedValue,
    }
  },
}
</script>