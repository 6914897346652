<template>
  <ProfileCard/>
</template>
<script>

import ProfileCard from "@/components/sponsor/ProfileCard";
export default {
  components:{ProfileCard},
  name: "SponsorProfile"
}
</script>

<style scoped>

</style>