<template>
  <onboarding-layout
    :title="first_screen ? 'Reset your password' : 'Password reset successful!'"
    :subtitle="
      first_screen
        ? 'Welcome back! Please create a new password'
        : 'You have successfully reset password for your Steward account and will be signed in to your dashboard'
    "
    :icon="first_screen ? 'steward-logo.png' : 'onboarding/success_icon.svg'"
  >
    <template v-slot:other-content>
      <div v-if="first_screen">
        <form
          @submit.prevent="saveNewPassword"
          method="POST"
          enctype="multipart/form-data"
        >
          <div class="mt-4 relative">
            <label
              class="block font-medium text-sm text-gray-700"
              for="password"
            >
              Password
            </label>

            <div class="relative password_container">
              <input
                v-model="password"
                class="text_field_input pr-10"
                id="password"
                type="password"
                name="password"
                required
                autocomplete="do-not-autofill"
                placeholder="Enter your password here"
                @input="checkLetters"
              />
              <div
                onclick="document.getElementById('password').setAttribute('type','text'); document.getElementById('password').parentElement.classList.toggle('show-icon')"
                class="absolute inset-y-0 right-0 pr-3 flex items-center"
              >
                <svg
                  class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                  ></path>
                  <path
                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                  ></path>
                </svg>
              </div>

              <div
                onclick="document.getElementById('password').setAttribute('type','password');document.getElementById('password').parentElement.classList.toggle('show-icon')"
                class="absolute inset-y-0 right-0 pr-3 flex items-center hidden"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                  ></path>
                  <path
                    d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                  ></path>
                  <path
                    d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="my-7">
              <div class="flex items-center mt-4">
                <img
                  v-if="uppercase"
                  src="@/assets/onboarding/filled_checkbox.svg"
                  alt=""
                />
                <img
                  v-else
                  src="@/assets/onboarding/empty_checkbox.svg"
                  alt=""
                />

                <span class="font-medium text-gray-700 ml-3 text-sm"
                  >Must contain at least one uppercase letter</span
                >
              </div>
              <div class="flex items-center mt-4">
                <img
                  v-if="number"
                  src="@/assets/onboarding/filled_checkbox.svg"
                  alt=""
                />
                <img
                  v-else
                  src="@/assets/onboarding/empty_checkbox.svg"
                  alt=""
                />

                <span class="font-medium text-gray-700 ml-3 text-sm"
                  >Must contain at least one number</span
                >
              </div>
              <div class="flex items-center mt-4">
                <img
                  v-if="special_character"
                  src="@/assets/onboarding/filled_checkbox.svg"
                  alt=""
                />
                <img
                  v-else
                  src="@/assets/onboarding/empty_checkbox.svg"
                  alt=""
                />

                <span class="font-medium text-gray-700 ml-3 text-sm"
                  >Must contain at least one special character</span
                >
              </div>
              <div class="flex items-center mt-4">
                <img
                  v-if="length"
                  src="@/assets/onboarding/filled_checkbox.svg"
                  alt=""
                />
                <img
                  v-else
                  src="@/assets/onboarding/empty_checkbox.svg"
                  alt=""
                />

                <span class="font-medium text-gray-700 ml-3 text-sm"
                  >Must contain a minimum of 8 characters</span
                >
              </div>
            </div>
          </div>
          <div class="mt-4 relative">
            <label
              class="block font-medium text-sm text-gray-700"
              for="password_confirmation"
            >
              Confirm password
            </label>

            <div class="relative password_container">
              <input
                v-model="password_confirmation"
                class="text_field_input pr-10"
                id="password_confirmation"
                type="password"
                name="password_confirmation"
                required
                autocomplete="do-not-autofill"
                placeholder="Confirm your password"
              />
              <div
                onclick="document.getElementById('password_confirmation').setAttribute('type','text'); document.getElementById('password_confirmation').parentElement.classList.toggle('show-icon')"
                class="absolute inset-y-0 right-0 pr-3 flex items-center"
              >
                <svg
                  class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                  ></path>
                  <path
                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                  ></path>
                </svg>
              </div>

              <div
                onclick="document.getElementById('password_confirmation').setAttribute('type','password');document.getElementById('password_confirmation').parentElement.classList.toggle('show-icon')"
                class="absolute inset-y-0 right-0 pr-3 flex items-center hidden"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                  ></path>
                  <path
                    d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                  ></path>
                  <path
                    d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="text-xs text-[#ff2e2e] font-light mt-1">
              {{ password_confirmation_error_message }}
            </div>
          </div>
          <div class="flex items-center justify-end mt-4">
            <button :disabled="!enabled" type="submit" class="block-button">
              {{ submitting ? "Saving..." : "Save" }}
            </button>
          </div>
        </form>
      </div>
      <div v-else>
        <div class="mt-7 mb-4">
          <router-link to="/login">
            <button class="block-button">Continue</button>
          </router-link>
        </div>
      </div>
    </template>
  </onboarding-layout>
</template>

<script>
import { ref, computed, watch } from "vue";
import { resetPasswordAPI } from "@/api/auth";
import { useRoute } from "vue-router";
import onboardingLayout from "@/components/onboarding/onboardingLayout.vue";
export default {
  components: { onboardingLayout },
  setup() {
    const route = useRoute();
    const first_screen = ref(true);
    const uppercase = ref(false);
    const number = ref(false);
    const special_character = ref(false);
    const length = ref(false);
    const password = ref("");
    const password_confirmation = ref("");
    const password_confirmation_error_message = ref("");
    const submitting = ref(false);
    const enabled = computed(() => {
      return (
        password.value && password_confirmation.value && password_check.value
      );
    });
    const password_check = computed(() => {
      return (
        uppercase.value &&
        number.value &&
        special_character.value &&
        length.value
      );
    });

    watch(password, (currentValue) => {
      if (password_confirmation.value.length) {
        password_confirmation_error_message.value =
          currentValue !== password_confirmation.value
            ? "Passwords Don't Match"
            : "";
      }
    });
    watch(password_confirmation, (currentValue) => {
      if (password_confirmation.value.length) {
        password_confirmation_error_message.value =
          currentValue !== password.value ? "Passwords Don't Match" : "";
      }
    });

    const checkLetters = () => {
      const numbers = /[0-9]/;
      const uppercaseLetters = /[A-Z]/;
      const symbols = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      number.value = password.value.match(numbers) ? true : false;
      uppercase.value = password.value.match(uppercaseLetters) ? true : false;
      special_character.value = password.value.match(symbols) ? true : false;
      length.value = password.value.length >= 8 ? true : false;
    };
    const saveNewPassword = () => {
      if (password.value !== password_confirmation.value) return false;
      submitting.value = true;
      const userId = route.params.userId;
      const data = {
        password: password.value,
        password_confirmation: password_confirmation.value,
      };
      resetPasswordAPI(userId, data).then((res) => {
        if (res.data.status) {
          first_screen.value = false;
        }
        submitting.value = false;
      });
    };

    return {
      first_screen,
      uppercase,
      number,
      special_character,
      length,
      password,
      submitting,
      enabled,
      password_confirmation,
      password_confirmation_error_message,
      password_check,
      checkLetters,
      saveNewPassword,
    };
  },
};
</script>

<style></style>
