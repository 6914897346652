<template>
  <div class="bg-success bg-cover bg-no-repeat">
  <div class="flex justify-between">
    <router-link :to="{name:'create-scholarship-description'}"  type="button" class="inline-flex  items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="20" viewBox="0 0 20 20" width="20"><g stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path d="m7.97484 4.94531-5.05834 5.05829 5.05834 5.0584"/><path d="m17.0831 10h-14.02499"/></g></svg>
      <span>Back to Overview</span>
    </router-link>
<!--    <button type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">-->
<!--        <path d="M11.05 3.49611L4.20829 10.7378C3.94996 11.0128 3.69996 11.5544 3.64996 11.9294L3.34162 14.6294C3.23329 15.6044 3.93329 16.2711 4.89996 16.1044L7.58329 15.6461C7.95829 15.5794 8.48329 15.3044 8.74162 15.0211L15.5833 7.77944C16.7666 6.52944 17.3 5.10445 15.4583 3.36278C13.625 1.63778 12.2333 2.24611 11.05 3.49611Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--        <path d="M9.90845 4.71094C10.2668 7.01094 12.1334 8.76927 14.4501 9.0026" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--        <path d="M2.5 18.8359H17.5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--      </svg>-->
<!--      <span class="ml-[13px] hidden md:inline-block">Edit Details</span>-->
<!--    </button>-->
  </div>
   <h2 class="text-xl text-center my-8">Scholarship created successfully🚀 </h2>
<div class="flex justify-center ">
  <ScholarshipDetailCard v-if="activeScholarship" class="md:max-w-[60%]" :scholarship="activeScholarship" />
</div>

    <div class="flex justify-center mt-11">
      <CopyPublicLink v-if="activeScholarship" :scholarship="activeScholarship"/>
    </div>
  </div>
</template>

<script>
import ScholarshipDetailCard from "@/components/ScholarshipDetailCard";
import CopyPublicLink from "@/components/CopyPublicLink";
import {activeScholarship,getScholarshipById} from "@/store/scholarship";
import {onMounted} from "vue";
export default {
  name: "CreateScholarshipSuccessful",
  components:{ScholarshipDetailCard,CopyPublicLink},
  setup(){

    return {activeScholarship}
  }
}
</script>

<style scoped>

</style>