<template>
  <Listbox as="div" v-model="selected">
    <ListboxLabel class="block text-sm font-medium text-gray-700">Sign up as</ListboxLabel>
    <div class="relative mt-1">
      <ListboxButton class="relative w-full  rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
        <span class="flex items-center" v-if="selected" >
          <span class="block truncate">{{ selected }}</span>
        </span>
        <span class="block truncate text-gray-500" v-else >
          Select an option
        </span>
        <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute z-10 mt-1  w-full bg-white overflow-auto rounded-md  p-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <ListboxOption value="organisation" as="template" v-slot="{ active, selected }" class="p-4">
            <li :class="[active ? 'text-white bg-gray-100' : 'text-gray-900', 'relative   select-none py-2 pl-3 pr-9 flex  border bg-white rounded-lg cursor-pointer']">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="24" viewBox="0 0 24 24" width="24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><g stroke="#667085"><path d="m18.0001 7.16c-.06-.01-.13-.01-.19 0-1.38-.05-2.48-1.18-2.48-2.58 0-1.43 1.15-2.58 2.58-2.58s2.58 1.16 2.58 2.58c-.01 1.4-1.11 2.53-2.49 2.58z"/><path d="m16.9699 14.4402c1.37.23 2.88-.01 3.94-.72 1.41-.94 1.41-2.48 0-3.42-1.07-.71004-2.6-.95004-3.97-.71004"/><path d="m5.96998 7.16c.06-.01.13-.01.19 0 1.38-.05 2.48-1.18 2.48-2.58 0-1.43-1.15-2.58-2.58-2.58s-2.58 1.16-2.58 2.58c.01 1.4 1.11 2.53 2.49 2.58z"/></g><path d="m6.99994 14.4402c-1.37.23-2.88-.01-3.94-.72-1.41-.94-1.41-2.48 0-3.42 1.07-.71004 2.6-.95004 3.97-.71004" stroke="#292d32"/><path d="m12.0001 14.6288c-.06-.01-.13-.01-.19 0-1.38-.05-2.48002-1.18-2.48002-2.58 0-1.43 1.15002-2.58005 2.58002-2.58005s2.58 1.16005 2.58 2.58005c-.01 1.4-1.11 2.54-2.49 2.58z" stroke="#292d32"/><path d="m9.09021 17.7794c-1.41.94-1.41 2.48 0 3.42 1.59999 1.07 4.21999 1.07 5.81999 0 1.41-.94 1.41-2.48 0-3.42-1.59-1.06-4.22-1.06-5.81999 0z" stroke="#292d32"/></g></svg>
              </span>

              <div class="grow ml-2">
                <p class="font-medium text-gray-900">Organisations</p>
                <p class="text-gray-500 text-sm">Create a portfolio of scholars, raise funds from sponsors anywhere in the world </p>
              </div>

              <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#E7E7E7"/>
                  <circle cx="10" cy="10" r="4" fill="#000712"/>
                  <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#000712"/>
                  </svg>
              </span>
              <span v-else :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
             <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="white"/>
              <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#D0D5DD"/>
              </svg>
              </span>
            </li>


          </ListboxOption>

          <ListboxOption value="sponsor" as="template" v-slot="{ active, selected }" class="p-4">
            <li :class="[active ? 'text-white bg-gray-100' : 'text-gray-900', 'relative  bg-white select-none py-2 pl-3 pr-9 flex border bg-white rounded-lg my-2 cursor-pointer']">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.9999 11.0037C13.2867 11.0037 14.3299 9.96053 14.3299 8.67371C14.3299 7.38688 13.2867 6.34375 11.9999 6.34375C10.7131 6.34375 9.66992 7.38688 9.66992 8.67371C9.66992 9.96053 10.7131 11.0037 11.9999 11.0037Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16 16.6662C16 14.8662 14.21 13.4062 12 13.4062C9.79 13.4062 8 14.8662 8 16.6662" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </span>

              <div class="grow ml-2">
                <p class="font-medium text-gray-900">Sponsor</p>
                <p class="text-gray-500 text-sm">Create your own scholarship or sponsor through partners. Define your own selection criteria and Steward will handle everything else.  </p>
              </div>

              <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#E7E7E7"/>
                  <circle cx="10" cy="10" r="4" fill="#000712"/>
                  <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#000712"/>
                  </svg>
              </span>
              <span v-else :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
             <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="white"/>
              <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#D0D5DD"/>
              </svg>
              </span>
            </li>


          </ListboxOption>

          <ListboxOption value="scholar" as="template" v-slot="{ active, selected }" class="p-4">
            <li :class="[active ? 'text-white bg-gray-100' : 'text-gray-900', 'relative  select-none py-2 pl-3 pr-9 flex border bg-white rounded-lg cursor-pointer ']">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M10.05 2.53126L4.03002 6.46126C2.10002 7.72126 2.10002 10.5413 4.03002 11.8013L10.05 15.7313C11.13 16.4413 12.91 16.4413 13.99 15.7313L19.98 11.8013C21.9 10.5413 21.9 7.73126 19.98 6.47126L13.99 2.54126C12.91 1.82126 11.13 1.82126 10.05 2.53126Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.63012 13.0781L5.62012 17.7681C5.62012 19.0381 6.60012 20.3981 7.80012 20.7981L10.9901 21.8581C11.5401 22.0381 12.4501 22.0381 13.0101 21.8581L16.2001 20.7981C17.4001 20.3981 18.3801 19.0381 18.3801 17.7681V13.1281" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.3999 15V9" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              </span>

              <div class="grow ml-2">
                <p class="font-medium text-gray-900">Scholars</p>
                <p class="text-gray-500 text-sm">Make your dreams come true by taking advantage of available scholarship opportunities from our sponsors and partners.</p>
              </div>

              <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#E7E7E7"/>
                  <circle cx="10" cy="10" r="4" fill="#000712"/>
                  <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#000712"/>
                  </svg>
              </span>
              <span v-else :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
             <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="white"/>
              <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#D0D5DD"/>
              </svg>
              </span>
            </li>


          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
import { ref } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/solid'
import { getCurrentInstance,watch} from 'vue'
const { emit } = getCurrentInstance()

const selected = ref();
watch(selected, (newVal) => {
   emit('selected', newVal);
})
</script>