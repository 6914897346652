<template>
  <div class="xl:mt-[5.78px] h-full">
    <div class="xl:mx-auto w-full xl:max-w-5xl h-full bg-white p-6">

    <button @click="$router.back()" type="button" class="inline-flex  items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="20" viewBox="0 0 20 20" width="20"><g stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path d="m7.97484 4.94531-5.05834 5.05829 5.05834 5.0584"/><path d="m17.0831 10h-14.02499"/></g></svg>
      <span>Back to Sponsors</span>
    </button>

    <h2 class="text-xl my-10">Add new sponsor</h2>
    <form class="mb-20" id="descriptionForm" action="#"   method="POST">

      <input type="hidden" name="state" value="draft">
      <input type="hidden" name="frequency" value="monthly">
      <input type="hidden" name="organisation" value="1">
      <div class="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-3 col-span-6 order-2 md:order-1 grid grid-cols-6 gap-4">

          <div class="sm:col-span-3">
            <label for="first_name" class="block text-sm font-medium text-gray-700">
              First name
            </label>
            <div class="mt-1">
              <input type="text" required name="first_name" id="first_name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:col-span-3 ">
            <label for="last_name" class="block text-sm font-medium text-gray-700">
              Last name
            </label>
            <div class="mt-1">
              <input type="text" required name="last_name" id="last_name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

            <div class="mt-4 col-span-6">
              <div class="col-span-6 max-w-lg">
                <label for="email-address" class="block text-sm font-medium text-gray-700">Email address</label>
                <div class="mt-1 relative rounded-md ">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                      <path d="M18.3332 3.0026C18.3332 2.08594 17.5832 1.33594 16.6665 1.33594H3.33317C2.4165 1.33594 1.6665 2.08594 1.6665 3.0026M18.3332 3.0026V13.0026C18.3332 13.9193 17.5832 14.6693 16.6665 14.6693H3.33317C2.4165 14.6693 1.6665 13.9193 1.6665 13.0026V3.0026M18.3332 3.0026L9.99984 8.83594L1.6665 3.0026" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <input   type="email" required name="email" id="email" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" placeholder="you@example.com" />
                </div>
              </div>
            </div>



            <div class="mt-4  col-span-6">
              <div class="col-span-6 max-w-lg">
                <label for="phone-number" class="block text-sm font-medium text-gray-700">Phone Number</label>
                <div class="mt-1 relative rounded-md ">
                  <div class="absolute inset-y-0 left-0 flex items-center">
                    <label for="code" class="sr-only">Country</label>
                    <select id="code" name="code" autocomplete="country" class="max-w-[100px] focus:ring-indigo-500 focus:border-indigo-500 border-r h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
                      <option value="256">+256 Uganda</option>
                      <option v-for="code in countries"  :value="code.phone_code"  :key="code">+{{code.phone_code}} {{code.name}}</option>
                    </select>
                  </div>
                  <input type="text" name="phone"  id="phone_number" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-28 sm:text-sm border-gray-300 rounded-md" placeholder="+1 (555) 987-6543" />
                </div>
              </div>
            </div>

          <div class="relative flex items-start col-span-6 mt-4" >
            <div class="flex items-center h-5">
              <input id="comments"  value="essay" aria-describedby="comments-description" name="submission_requirements[]" type="checkbox" class="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded" />
            </div>
            <div class="ml-3 text-sm">
              <label for="comments" class="font-medium text-gray-700">Assign a scholar</label>
              <p class="font-medium text-gray-500 text-xs">This sponsor will be assigned a scholar, they will primarily donate to the scholars asigned to them</p>
            </div>
          </div>

          <div class="sm:col-span-6 mt-4 ">
            <label for="last_name" class="block text-sm font-medium text-gray-700">
              Scholar
            </label>
            <div class="mt-1 relative rounded-md shadow-sm max-w-xl mx-auto">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <input type="text" name="search" id="search" class="focus:ring-green-500 focus:border-gray-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" placeholder="Search by scholar" />
            </div>
          </div>



        </div>

      </div>
      <!--    buttons-->
      <div class="mt-8 flex justify-end">

          <button type="button" class=" inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
            <span class="md:inline-block">Cancel</span>
          </button>

          <button type="submit" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3 bg-black hover:bg-gray-700 focus:outline-none ">
            <span class="md:inline-block">Add Sponsor</span>
          </button>


      </div>
    </form>
    </div>
  </div>
  </template>

<script>
import {countries} from "@/helpers/countries";
export default {
  name: "AddSponsor",
  setup(){
    return{countries}
  }
}
</script>

<style scoped>

</style>