<template>
  <navbar></navbar>
  <div class="sm:sm:px-[80px] py-[86px] mx-auto max-w-[100rem]">
    <div>
      <div class="max-w-3xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 class="text-2xl font-medium text-black sm:text-4xl">
          <span class="block">Explore exclusive scholarships for you 🤩</span>
        </h2>
        <p class="mt-4 text-base leading-6 text-gray-600">Access thousands of exclusive scholarships across hundreds of categories, all available only on Steward. Browse the categories below to find the perfect scholarship for you.</p>
        <div class="mt-11 relative rounded-md shadow-sm max-w-xl mx-auto">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <input v-model="searchValue" @input="handleSearch" type="text" name="search" id="search" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" placeholder="Search for scholarships" />
        </div>
      </div>
    </div>

    <div v-if="scholarshipList.length > 0" class="grid grid-cols-2 my-6 gap-11">
      <div class="col-span-2 sm:col-span-1 p-3 md:p-0" v-for="scholarship in scholarshipList" :key="scholarship.id">
        <ScholarshipDetailSummary :scholarship="scholarship" />
      </div>
    </div>
    <div v-if="scholarshipList.length>0">
      <Pagination/>
    </div>
    <EmptyState v-if="scholarshipList.length === 0" title="No Scholarship records yet" description="Records will appear here once scholarships are available ">
      <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" fill="none">
        <rect x="4.5" y="4.5" width="48" height="48" rx="24" fill="#F2F4F7"/>
        <path d="M26.55 19.0313L20.53 22.9613C18.6 24.2213 18.6 27.0413 20.53 28.3013L26.55 32.2313C27.63 32.9413 29.41 32.9413 30.49 32.2313L36.48 28.3013C38.4 27.0413 38.4 24.2313 36.48 22.9713L30.49 19.0413C29.41 18.3213 27.63 18.3213 26.55 19.0313Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.1301 29.5781L22.1201 34.2681C22.1201 35.5381 23.1001 36.8981 24.3001 37.2981L27.4901 38.3581C28.0401 38.5381 28.9501 38.5381 29.5101 38.3581L32.7001 37.2981C33.9001 36.8981 34.8801 35.5381 34.8801 34.2681V29.6281" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M37.8999 31.5V25.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="4.5" y="4.5" width="48" height="48" rx="24" stroke="#F9FAFB" stroke-width="8"/>
      </svg>
    </EmptyState>


  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import EmptyState from "@/components/state/EmptyState";
import ScholarshipDetailSummary from "@/components/scholarshipDetailSummary";
import {scholarshipList,getPublicScholarships} from "@/store/scholarship";
import Pagination from "@/components/Pagination";
import {onMounted,ref} from "vue";
export default {
  components:{Navbar,EmptyState,ScholarshipDetailSummary,Pagination},
  name: "PublicScholarshipList",
  setup(){
    const searchValue = ref('');
    onMounted(()=>{
      getPublicScholarships()
    })

   const handleSearch = () => {
     getPublicScholarships(searchValue.value)
    }
    return {scholarshipList,searchValue,handleSearch}
  }
}
</script>

<style scoped>

</style>