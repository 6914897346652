import {host,encodeDataToURL} from "@/helpers/api";
import {ref} from 'vue';
import {useLoading} from 'vue-loading-overlay'
const $loading = useLoading();
const paymentList = ref([]);
const outFlowList = ref([]);
const paymentRequests = ref([]);
import {notify} from "@kyvg/vue3-notification";

const getScholarshipsByPayments =async (id: any,page = null,search = '') => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const url = host+'/scholarships/'+id+'/payments';
    const parameters: { q?: string |null,page?: number | null,  }  = {
        'q':null,
        'page':null
    };

    if (search){
        parameters ['q'] = search;
    }else{
        delete  parameters.q
    }

    if (page){
        parameters ['page'] = page;
    }else{
        delete  parameters.page
    }

    const res =  await fetch(url+'?'+ encodeDataToURL(parameters),{
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token')
        },
    });
    const data =  await res.json();
    if (res.ok){
        paymentList.value = data.data;
        if (page == 1 && data.data.length == 0){
            paymentList.value = [];
        }
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }
    loader.hide();
}

const getRate = async ($base_currency :any, $amount:any , callback:any )=> {
    const res = await fetch(`https://api.exchangerate.host/latest?base=usd&amount=1`);
    const data = await res.json()
    callback(data.rates[$base_currency]);
}

const getPartnerPayments = async () => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });

    const res =  await fetch(`${host}/transactions`,{
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token')
        },
    });
    const data =  await res.json();
    if (res.ok) {
        paymentList.value = data.data;
    }

    loader.hide();
}


const getPaymentRequests = async () => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });

    const res =  await fetch(`${host}/payments`,{
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token')
        },
    });
    const data =  await res.json();
    if (res.ok) {
        paymentRequests.value = data.data;
    }

    loader.hide();
}

const createPaymentRequest  = async (event :any, callback:any) => {
    console.log(event)
    const form = new FormData(event.target);
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const obj: any = Object.fromEntries(form);

    const res =  await fetch(host+'/payments',{
        method:'post',
        body:JSON.stringify(obj),
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token'),
            'Content-Type':'application/json',
        },
    });
    const data =  await res.json();
    if (res.ok){
        callback()
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }
    loader.hide();
}

export {paymentList, getScholarshipsByPayments,
    getRate,getPartnerPayments,outFlowList,paymentRequests,getPaymentRequests,createPaymentRequest}