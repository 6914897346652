<template>
    <button @click="$router.back()" type="button" class="inline-flex  items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="20" viewBox="0 0 20 20" width="20"><g stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path d="m7.97484 4.94531-5.05834 5.05829 5.05834 5.0584"/><path d="m17.0831 10h-14.02499"/></g></svg>
      <span>Back to Overview</span>
    </button>

    <h2 class="text-xl my-10">Scholarship description</h2>
    <form class="mb-20" id="descriptionForm" action="#" @submit.prevent="submitScholarshipDescription($event,moveForward)"  method="POST">

      <input type="hidden" name="state" value="draft">
      <input type="hidden" name="frequency" value="monthly">
      <input type="hidden" name="organisation" value="1">
      <div class="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-3 col-span-6 order-2 md:order-1">

          <div class="sm:col-span-3">
            <label for="first-name" class="block text-sm font-medium text-gray-700">
              Title
            </label>
            <div class="mt-1">
              <input type="text" required name="title" id="title" placeholder="Enter your donation title" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="mt-4">
            <label for="description" class="block text-sm font-medium text-gray-700">
              Description
            </label>
            <div class="mt-1">
              <textarea id="description" required name="description" placeholder="Enter a description..." rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="mt-4">
            <label for="first-name" class="block text-sm font-medium text-gray-700">
              Scholars rewards*
            </label>
            <div class="flex mt-4">
            <div class="relative flex items-start mr-20">
              <div class="flex items-center h-5">
                <input id="monetary" v-model="isMonetary" aria-describedby="comments-description"  type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
              </div>
              <div class="ml-3 text-sm">
                <label for="monetary" class="font-medium text-gray-700">Monetary</label>
              </div>
            </div>
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="other" v-model="isOther" aria-describedby="candidates-description"  type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
              </div>
              <div class="ml-3 text-sm">
                <label for="other" class="font-medium text-gray-700">Others</label>
              </div>
            </div>
            </div>
          </div>

          <div class="mt-4" v-if="isMonetary">
            <label for="first-name" class="block text-sm font-medium text-gray-700">
              Total scholarship amount
            </label>
            <div class="relative mt-1 rounded-md shadow-sm">
              <input type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"  name="amount" required  @keyup="format" id="price" class="block w-full rounded-md border-gray-300  pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Enter amount here" />
              <div class="absolute inset-y-0 right-0 flex items-center">
                <label for="currency" class="sr-only">Currency</label>
                <input type="hidden" name="currency" v-model="scholarship_currency"  >
                <SelectElement  @updated='val => scholarship_currency = val'  :items="['USD','NGN']" :selected="0"/>
              </div>
            </div>
          </div>

          <div class="mt-4" v-if="isOther">
            <label for="other_rewards" class="block text-sm font-medium text-gray-700">
              Other rewards
            </label>
            <div class="mt-1">
              <textarea id="other_rewards" required name="other_rewards" placeholder="Enter a description..." rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:col-span-3 mt-4">
            <label for="application_deadline" class="block text-sm font-medium text-gray-700">
              Application Deadline
            </label>
            <div class="mt-1">
              <input type="datetime-local" :min="today" required name="application_deadline" id="application_deadline"  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="mt-4">
            <label for="winners" class="block text-sm font-medium text-gray-700">
              Winners
            </label>
            <div class="mt-1">
              <input type="number" name="winners" required id="winners"  placeholder="Enter Value" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="mt-4">
            <label for="description" class="block text-sm font-medium text-gray-700">
              Deadline Note
            </label>
            <div class="mt-1">
              <textarea id="deadline_note" required name="deadline_note" placeholder="Enter a deadline note ..." rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
            </div>
          </div>


          <div class="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div class="sm:col-span-6  col-span-6">

              <div class="relative flex" v-if="isMonetary">
                <div class="flex items-center h-5">
                  <input id="accept_contribution" v-model="canAcceptContributions" @click="canAcceptContributions != true"  aria-describedby="comments-description" name="external_sponsorship" value="1" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="accept_contribution" class="font-medium text-gray-700 flex flex-col">
                    <span>Sponsorship</span>
                    <span>Accept external sponsor contribution</span>
                  </label>
                </div>
              </div>

              <div v-if="canAcceptContributions && isMonetary" class="mt-4">
                <label for="minimum_amount" class="block text-sm font-medium text-gray-700">
                  Minimum amount
                </label>
                <div class="relative mt-1 rounded-md shadow-sm">
                  <input type="text" @keyup="format" name="minimum_amount"  id="minimum_amount" class="block w-full rounded-md border-gray-300  pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Enter amount here" />
                  <div class="absolute inset-y-0 right-0 flex items-center">
                    <label for="currency" class="sr-only">Currency</label>
                    <span class="h-full flex items-center bg-transparent py-0 pl-2 pr-2 text-gray-500 sm:text-sm">
                      {{scholarship_currency}}
                    </span>
                  </div>
                </div>
              </div>

                 <div v-if="canAcceptContributions && isMonetary"  class="mt-4">
                        <label for="accepted_currencies" class="block text-sm font-medium text-gray-700">
                          Only accept these currencies
                        </label>
                        <div class="mt-1">
                          <input type="hidden" name="accepted_currency" id="accepted_currencies" v-model="accepted_currencies"  >
                          <SelectElement  :border="true"  @updated='val => accepted_currencies = val'  :items="['USD','NGN']" :selected="0" />
                        </div>
                      </div>

<!--              <div v-if="canAcceptContributions && isMonetary"  class="mt-4">-->
<!--                <label for="fees_paid_by" class="block text-sm font-medium text-gray-700">-->
<!--                  Who takes the transaction charge-->
<!--                </label>-->
<!--                <div class="mt-1">-->
  <!--                  <input type="hidden" name="fees_paid_by" v-model="fees_paid_by">-->
<!--                  <SelectElement  :border="true"  @updated='val => fees_paid_by = val'  :items="['organisation','donor']" :selected="0" />-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>

        </div>
        <div class="sm:col-span-3 col-span-6 order-1 md:order-2">
          <ImageUpload />
        </div>

      </div>
      <!--    buttons-->
      <div class="mt-8 flex justify-between">

        <router-link :to="{name:'Overview'}" type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
          <span class="md:inline-block">Cancel</span>
        </router-link>
        <div>
          <button type="button" class="hidden inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
            <span class="md:inline-block">Save to draft</span>
          </button>

          <button type="submit" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3 bg-black hover:bg-gray-700 focus:outline-none ">
            <span class="md:inline-block">Continue</span>
          </button>

        </div>

      </div>
    </form>
</template>

<script>
import {submitScholarshipDescription} from "@/store/scholarship";
import {onMounted, watch,ref} from "vue";
import router from "@/router";
import ImageUpload from "@/components/elements/ImageUpload";
import {format} from "@/helpers/input";
import moment from 'moment';
import SelectElement from "@/components/elements/SelectElement";


export default {
  components:{ ImageUpload,SelectElement},
  setup(){
    const today = ref();
    const isMonetary = ref(false);
    const isOther = ref(false);
    const canAcceptContributions = ref(0);
    const scholarship_currency = ref();
    const accepted_currencies = ref();
    const fees_paid_by = ref();
    const moveForward = () => {
      router.push({name:'create-scholarship-eligibility'})
    }
    onMounted(()=>{
      today.value =  moment().format('YYYY-MM-DDTHH:mm')
      document.getElementById('descriptionForm').reset();
    })

    return {submitScholarshipDescription,
      moveForward,format,today,isMonetary,isOther
      ,canAcceptContributions
      ,scholarship_currency,accepted_currencies,fees_paid_by}
  }
}
</script>

<style scoped>

</style>