import {host} from "@/helpers/api";
import {notify} from "@kyvg/vue3-notification";
import {ref} from 'vue';
const activePartner  = ref();
import {useLoading} from 'vue-loading-overlay'
const $loading = useLoading();
const partnerSponsors = ref([]);
const partnerScholars = ref([]);
const overview =  ref({
    "available_funds": "$0",
    "scholarships_given": "0",
    "scholarship_applicants": "0",
    "total_funds_donated": "$0",
})

const getPartnerBySlug = async (slug: any) => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const res =  await fetch(host+'/partner/'+slug,{
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token')
        },
    });
    const data =  await res.json();
    if (res.ok){
        activePartner.value = data;
    }
    loader.hide();
}



const getOverview = async () => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const res =  await fetch(host+'/overview',{
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token')
        },
    });
    const data =  await res.json();
    if (res.ok){
        overview.value = data;
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }

    loader.hide();
}


const getPartnerSponsors = async (id: any) => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const res =  await fetch(host+'/partner/'+id+'/sponsors',{
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token')
        },
    });
    const data =  await res.json();
    if (res.ok){
        partnerSponsors.value = data.data;
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }

    loader.hide();
}


const getPartnerScholars = async (slug: any) => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const res =  await fetch(host+'/partner/'+slug+'/scholars',{
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token')
        },
    });
    const data =  await res.json();
    if (res.ok){
        overview.value = data;
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }

    loader.hide();
}



export  {getPartnerBySlug,activePartner,overview,getOverview,
       partnerSponsors,partnerScholars,
    getPartnerSponsors,getPartnerScholars}