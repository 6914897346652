import {host,encodeDataToURL} from "@/helpers/api";
import {user} from "@/store/auth";
import {saveFile} from "@/helpers/file";
import {ref} from 'vue';
import {useLoading} from 'vue-loading-overlay'
import {notify} from "@kyvg/vue3-notification";
const $loading = useLoading();
const activeScholarship  = ref();
const scholarshipList  = ref([]);

const submitScholarshipDescription  = async (event :any, callback:any) => {
  const form = new FormData(event.target);
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });



    const obj: any = Object.fromEntries(form);
    obj.amount = parseInt(obj.amount?.replace(',',''));
    obj.state =  'published';
    obj.minimum_amount =parseInt(obj.minimum_amount?.replace(',','')) || 10;
    if (event.target.querySelector('#accept_contribution').value){
        obj.external_sponsorship = true
        obj.accepted_currency = [event.target.querySelector('#accepted_currencies')?.value || 'USD'];
    }



    saveFile(event.target.querySelector("[name='image']")).then(async (response)=>{
       response.text().then(async (image) => {
           obj.image = image;
           const res =  await fetch(host+'/scholarship',{
               method:'post',
               body:JSON.stringify(obj),
               headers:{
                   'Accept':'application/json, text/plain',
                   'Content-Type':'application/json',
                   'Authorization' : 'Bearer '+localStorage.getItem('token')
               },
           });
           const data =  await res.json();
           if (res.ok){
               activeScholarship.value = data.data;
               callback()
           }else{
               // notify({
               //     text:data.message || 'We have experienced and error. Please try again',
               //     type:'error'
               // })

           }
           loader.hide();
       }).catch(() => {
           loader.hide();
       });

    })


}

const submitScholarshipEligibility = async (event :any, callback:any,id: string ) => {
    const form = new FormData(event.target);
    form.append('scholarship_id', id);
    form.append('submission_requirements','essay');
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });

    const obj:any = Object.fromEntries(form);
    const el = event.target;
   const links  =   el.querySelectorAll('.links');
   const files  =   el.querySelectorAll('.files');
    obj.link_requirements = [];
    obj.file_requirements = [];

   for ( const element of  links){
       obj.link_requirements.push({ name : element.value });
   }

    for ( const element of  files){
        obj.file_requirements.push({ name : element.value });
    }

    const res =  await fetch(host+'/scholarship/'+id+'/eligibility',{
        method:'post',
        body:JSON.stringify(obj),
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token'),
            'Content-Type':'application/json',
        },
    });
    const data =  await res.json();
    if (res.ok){
        callback()
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }
    loader.hide();
}

const submitScholarshipSponsorship = async (event :any, callback:any,id: string ) => {
    const form = new FormData(event.target);
    form.append('scholarship_id', id);
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const res =  await fetch(host+'/scholarship/'+id+'/sponsor',{
        method:'post',
        body:JSON.stringify(Object.fromEntries(form)),
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token'),
            'Content-Type':'application/json',
        },
    });
    const data =  await res.json();
    if (res.ok){
        activeScholarship.value = data.data;
        callback()
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }
    loader.hide();
}


const getScholarshipById = async (id: any ) => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const res =  await fetch(host+'/public/scholarship/'+id,{
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token'),
            'Content-Type':'application/json',
        },
    });
    const data =  await res.json();
    if (res.ok){
        activeScholarship.value = data.data;
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }
    loader.hide();
}


const getScholarships = async ($search :any,$frequency :any,$page :any) => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const url = host+'/scholarship';
    const parameters: { q?: string |null,f?: string |null,page?: string |null, u?:any | null }  = {
        'q':null,
        'f':null,
        'page':null,
        'u':user.value.id
    };

    if ($search){
        parameters ['q'] = $search;
    }else{
        delete  parameters.q
    }



    if ($frequency){
        if ($frequency.toLowerCase() == 'all'){
            delete  parameters.f
        }else{
            parameters ['f'] = $frequency;
        }
    } else{
        delete  parameters.f
    }

    if ($page){
        parameters ['page'] = $page;
    }else{
        delete  parameters.page
    }



    const res =  await fetch(url+'?'+encodeDataToURL(parameters),{
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token')
        },
    });
    const data =  await res.json();
    if (res.ok){

        scholarshipList.value = data.data;
        if ($page == 1 && data.data.length == 0){
            scholarshipList.value = [];
        }
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }

    loader.hide();


}


const makePayment = async (event :any,id:any) => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const form = new FormData(event.target);
    const res =  await fetch(host+'/scholarship/'+id+'/pay',{
        method:'post',
        body:form,
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token'),
            'Content-Type':'application/json',
        },
    });
    const data =  await res.json();
    if (res.ok){
        window.location.href=data.data.link
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }
    loader.hide()
}
const getScholarshipsByPartner =async (id: any,page = 1) => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const res =  await fetch(host+'/scholarship/partner/'+id,{
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token'),
            'Content-Type':'application/json',
        },
    });
    const data =  await res.json();
    if (res.ok){
        scholarshipList.value = data.data;
        if (page == 1 && data.data.length == 0){
            scholarshipList.value = [];
        }
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }

    loader.hide();

}


const getPublicScholarships = async ($search :any,$page :any) => {
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const url = host+'/public/scholarship';
    const parameters: { q?: string |null,page?: string |null,  }  = {
        'q':null,
        'page':null
    };

    if ($search){
        parameters ['q'] = $search;
    }else{
        delete  parameters.q
    }


    if ($page){
        parameters ['page'] = $page;
    }else{
        delete  parameters.page
    }



    const res =  await fetch(url+'?'+encodeDataToURL(parameters),{
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token'),
            'Content-Type':'application/json',
        },
    });
    const data =  await res.json();
    if (res.ok){

        scholarshipList.value = data.data;
        if ($page == 1 && data.data.length == 0){
            scholarshipList.value = [];
        }
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }
loader.hide();

}


const editScholarshipDescription  = async (event :any, id:any, callback:any) => {
    const form = new FormData(event.target);
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const res =  await fetch(host+'/scholarship/'+id,{
        method:'post',
        body:form,
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token'),
            'Content-Type':'application/json',
        },
    });
    const data =  await res.json();
    if (res.ok){
        activeScholarship.value = data;
        callback()
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }
    loader.hide();
}

const deleteScholarship = async (id:any,callback:any) => {
    const form = new FormData();
    form.append('_method', 'delete');
    const loader = $loading.show({
        color: " #5271ff",
        opacity: .7,
    });
    const res =  await fetch(host+'/scholarship/'+id,{
        method:'post',
        body:form,
        headers:{
            'Accept':'application/json, text/plain',
            'Authorization' : 'Bearer '+localStorage.getItem('token'),
            'Content-Type':'application/json',
        },
    });
    const data =  await res.json();
    if (res.ok){
        notify({
            text:data.message || 'Scholarship has been removed',
            type:'success'
        })
        callback();
    }else{
        // notify({
        //     text:data.message || 'We have experienced and error. Please try again',
        //     type:'error'
        // })

    }
    loader.hide();
}


export  {submitScholarshipDescription,activeScholarship,
    submitScholarshipEligibility,
    submitScholarshipSponsorship,
    getScholarshipById,
    getScholarships,
    scholarshipList,
    makePayment,
    getScholarshipsByPartner,
    getPublicScholarships,
    editScholarshipDescription,
    deleteScholarship
}