<template>
  <div class="text-center mt-20">
    <div class="flex justify-center">
      <slot>
        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="57" viewBox="0 0 56 57" fill="none">
          <rect x="4" y="4.5" width="48" height="48" rx="24" fill="#F2F4F7"/>
          <path d="M25 38.5H23C19 38.5 18 37.5 18 33.5V23.5C18 19.5 19 18.5 23 18.5H24.5C26 18.5 26.33 18.94 26.9 19.7L28.4 21.7C28.78 22.2 29 22.5 30 22.5H33C37 22.5 38 23.5 38 27.5V29.5" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M29.7601 34.8165C27.4101 34.9865 27.4101 38.3865 29.7601 38.5565H35.3201C35.9901 38.5565 36.6501 38.3065 37.1401 37.8565C38.7901 36.4165 37.91 33.5365 35.74 33.2665C34.96 28.5765 28.1801 30.3565 29.7801 34.8265" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <rect x="4" y="4.5" width="48" height="48" rx="24" stroke="#F9FAFB" stroke-width="8"/>
        </svg>

      </slot>

    </div>
    <h3 class="mt-2 text-base font-medium text-gray-900">{{title}}</h3>
    <p class="mt-1 text-sm text-gray-500">
       {{description}}
    </p>
    <div class="mt-6" v-if="button">
      <button @click="$emit('action')" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-0 ">
        {{button}}
      </button>
    </div>
  </div>
</template>s

<script>
export default {
  name: "EmptyState",
  props:['title','description','button'],
  emits:['action']
}
</script>

<style scoped>

</style>