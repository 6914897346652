<template>
  <div v-if="hasPaginate" class="flex justify-between mt-[14px]">
    <button type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path d="M12.8333 6.9974H1.16663M1.16663 6.9974L6.99996 12.8307M1.16663 6.9974L6.99996 1.16406" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span class="ml-[13px] hidden md:inline-block">Previous</span>
    </button>
    <ul class="flex">
      <li><button class="h-[40px] w-[40px] bg-gray-200 hover:bg-gray-100">1</button></li>
      <li><button class="h-[40px] w-[40px] hover:bg-gray-100">2</button></li>
      <li><button class="h-[40px] w-[40px] hover:bg-gray-100">3</button></li>
      <li><button class="h-[40px] w-[40px] hover:bg-gray-100">...</button></li>
      <li><button class="h-[40px] w-[40px] hover:bg-gray-100">8</button></li>
      <li><button class="h-[40px] w-[40px] hover:bg-gray-100">9</button></li>
      <li><button class="h-[40px] w-[40px] hover:bg-gray-100 ">10</button></li>

    </ul>

    <button type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path d="M1.16669 6.9974H12.8334M12.8334 6.9974L7.00002 1.16406M12.8334 6.9974L7.00002 12.8307" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span class="ml-[13px] hidden md:inline-block">Next</span>
    </button>
  </div>
</template>

<script>
import {hasPaginate} from "@/helpers/pagination";
export default {
  name: "PaginationLinks",
  setup(){
    return {hasPaginate}
  }
}
</script>

<style scoped>

</style>