
import { defineComponent } from "vue";
import { ref } from "vue";
import Navbar from "@/components/Navbar.vue";
export default defineComponent({
  name: "HomeView",
  components: { Navbar },
  setup() {
    const active = ref("sponsor");
    return {
      active,
    };
  },
});
