<template>
  <button @click="$router.back()" type="button" class="inline-flex  items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="20" viewBox="0 0 20 20" width="20"><g stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path d="m7.97484 4.94531-5.05834 5.05829 5.05834 5.0584"/><path d="m17.0831 10h-14.02499"/></g></svg>
    <span>Back to Eligibility</span>
  </button>

  <h2 class="text-xl my-10">Scholarship</h2>
  <form id="sponsorshipForm"  @submit.prevent="submitScholarshipSponsorship($event,moveForward,activeScholarship.id)" action="#" method="POST">

    <div class="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div class="sm:col-span-3  col-span-6">

        <div class="relative flex items-start">
          <div class="flex items-center h-5">
            <input id="accept_contribution" v-model="canAcceptContributions" @click="canAcceptContributions != true"  aria-describedby="comments-description" name="accept_contribution" value="1" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
          </div>
          <div class="ml-3 text-sm">
            <label for="accept_contribution" class="font-medium text-gray-700">Accept external sponsor contribution</label>
          </div>
        </div>

        <div v-if="canAcceptContributions" class="mt-4">
          <label for="minimum_amount" class="block text-sm font-medium text-gray-700">
            Minimum amount
          </label>
          <div class="relative mt-1 rounded-md shadow-sm">
            <input type="text" @keyup="format" name="minimum_amount"  id="minimum_amount" class="block w-full rounded-md border-gray-300  pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Enter amount here" />
            <div class="absolute inset-y-0 right-0 flex items-center">
              <label for="currency" class="sr-only">Currency</label>
              <select id="currency" name="currency" class="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                <option>USD</option>
                <option>UGX</option>
                <option>NGN</option>
              </select>
            </div>
          </div>
        </div>

<!--        <div v-if="canAcceptContributions"  class="mt-4">-->
<!--          <label for="frequency" class="block text-sm font-medium text-gray-700">-->
<!--            Only accept these currencies-->
<!--          </label>-->
<!--          <div class="mt-1">-->
<!--            <select id="frequency"  multiple  name="frequency"  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">-->
<!--              <option value="" selected>Select an option</option>-->
<!--              <option>USD</option>-->
<!--              <option>UGX</option>-->
<!--              <option>NGX</option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->

        <div v-if="canAcceptContributions"  class="mt-4">
          <label for="fees_paid_by" class="block text-sm font-medium text-gray-700">
            Who takes the transaction charge
          </label>
          <div class="mt-1">
            <select id="fees_paid_by"  name="fees_paid_by"  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
              <option value="" selected>Select an option</option>
              <option>Partner</option>
              <option>donor</option>
            </select>
          </div>
        </div>




      </div>
    </div>
    <!--    buttons-->
    <div   class="mt-8 flex justify-between">

      <button type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
        <span class="md:inline-block">Cancel</span>
      </button>
      <div>
<!--        <button type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">-->
<!--          <span class="md:inline-block">Save to draft</span>-->
<!--        </button>-->

        <button type="submit" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3 bg-black hover:bg-gray-700 focus:outline-none ">
          <span class="md:inline-block">Continue</span>
        </button>

      </div>

    </div>
  </form>
</template>

<script>
import {onMounted, ref} from "vue";
import {submitScholarshipSponsorship,activeScholarship} from "@/store/scholarship";
import {format} from "@/helpers/input";
import router from "@/router";
export default {
  name: "CreateScholarshipSponsorship",
  setup(){
    const canAcceptContributions = ref(0);
    const moveForward = () => {
      router.push({name:'create-scholarship-successful'})
    }
    onMounted(()=>{
      document.getElementById('sponsorshipForm').reset();

    })
    return {canAcceptContributions,submitScholarshipSponsorship,activeScholarship,moveForward,format}
  }
}
</script>

<style scoped>

</style>