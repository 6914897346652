<template>
  <div>
  <TransitionRoot as="template" :show="show">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="$emit('close')">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div class="mt-3  sm:mt-5">
                <button @click="$emit('close')" class="absolute right-[10px] top-[13px]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <circle cx="16" cy="16" r="16" fill="#D0D5DD"/>
                    <path d="M11.0168 11.0156L21.1992 21.198" stroke="#000409" stroke-width="1.16667" stroke-linecap="round"/>
                    <path d="M11.0168 21.2031L21.1992 11.0208" stroke="#000409" stroke-width="1.16667" stroke-linecap="round"/>
                  </svg>
                </button>
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  Withdraw funds
                </DialogTitle>
                <div class="mt-2">

                  <form v-if="!verify" id="descriptionForm" action="#"  method="POST">

                    <div class="mt-6  gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div class="sm:col-span-3 col-span-6 order-2 md:order-1">

                        <div class="relative mt-1 rounded-md shadow-sm">

                          <input type="text" name="price" id="price" class="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Enter amount" />
                          <div class="absolute inset-y-0 right-0 flex items-center">
                            <label for="currency" class="sr-only"> Amount to withdraw</label>
                            <span class="h-full rounded-md border-transparent flex items-center bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">USD</span>
                          </div>
                        </div>
                        <div class="text-sm text-gray-500 mt-4">
                          <p>Transaction fee: <span class="font-medium">$0</span></p>
                          <p class="mt-3">Your bank account will receive: <span class="font-medium">NGN 0</span></p>

                        </div>

                        <div class="mt-4">
                          <label for="frequency" class="block text-sm font-medium text-gray-700">
                            Select bank
                          </label>
                          <div class="mt-1">
                            <select id="frequency" required name="frequency"  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                              <option value="" selected>Select bank</option>
                            </select>
                          </div>
                        </div>
                        <div class="sm:col-span-3 mt-4">
                          <label for="name" class="block text-sm font-medium text-gray-700">
                            Account holder name
                          </label>
                          <div class="mt-1">
                            <input type="text" required name="name" id="name" placeholder="Enter name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                          </div>
                        </div>


                        <div class="mt-4">
                          <label for="description" class="block text-sm font-medium text-gray-700">
                            Narration
                          </label>
                          <div class="mt-1">
                            <textarea id="description" required name="description" placeholder="Enter a description..." rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
                          </div>
                        </div>
                      </div>

                    </div>
                    <!--    buttons-->
                    <div class="mt-8 ">
                        <button @click="verifyAccount" type="submit" class="flex w-full justify-center items-center px-3 py-4 border border-gray-300 shadow-sm text-base leading-4 font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none ">
                          <span class="md:inline-block">Continue</span>
                        </button>
                    </div>
                  </form>

                  <form v-else id="descriptionForm" action="#"  method="POST">

                     <h3>Withdrawal confirmation</h3>
                    <div class="text-sm text-gray-500 mt-4">
                    <p>Please enter the  OTP sent to fa *********@gmail.com to approve this transaction.  </p>
                    </div>
                      <div class="sm:col-span-3 col-span-6 order-2 md:order-1">

                        <div class="sm:col-span-3 mt-4">
                          <label for="first-name" class="block text-sm font-medium text-gray-700">
                            Email  OTP
                          </label>
                          <div class="mt-1">
                            <input type="text" required name="otp" id="otp" placeholder="Enter your email OTP" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                          </div>
                        </div>


                    </div>
                    <!--    buttons-->
                    <div class="mt-8 ">
                      <button type="button" class="flex w-full justify-center items-center px-3 py-4 border border-gray-300 shadow-sm text-base leading-4 font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none ">
                        <span class="md:inline-block">Continue</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  </div>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  props:['show','verify'],
  setup(props) {
    const verify = ref(!props.show);

    const verifyAccount = () => {
         verify.value = true;
    }


    return {
      open,
      verifyAccount
    }
  },
}
</script>