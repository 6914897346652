<template>
<div class="bg-white p-4">
  <router-view></router-view>
</div>
</template>

<script>
export default {
  name: "CreateScholarship"
}
</script>

<style scoped>

</style>