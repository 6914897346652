<template>
  <div class="mt-6">
    <div class="py-2 align-middle inline-block min-w-full">
      <div v-if="paymentRequests.length > 0" class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200 whitespace-nowrap">
          <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalise tracking-wider">
              Sponsors
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalise tracking-wider">
              Amount  requested
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalise tracking-wider">
              Status
            </th>

            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalise tracking-wider">
              Date
            </th>
          </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="payment in paymentRequests" :key="payment.id">
            <td class="px-6 py-4 whitespace-nowrap ">
              <div class="text-sm text-gray-500">
                 <UserProfileSummary/>
              </div>
            </td>
            <td>
              <div class="text-sm text-gray-500"> {{payment.amount}}</div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="text-sm text-gray-500">{{payment.status}}</div>
            </td>

            <td>
              <div class="text-sm text-gray-500">{{payment.date}}</div>

            </td>

          </tr>

          </tbody>
        </table>
      </div>
      <EmptyState  v-else   title="No  Payment Requests  yet" description="your records will appear here once you make any payment requests" />
    </div>
  </div>
</template>

<script>
import EmptyState from "@/components/state/EmptyState.vue";
import {getPaymentRequests, paymentRequests} from "@/store/payments";
import {onMounted} from "vue";
import UserProfileSummary from "@/components/elements/UserProfileSummary.vue";
export default {
  name: "PaymentRequests",
  components: {EmptyState,UserProfileSummary},
  setup(){
    onMounted(()=>{
      getPaymentRequests();
    })
    return {paymentRequests}
  }
}
</script>

<style scoped>

</style>