import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full overflow-x-auto" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(`bg-white border border-gray-300 divide-x divide-gray-300 rounded-lg overflow-x-auto flex flex-nowrap ${
        !_ctx.xlFillWidth ? 'xl:inline-block' : ''
      }`)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
        return (_openBlock(), _createElementBlock("button", {
          type: "button",
          class: _normalizeClass(`text-gray-700 font-medium text-sm w-full whitespace-nowrap px-2 ${
          !_ctx.xlFillWidth && 'xl:w-auto'
        } h-full py-[10px] xl:p-[10px] first-letter:uppercase ${
          tab === _ctx.currentTab && 'bg-gray-100'
        }`),
          key: i,
          onClick: ($event: any) => (_ctx.handleClick(tab))
        }, _toDisplayString(tab), 11, _hoisted_2))
      }), 128))
    ], 2)
  ]))
}