export const longFormDate = (date: Date | string) => {
    return new Date(date).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        hour12: true,
        year: 'numeric',
        minute: '2-digit',
        hour: 'numeric'
    });
}

export const shortFormDate = (date: Date | string) => {
    return new Date(date).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        hour12: true,
        year: 'numeric',
    });
}