import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-[5px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(`bg-gray-500 w-[18px] h-0.5 transition duration-700 ${_ctx.active && 'rotate-45 transform translate-y-[7px]'}`)
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(`bg-gray-500 w-[18px] h-0.5 transition duration-700 ${_ctx.active && 'opacity-0'}`)
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(`bg-gray-500 w-[18px] h-0.5 transition duration-700 ${_ctx.active && '-rotate-45 transform translate-y-[-7px]'}`)
    }, null, 2)
  ]))
}