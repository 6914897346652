import {notify} from "@kyvg/vue3-notification";
import {copyTextToClipboard} from "@/helpers/input";


export async function share(text:any,url:string) {
    try {
        await navigator.share({
            text: text,
            url: url
        })
    } catch (error) {
        copyTextToClipboard(url);
    }
}