
import { ref, computed, Ref, watch } from "vue";
import OnboardingLayout from "@/components/onboarding/onboardingLayout.vue";
import { countriesAPI, registerAPI } from "@/api/auth";
import Country from "@/types";
import router from "@/router";
import { notify } from "@kyvg/vue3-notification";
import {user as loggedInUser} from "@/store/auth";
import SelectMenu from '@/components/SelectMenu.vue';

export default {
  components: { OnboardingLayout,SelectMenu },
  name: "RegisterView",
  async setup() {
    const all_countries = ref([]) as Ref<Country[]>;
    const user_type = ref("");
    const country = ref("");
    const partner_name = ref("");
    const first_name = ref("");
    const last_name = ref("");
    const email = ref("");
    const organisation_email = ref("");
    const password = ref("");
    const password_confirmation = ref("");
    const password_confirmation_error_message = ref("");

    const first_screen = ref(true);
    const uppercase = ref(false);
    const number = ref(false);
    const special_character = ref(false);
    const length = ref(false);
    const accepted_terms = ref(false);

    const enable_first_screen_button = computed(() => {
      if (user_type.value === "partner") {
        return user_type.value && partner_name.value && country.value;
      }
      return user_type.value && country.value;
    });
    const enable_signup_button = computed(() => {
      return (
        first_name.value &&
        last_name.value &&
        email.value &&
        password.value &&
        password_confirmation.value &&
        accepted_terms.value &&
        password_check.value
      );
    });
    const password_check = computed(() => {
      return (
        uppercase.value &&
        number.value &&
        special_character.value &&
        length.value
      );
    });

    watch(password, (currentValue) => {
      if (password_confirmation.value.length) {
        password_confirmation_error_message.value =
          currentValue !== password_confirmation.value
            ? "Passwords Don't Match"
            : "";
      }
    });
    watch(password_confirmation, (currentValue) => {
      if (password_confirmation.value.length) {
        password_confirmation_error_message.value =
          currentValue !== password.value ? "Passwords Don't Match" : "";
      }
    });
    try {
      const countries = await countriesAPI();
      all_countries.value = countries.data.data;
    } catch (e) {
      console.log(e);
    }

    const signup = async () => {
      if (
        password.value != password_confirmation.value ||
        !accepted_terms.value
      )
        return false;
      const user = {
        user_type: user_type.value,
        country: country.value,
        business_name: partner_name.value,
        first_name: first_name.value,
        last_name: last_name.value,
        email: email.value,
        password: password.value,
        // password_confirmation: password_confirmation.value,
        organisation_email:user_type.value === 'partner' ? email.value : ''
      };
      try {
        const { data } = await registerAPI(user);
        if (!data.success) {
          throw new Error();
        }
        sessionStorage.setItem("email", email.value);
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("user", JSON.stringify(data.data.user));
        loggedInUser.value = data.data.user;
        router.replace("/verify-email");
      } catch (err: any) {
        notify({
          title: err.response.data.message,
          type:'error'
        });
      }
    };
    const switch_screen = () => {
      first_screen.value = !first_screen.value;
    };
    const checkLetters = () => {
      const numbers = /[0-9]/;
      const uppercaseLetters = /[A-Z]/;
      const symbols = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      number.value = password.value.match(numbers) ? true : false;
      uppercase.value = password.value.match(uppercaseLetters) ? true : false;
      special_character.value = password.value.match(symbols) ? true : false;
      length.value = password.value.length >= 8 ? true : false;
    };

    const updateSelected = (val: any) => {
      if (val === 'organisation'){
        val = 'partner';
      }
      user_type.value = val;
    }

    return {
      all_countries,
      user_type,
      country,
      partner_name,
      first_name,
      last_name,
      email,
      password,
      password_confirmation,
      password_confirmation_error_message,
      enable_first_screen_button,
      enable_signup_button,
      first_screen,
      uppercase,
      number,
      special_character,
      length,
      accepted_terms,
      password_check,
      organisation_email,
      updateSelected,
      signup,
      switch_screen,
      checkLetters,
    };
  },
};
