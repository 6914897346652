import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteComponent,
  RouteRecordRedirectOption,
} from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import DashboardView from "@/views/DashboardView.vue";
import ApplicationSucceful from "@/views/ApplicationSucceful.vue";
import PaymentSuccessful from "@/views/PaymentSuccessful.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import EmailVerificationView from "@/views/EmailVerificationView.vue";
import OverviewPage from "@/views/overview/index.vue";
import Scholarships from "@/views/scholarships/index.vue";
import QuickActionOverview from "@/views/overview/QuickActionOverview.vue";
import ListScholarships from "@/views/scholarships/ListScholarships.vue";
import ShowScholarship from "@/views/scholarships/ShowScholarship.vue";
import ScholarshipDetails from "@/views/scholarships/ScholarshipDetails.vue";
import ScholarshipSponsorship from "@/views/scholarships/ScholarshipSponsorship.vue";
import ScholarshipApplicants from "@/views/scholarships/ScholarshipApplicants.vue";
import CreateScholarship from "@/views/scholarships/CreateScholarship.vue";
import CreateScholarshipDescription from "@/views/scholarships/CreateScholarshipDescription.vue";
import CreateScholarshipEligibility from "@/views/scholarships/CreateScholarshipEligibility.vue";
import CreateScholarshipSponsorship from "@/views/scholarships/CreateScholarshipSponsorship.vue";
import CreateScholarshipSuccessful from "@/views/scholarships/CreateScholarshipSuccessful.vue";

import EditScholarshipDescription from "@/views/scholarships/EditScholarshipDescription.vue";
import EditScholarshipSponsorship from "@/views/scholarships/EditScholarshipSponsorship.vue";
import EditScholarshipEligibility from "@/views/scholarships/EditScholarshipEligibility.vue";

import SponsorScholarship from "@/views/scholarships/SponsorScholarship.vue";
import ApplyToScholarship from "@/views/scholarships/ApplyToScholarship.vue";
import PublicScholarshipList from "@/views/scholarships/PublicScholarshipList.vue";
import PublicShowScholarship from "@/views/scholarships/PublicShowScholarship.vue";
import ScholarshipApplicantsDetails from "@/views/scholarships/ScholarshipApplicantsDetails.vue";
import Settings from "@/views/settings/Index.vue";
import PersonalSettings from "@/views/settings/PersonalSettings.vue";
import OrganisationSettings from "@/views/settings/OrganisationSettings.vue";
import PasswordSettings from "@/views/settings/PasswordSettings.vue";
import TeamSettings from "@/views/settings/TeamSettings.vue";
import BankSettings from "@/views/settings/BankSettings.vue";
import Partner from "@/views/partners/Index.vue";
import Scholars from "@/views/Scholars/Index.vue";
import ScholarList from "@/views/Scholars/ScholarList.vue";
import ScholarProfile from "@/views/Scholars/ScholarProfile.vue";
import Communications from "@/views/communications/Index.vue";
import Sponsors from "@/views/sponsors/Index.vue";
import SponsorProfile from "@/views/sponsors/SponsorProfile.vue";
import SponsorList from "@/views/sponsors/SponsorList.vue";
import Payments from "@/views/payments/Index.vue";
import InflowPayments from "@/views/payments/InflowPayments.vue";
import OutflowPayments from "@/views/payments/OutflowPayments.vue";
import MyScholarshipApplication from "@/views/scholarships/MyScholarshipApplication.vue";
import ViewReceipt from "@/views/payments/ViewReceipt.vue";
import ScholarshipSponsorDetails from "@/views/scholarships/ScholarshipSponsorDetails.vue";
import SponsorScholar from "@/views/payments/SponsorScholar.vue";
import PaymentRequests from "@/views/payments/PaymentRequests.vue";
import AddSponsor from "@/views/sponsors/AddSponsor.vue";



export interface NavRoute {
  path: string;
  name: string;
  component: RouteComponent | null | undefined;
  icon?: string;
  children?: Array<NavRoute>;
  redirect?: RouteRecordRedirectOption;
  activeClass?: string;
  meta?: Record<string, any>;
}

export const navRoutes: Array<NavRoute> = [
  {
    path: "overview",
    name: "Overview",
    component: OverviewPage,
    icon: "home.svg",
    activeClass: "!text-[#1D2939]",
    meta: {
      heading: "Overview",
    },
    redirect: { name: "Quick Action Overview" },
    children: [
      {
        path: "",
        name: "Quick Action Overview",
        component: QuickActionOverview,
      },
    ],
  },
  {
    path: "scholarships",
    name: "Scholarships",
    component: Scholarships,
    icon: "teacher.svg",
    activeClass: "!text-[#1D2939]",
    meta: {
      heading: "Scholarships",
    },
    redirect: { name: "List Scholarships" },
    children: [
      {
        path: "",
        name: "List Scholarships",
        component: ListScholarships,
      },
      {
        path: "create",
        name: "create-scholarships",
        component: CreateScholarship,
        children:[
          {
            path: "",
            name: "create-scholarship-description",
            component: CreateScholarshipDescription,
          },
          {
            path: "eligibility",
            name: "create-scholarship-eligibility",
            component: CreateScholarshipEligibility,
          },
          {
            path: "sponsorship",
            name: "create-scholarship-sponsorship",
            component: CreateScholarshipSponsorship,
          },
          {
            path: "successful",
            name: "create-scholarship-successful",
            component: CreateScholarshipSuccessful,
          },
        ]
      },
      {
        path: ":id/edit",
        name: "edit-scholarships",
        component: CreateScholarship,
        redirect:{name:'edit-scholarship-description'},
        children:[
          {
            path: "",
            name: "edit-scholarship-description",
            component: EditScholarshipDescription,
          },
          {
            path: "eligibility",
            name: "edit-scholarship-eligibility",
            component: EditScholarshipEligibility,
          },
          {
            path: "sponsorship",
            name: "edit-scholarship-sponsorship",
            component: EditScholarshipSponsorship,
          },
          {
            path: "successful",
            name: "edit-scholarship-successful",
            component: CreateScholarshipSuccessful,
          },
        ]
      },
      {
        path: ":id",
        name: "show-scholarship",
        component: ShowScholarship,
        children: [
          {
            path: "",
            name: "scholarshipDetails",
            component: ScholarshipDetails,
          },
          {
            path: "sponsorship",
            name: "scholarshipSponsorship",
            component: ScholarshipSponsorship,
          },
          {
            path: "applicants",
            name: "scholarshipApplicants",
            component: ScholarshipApplicants,
          },
          {
            path: "my-application",
            name: "MyScholarshipSponsorship",
            component: MyScholarshipApplication,
          },
        ],
      },
      {
        path: ":id/application/:application",
        name: "ScholarshipApplicantsDetails",
        component: ScholarshipApplicantsDetails,
      },
      {
        path: ":id/sponsors/:sponsor",
        name: "ScholarshipSponsorDetails",
        component: ScholarshipSponsorDetails,
      },
    ],
  },
  {
    path: "receipt/:id",
    name: "show-receipt",
    component: ViewReceipt,
  },

  {
    meta: {
      heading: "Settings",
    },
    path: "settings",
    name: "settings",
    component: Settings,
    redirect: { name: "PersonalSettings" },
    children:[
      {
        path: "",
        name: "PersonalSettings",
        component: PersonalSettings,
      },
      {
        path: "organisation",
        name: "OrganisationSettings",
        component: OrganisationSettings,
      },
      {
        path: "password",
        name: "PasswordSettings",
        component: PasswordSettings,
      },
      {
        path: "team",
        name: "TeamSettings",
        component: TeamSettings,
      },
      {
        path: "bank-settings",
        name: "BankSettings",
        component: BankSettings,
      },
    ]
  },
  {
    path: "scholars",
    name: "Scholars",
    component: Scholars,
    redirect:{name: 'scholar-list'},
    children:[
      {
        path: "",
        name: "scholar-list",
        component: ScholarList,
      },

      {
        path: ":id",
        name: "scholar-profile",
        component: ScholarProfile,
      },
    ]
  },
  {
    meta: {
      heading: "Sponsors",
    },
    path: "sponsors",
    name: "Sponsors",
    component: Sponsors,
    redirect:{name:'sponsor-list'},
    children:[
      {
        path: "",
        name: "sponsor-list",
        component: SponsorList,
      },

      {
        path: ":id",
        name: "sponsor-profile",
        component: SponsorProfile,
      },

    ]
  },
  {
    path: "communications",
    name: "Communications",
    component: Communications,
  },
  {
    meta: {
      heading: "Payments",
    },
    path: "payments",
    name: "Payments",
    component: Payments,
    redirect:{name:'Inflow'},
    children:[
      {
        path: "inflow",
        name: "Inflow",
        component: InflowPayments,
      },
      {
        path: "outflow",
        name: "Outflow",
        component: OutflowPayments,
      },
      {
        path: "payment-request",
        name: "paymentRequest",
        component: PaymentRequests,
      },
    ]


  },
  {
    path: "add-sponsor",
    name: "add-sponsor",
    component: AddSponsor,
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardView,
    redirect: { name: "Overview" },
    children: [
      ...(navRoutes as Array<RouteRecordRaw>),
      // { path: '/:catchAll(.*)', name: '', component: FeatureUnderDevelopment }
    ],
  },
  {
    path: "/verify-email",
    name: "verify-email",
    component: EmailVerificationView,
  },
  {
    path: "/partner/:id",
    name: "partner",
    component: Partner,
  },
  {
    path: "/scholarships",
    name: "public-scholarships",
    component: PublicScholarshipList,
  },

  {
    path: "/scholarships/:id",
    name: "public-show-scholarship",
    component: PublicShowScholarship,
  },

  {
    path: "/scholarships/:id/sponsor",
    name: "sponsor-scholarship",
    component: SponsorScholarship,
  },
  {
    path: "/scholarships/:id/apply",
    name: "apply-scholarship",
    component: ApplyToScholarship,
  },

  {
    path: "/application-sent",
    name: "application-sent",
    component: ApplicationSucceful,
  },
  {
    path: "/payment-received",
    name: "payment-received",
    component: PaymentSuccessful,
  },
  {
    path: "/sponsor-scholar",
    name: "sponsor-scholar",
    component: SponsorScholar,
  },



  {
    path: "/email-confirmed",
    name: "email-confirmed",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@/views/EmailConfirmedView.vue"
      ),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "@/views/ForgotPassword.vue"
      ),
  },
  {
    path: "/reset-password/:userId",
    name: "reset-password",
    component: ResetPassword
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
        import(
            /* webpackChunkName: "forgot-password" */ "@/views/Policy.vue"
            ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
