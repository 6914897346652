<template>
  <div class="h-full bg-gray-50">
    <div class="h-20 bg-white">
      <navbar />
    </div>

    <div class="main__content">
      <div class="flex flex-col sm:justify-center items-center px-4 sm:pt-0 ">
        <div class="w-full sm:max-w-md bg-white shadow-sm mt-20 rounded-t-lg">
           <img v-if="route.query.status === 'failed' " src="../assets/success-image.png">
           <img v-else  src="../assets/failed-image.png">
        </div>
        <div class="w-full sm:max-w-md bg-white text-center px-8">
           <div class="my-8" v-if="route.query.status === 'failed'">
            <p class="text-[20px] font-medium">Payment successful</p>
             <p class="text-gray-500 text-[18px]">A receipt has been sent to fa *********@gmail.com. </p>
           </div>
          <div class="my-8" v-else>
            <p class="text-[20px] font-medium">Payment Failed</p>
            <p class="text-gray-500 text-[18px]">Your payment was unsuccessful. Your account was not charged. </p>
          </div>
          <router-link class=" mb-16 inline-flex items-center px-5 py-4 border border-gray-300 shadow-sm  leading-4 font-medium rounded-md text-white ml-3 bg-black hover:bg-gray-700 focus:outline-none " :to="{name:'public-scholarships'}">
            Back to home
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import navbar from "@/components/Navbar";
export default {
  components: { navbar },
  setup(){
    const route = useRoute();
    console.log(route.query)
    return {route};
  }
};
</script>

<style scoped>
.main__content {
  min-height: calc(100vh - 64px);
  overflow: hidden;
}
</style>
