<template>
    <button @click="$router.back()" type="button" class="inline-flex  items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="20" viewBox="0 0 20 20" width="20"><g stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path d="m7.97484 4.94531-5.05834 5.05829 5.05834 5.0584"/><path d="m17.0831 10h-14.02499"/></g></svg>
      <span>Back to Overview</span>
    </button>

    <h2 class="text-xl my-10">Scholarship description</h2>
    <form v-if="activeScholarship" id="descriptionForm" action="#" @submit.prevent="editScholarshipDescription($event,activeScholarship.id,moveForward)"  method="POST">
      <input type="hidden" name="_method" value="PUT">
      <div class="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-3 col-span-6 order-2 md:order-1">

          <div class="sm:col-span-3">
            <label for="first-name" class="block text-sm font-medium text-gray-700">
              Title
            </label>
            <div class="mt-1">
              <input type="text" v-model="activeScholarship.title" required name="title"  id="title" placeholder="Enter your donation title" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="mt-4">
            <label for="description" class="block text-sm font-medium text-gray-700">
              Description
            </label>
            <div class="mt-1">
              <textarea id="description" v-model="activeScholarship.description" required name="description" placeholder="Enter a description..." rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="mt-4">
            <label for="frequency" class="block text-sm font-medium text-gray-700">
              Frequency
            </label>
            <div class="mt-1">
              <select id="frequency" required name="frequency" v-model="activeScholarship.frequency"  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                <option value="" selected>Select an option</option>
                <option>One-time</option>
                <option>Monthly</option>
                <option>Annually</option>
              </select>
            </div>
          </div>

          <div class="mt-4">
            <label for="first-name" class="block text-sm font-medium text-gray-700">
              Price
            </label>
            <div class="relative mt-1 rounded-md shadow-sm">
              <input type="text" v-model="activeScholarship.amount" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"  name="amount" required  @keyup="format" id="price" class="block w-full rounded-md border-gray-300  pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Enter amount here" />
              <div class="absolute inset-y-0 right-0 flex items-center">
                <label for="currency" class="sr-only">Currency</label>
                <select id="currency" v-model="activeScholarship.currency" name="currency" class="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                  <option>USD</option>
                  <option>UGX</option>
                  <option>NGN</option>
                </select>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <label for="winners" class="block text-sm font-medium text-gray-700">
              Winners
            </label>
            <div class="mt-1">
              <input type="number" name="winners" v-model="activeScholarship.winners" required id="winners"  placeholder="Enter Value" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

        </div>
        <div class="sm:col-span-3 col-span-6 order-1 md:order-2  flex ">
          <RoundProfileImage class="mr-6"  :size="12" :url="activeScholarship.image" :username="activeScholarship.title"/>
          <ImageUpload class="grow self-start"/>
        </div>

      </div>
      <!--    buttons-->
      <div class="mt-8 flex justify-between">

        <router-link :to="{name:'Overview'}" type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
          <span class="md:inline-block">Cancel</span>
        </router-link>
        <div>
          <button type="button" class="hidden inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
            <span class="md:inline-block">Save to draft</span>
          </button>

          <button type="submit" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3 bg-black hover:bg-gray-700 focus:outline-none ">
            <span class="md:inline-block">Continue</span>
          </button>

        </div>

      </div>
    </form>
</template>

<script>
import {activeScholarship, getScholarshipById, editScholarshipDescription} from "@/store/scholarship";
import {onMounted, watch} from "vue";
import router from "@/router";
import ImageUpload from "@/components/elements/ImageUpload";
import {format} from "@/helpers/input";
import {useRoute} from "vue-router";
import RoundProfileImage from "@/components/elements/Images/RoundProfileImage";

export default {
  components:{ImageUpload,RoundProfileImage},
  setup(){
    const route = useRoute();
    const moveForward = () => {
      router.push({name:'edit-scholarship-eligibility'})
    }
    onMounted(()=>{
      activeScholarship.value = null;
      getScholarshipById(route.params.id)
    })

    return {editScholarshipDescription,moveForward,format,activeScholarship}
  }
}
</script>

<style scoped>

</style>