
import { defineComponent } from "vue";
import Navbar from "../Navbar.vue";
export default defineComponent({
  components: { Navbar },
  props: {
    title: {
      type: String,
      default: "Sign up to your account",
    },
    subtitle: {
      type: String,
      default: "Let’s get you started. Create an account to begin.",
    },
    icon: {
      type: String,
      default: "steward-logo.png",
    },
    icon_link: {
      type: String,
      default: "/",
    },
    show_title: {
      type: Boolean,
      default: true,
    },
    show_icon_with_router: {
      type: Boolean,
      default: false,
    },
  },
  name: "OnboardingLayout",
});
