<template>
  <div class="bg-white p-4 rounded-xl">
    <span>Quick actions</span>
    <div
      class="flex flex-wrap md:flex-nowrap justify-center md:justify-between items-center my-6"
    >
      <router-link
          v-if="user.user_type === 'sponsor' || user.user_type === 'partner' "
        :to="{name:'create-scholarship-description'}"
        class="w-full p-4 border rounded-lg border-success-600 bg-[#039855] md:mr-6  min-h-[186px] text-white mb-6 sm:mb-0 self-stretch"
      >
        <div class="flex  justify-between flex-col p-4 bg-books bg-no-repeat bg-right-bottom">
          <div class="flex items-start">
            <div class="">
              <div class=" font-medium text-base">
                Create a scholarship
              </div>
              <div class=" font-normal text-sm my-3">
                Add your scholarships to support students across africa
              </div>
            </div>
          </div>
          <button type="submit" class="flex  self-baseline items-center px-3 py-2  shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none ">
            <span class="md:inline-block">Create new scholarship</span>
          </button>
        </div>
      </router-link>

      <router-link
          v-if="user.user_type === 'partner' "
          :to="{name:'TeamSettings'}"
          class="w-full p-4 border rounded-lg border-blue-500  md:mr-6  min-h-[186px] text-white self-stretch"
      >
        <div class="flex  justify-between flex-col p-4 bg-team bg-no-repeat bg-right-bottom">
          <div class="flex items-start">
            <div class="">
              <div class=" text-black font-medium text-base">
                Invite Team members
              </div>
              <div class=" text-gray-600 font-normal text-sm my-3">
                Invite your team to stewards, share feedbacks and work together to create the best scholarships
              </div>
            </div>
          </div>
          <button type="submit" class="flex  self-baseline items-center px-3 py-2  shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none ">
            <span class="md:inline-block">Invite now</span>
          </button>
        </div>
      </router-link>

      <router-link
          v-if="user.user_type === 'sponsor'"
        :to="{name:'public-scholarships'}"
        class="w-full p-4 border md:mx-4 mt-4 md:m-0 rounded-lg border-[#1570EF] min-h-[186px] self-stretch"
      >
        <div class="flex items-center justify-between bg-books">
          <div class="flex items-start justify-between">
            <img src="@/assets/ui-icons/moneys.svg" alt="sms-icon" />
            <div class="pl-2">
              <div class="text-black-500 font-medium text-base">
                Make a donation
              </div>
              <div class="text-gray-500 font-normal text-sm">
                 Make a Contribution to any Scholarship
              </div>
            </div>
          </div>
          <img src="@/assets/ui-icons/arrow-right-1.svg" alt="arrow-right" />
        </div>
      </router-link>
<!--      <router-link-->
<!--          v-if="user.user_type === 'partner'"-->
<!--        :to="{name:'partner', params:{id:user.slug}}"-->
<!--        class="w-full p-4 border mt-4 md:m-0 rounded-lg border-[#1570EF] min-h-[118px]"-->
<!--      >-->
<!--        <div class="flex items-center justify-between">-->
<!--          <div class="flex items-start justify-between">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-grid text-gray-500" viewBox="0 0 16 16">-->
<!--              <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>-->
<!--            </svg>-->

<!--            <div class="pl-2">-->
<!--              <div class="text-black-500 font-medium text-base">-->
<!--                Public scholarship page-->
<!--              </div>-->
<!--              <div class="text-gray-500 font-normal text-sm">-->
<!--                All scholarships will be displayed on your scholarship page-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <img src="@/assets/ui-icons/arrow-right-1.svg" alt="arrow-right" />-->
<!--        </div>-->
<!--      </router-link>-->
    </div>
    <div class="border border-[#EAECF0] px-2 pt-4 rounded-lg">
      <div class="flex items-center justify-between flex-wrap md:flex-nowrap">
        <div class="text-black-500 font-medium text-[18px] p-2 ">
          Scholarship Overview
        </div>
      </div>
      <div
          v-if="user.user_type === 'sponsor' || user.user_type === 'partner'"
        class="border-t border-[#EAECF0] my-2 py-2 flex flex-row flex-wrap items-center justify-between"
      >
        <div class="w-full md:w-1/2 flex flex-wrap sm:flex-nowrap items-center">
          <div
            class="flex flex-col border-r border-[#EAECF0] ml-6 my-4 w-full sm:w-1/2"
          >
            <span class="text-sm text-gray-500">Available Funds</span>
            <span class="text-2xl text-black pt-5">{{overview.available_funds}}</span>
          </div>
          <div
            class="flex flex-col border-r border-[#EAECF0] ml-6 my-4 w-full sm:w-1/2"
          >
            <span class="text-sm text-gray-500">Scholarships Given</span>
            <span class="text-2xl text-black pt-5">{{overview.scholarships_given}}</span>
          </div>
        </div>
        <div class="w-full md:w-1/2 flex flex-wrap sm:flex-nowrap items-center">
          <div
            class="flex flex-col border-r border-[#EAECF0] ml-6 my-4 w-full sm:w-1/2"
          >
            <span class="text-sm text-gray-500">Scholarship Applicants</span>
            <span class="text-2xl text-black pt-5">{{overview.scholarship_applicants}}</span>
          </div>
          <div class="flex flex-col border-[#EAECF0] ml-6 my-4 w-full sm:w-1/2">
            <span class="text-sm text-gray-500">Total Funds Donated</span>
            <span class="text-2xl text-black pt-5">{{overview.total_funds_donated}} </span>
          </div>
        </div>
      </div>

      <div
          v-if="user.user_type === 'scholar'"
          class="border-t border-[#EAECF0] my-2 py-2 flex flex-row flex-wrap items-center justify-between"
      >
        <div class="w-full md:w-1/2 flex flex-wrap sm:flex-nowrap items-center">
          <div
              class="flex flex-col border-r border-[#EAECF0] ml-6 my-4 w-full sm:w-1/2"
          >
            <span class="text-sm text-gray-500">No of Scholarships won</span>
            <span class="text-2xl text-black pt-5">0</span>
          </div>
          <div
              class="flex flex-col border-r border-[#EAECF0] ml-6 my-4 w-full sm:w-1/2"
          >
            <span class="text-sm text-gray-500">Scholarship Applications</span>
            <span class="text-2xl text-black pt-5">0</span>
          </div>
        </div>
        <div class="w-full md:w-1/2 flex flex-wrap sm:flex-nowrap items-center">
          <div
              class="flex flex-col border-r border-[#EAECF0] ml-6 my-4 w-full sm:w-1/2"
          >
            <span class="text-sm text-gray-500">Total scholarships won</span>
            <span class="text-2xl text-black pt-5">0</span>
          </div>
          <div class="flex flex-col border-[#EAECF0] ml-6 my-4 w-full sm:w-1/2">
            <span class="text-sm text-gray-500">Total Funds received</span>
            <span class="text-2xl text-black pt-5">0</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent, onMounted} from "vue";
import {getOverview,overview} from "@/store/partner";
import { ref} from "vue";
export default defineComponent({
  name: "ScholarshipOverview",
  setup() {
    const startDate = ref(null);
    const endDate = ref(null);
    const startDateConfig = ref({ minDate: "today" });
    const user = JSON.parse(localStorage.getItem('user'));

    onMounted(()=>{
      getOverview();
    })
    return {
      startDate,
      endDate,
      startDateConfig,
      user,
      overview,
    };
  },
});
</script>

