
import { defineComponent } from "vue";
import AnimatedHamburger from "@/components/AnimatedHamburger.vue";
import RoundProfileImage from "@/components/elements/Images/RoundProfileImage.vue";
import ProfileDropdown from "@/components/layout/dashboard/ProfileDropdown.vue";
export default defineComponent({
  name: "DashboardHeader",
  components: {
    AnimatedHamburger,
    RoundProfileImage,
    ProfileDropdown,
  },
  setup(){
      const currentUser =JSON.parse(localStorage.getItem('user') || '');
      return {
        currentUser
      }
  },

  computed: {
    // ...mapGetters(['currentUser', 'isAdmin']),
    // school(): TSchool | undefined {
    //     if (!this.currentUser) return undefined;
    //     return this.currentUser.school;
    // },
    heading(): string {
      return this.$route.meta.heading as string;
    },
  },
  props: {
    toggleSidebar: {
      type: Function,
      required: true,
    },
    sidebarActive: {
      type: Boolean,
      required: true,
    },
  },
});
