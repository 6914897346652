const host =  process.env.VUE_APP_API_URL;
const ui   = process.env.VUE_APP_UI_URL;

const encodeDataToURL = (data :any) => {
    return Object
        .keys(data)
        .map(value => `${value}=${encodeURIComponent(data[value])}`)
        .join('&');
}

export   {host,encodeDataToURL,ui};