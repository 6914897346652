import {host} from "@/helpers/api";
import {ref} from 'vue';
import {activePartner} from "@/store/partner";
const countries  = ref();


 const getCountries = async ()  => {
    const res =  await fetch(host+'/public/countries',
        {
            headers:{'Accept':'application/json, text/plain',},

        });
    const data = await res.json();
    if (res.ok){
        countries.value = data.data;
    }
}


export {countries,getCountries}