
import { defineComponent } from "vue";
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";
import {logOut} from "@/store/auth";
// import { VoidFunction } from '@/types/index';
// import { links } from "@/router/index";
// import { mapActions, mapGetters, mapMutations } from 'vuex';

type TOption = {
  href?: string;
  isButton?: boolean;
  label: string;
  //   onClick?: VoidFunction;
};

export default defineComponent({
  name: "ProfileDropdown",
  data() {
    return {
      //   links,
    };
  },
  components: { Menu, MenuButton, MenuItems },
  computed: {
    // ...mapGetters(['isAdmin', 'currentUser']),
    options(): Array<TOption> {
      const generalOptions = [
        { href: "Overview", label: "View profile" },
        { href: "settings", label: "Settings" },
        // { href: "settings", label: "Support" },
      ];

      return [...generalOptions];
    },
  },
  methods: {
    logOutUser() {
     logOut();
    },
    // ...mapActions(['logOut']),
    // ...mapMutations(['createToast']),
    // copySchoolInvite() {
    //     const link = this.currentUser?.school?.details?.invite_link;
    //     if (!link) return this.createToast({
    //         message: `Something went wrong. Reload this page and try again. If the problem persists, contact ${this.isAdmin ? 'the Steward team' : 'your organisation\'s admin'}.`,
    //         type: 'error'
    //     });
    //     window.navigator.clipboard.writeText(link);
    //     this.createToast({
    //         message: 'Invite link copied successfully!',
    //         type: 'success'
    //     });
    // }
  },
});
