
import { defineComponent } from "vue";

export default defineComponent({
  name: "RoundProfileImage",
  props: {
    size: {
      type: Number,
      default: 8,
    },
    url: {
      type: String,
    },
    username: {
      type: String,
    },
    style: {
      type: Object as () => Record<string, any>,
    },
    nullUser: Boolean,
  },
  computed: {
    dimensions() {
      return `w-${this.size} h-${this.size}`;
    },
  },
});
