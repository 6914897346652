<template>
  <div v-if="!user" class="w-full fixed z-50">
    <nav
      id="home"
      class="px-8 xl:px-[112px] py-[18px] flex items-center justify-between bg-white border-b border-gray-300"
    >
      <div
        @click="$router.push({ name: 'home' })"
        class="flex sm:gap-x-3 xl:gap-x-[54px] items-center"
      >
        <div class="space-x-[5px] flex items-center hidden xl:flex">
          <div
            class="overflow-hidden flex justify-center items-center rounded-full"
            style="width: 32px; height: 32px"
          >
            <img
              class="w-full h-full object-contain"
              src="../assets/steward-logo.png"
            />
          </div>
          <div class="text-black font-bold text-base">Steward</div>
        </div>
        <div class="space-x-[5px] flex items-center xl:hidden">
          <div
            class="overflow-hidden flex justify-center items-center rounded-full"
            style="width: 32px; height: 32px"
          >
            <img
              class="w-full h-full object-contain"
              src="../assets/steward-logo.png"
            />
          </div>
          <!---->
        </div>
      </div>
      <div class="flex items-center space-x-7 xl:space-x-[30px]">
        <!--        <router-link-->
        <!--          class="text-base text-gray-500 font-medium whitespace-nowrap hidden md:block"-->
        <!--          :to="{name:'public-scholarships'}"-->
        <!--        >-->
        <!--          Scholarships-->
        <!--        </router-link>-->
        <!--        <router-link-->
        <!--          class="text-base text-gray-500 font-medium whitespace-nowrap"-->
        <!--          to="/login"-->
        <!--        >-->
        <!--          Log in-->
        <!--        </router-link>-->

        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdAIO--jEsER0tbLHVvQBCBuEittEPzN1em-Gw5GDMyeIqyrg/viewform"
        >
          <button
            type="button"
            class="gap-x-2 bg-black hover:opacity-80 transition duration-300 rounded-lg flex justify-center items-center h-11 w-[133px]"
          >
            <span class="text-white">Join waitlist</span>
          </button>
        </a>
      </div>
    </nav>
  </div>
  <DashboardHeader v-else></DashboardHeader>
</template>

<script>
import DashboardHeader from "@/components/layout/dashboard/DashboardHeader";
import { user } from "@/store/auth";
import { onMounted } from "vue";
export default {
  name: "NavbarComponent",
  components: { DashboardHeader },
  setup() {
    onMounted(() => {
      console.log(user);
    });
    return {
      user,
    };
  },
};
</script>

<style scoped></style>
