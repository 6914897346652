<template>
  <navbar></navbar>
  <div class="sm:px-[80px] py-[86px] mx-auto max-w-[100rem] px-3 ">
    <h1 class="font-medium text-[24px] text-black ">Sponsor a scholar</h1>

    <div  class="sm:grid grid-cols-2 gap-[46px] mt-[42px]">
      <div>
        <h2 class="text-black font-medium">Scholarship overview</h2>
        <ScholarshipDetailCard class="my-3" :scholarship="activeScholarship" v-if="activeScholarship"  :hide="true"/>

        <h2 class="text-black font-medium mb-4">Scholarship overview</h2>

        <UserProfileSummary/>

      </div>
      <div>
        <h2 class="text-black font-medium mb-10">Sponsor overview</h2>
        <UserProfileSummary/>

        <h2 class="text-black font-medium my-10">Payment details</h2>

        <div class="bg-gray-100 rounded-lg p-3 ">
          <p class="text-xs">Amount to pay </p>
          <span class="text-black text-[36px]">$75,000</span>
          <p class="text-xs text-gray-500">This scholarship aims to advance the social, political, and economic well-being of students pursuing higher </p>
        </div>

                      <div  class="mt-4">
                        <label for="fees_paid_by" class="block text-sm font-medium text-gray-700">
                          Payment type
                        </label>
                        <div class="mt-1">
                          <input type="hidden" name="fees_paid_by" v-model="payment_type">
                          <SelectElement  :border="true"  @updated='val => payment_type = val'  :items="['Card','Mobile']" placeholder="Select option" />
                        </div>
                      </div>

        <div class="bg-yellow-50 p-4 my-8">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <rect width="32" height="32" rx="16" fill="#F79009"/>
                <path d="M16 13.2188V14.8838" stroke="#FCFCFD" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M16.0099 11C14.1699 11 12.6799 12.49 12.6799 14.33V15.38C12.6799 15.72 12.5399 16.23 12.3649 16.52L11.7299 17.58C11.3399 18.235 11.6099 18.965 12.3299 19.205C14.7199 20 17.3049 20 19.6949 19.205C20.3699 18.98 20.6599 18.19 20.2949 17.58L19.6599 16.52C19.4849 16.23 19.3449 15.715 19.3449 15.38V14.33C19.3399 12.5 17.8399 11 16.0099 11Z" stroke="#FCFCFD" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M17.665 19.4062C17.665 20.3213 16.915 21.0712 16 21.0712C15.545 21.0712 15.125 20.8813 14.825 20.5813C14.525 20.2813 14.335 19.8613 14.335 19.4062" stroke="#FCFCFD" stroke-miterlimit="10"/>
              </svg>
            </div>
            <div class="ml-3 ">
              <p class="text-sm text-gray-700 text-black">
                <span class="font-medium">Note: </span>
                you will be charged for any transaction fee associated with this payment by the payment provider
              </p>
            </div>
          </div>
        </div>

        <button  type="submit" class="flex w-full justify-center items-center px-3 py-4 border border-gray-300 shadow-sm text-base leading-4 font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none ">
          <span class="md:inline-block">Make payment</span>
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import ScholarshipDetailCard from "@/components/ScholarshipDetailCard.vue";
import {onMounted, ref,onBeforeMount} from "vue";
import {activeScholarship, getScholarshipById} from "@/store/scholarship";
import UserProfileSummary from "@/components/elements/UserProfileSummary.vue";
import SelectElement from "@/components/elements/SelectElement.vue";
import { loadStripe } from '@stripe/stripe-js'
import {StripeElements,StripeElement} from "vue-stripe-js";
export default {
  components:{UserProfileSummary, navbar,ScholarshipDetailCard,SelectElement},
  name: "SponsorScholar",
  setup () {
const payment_type = ref();
    const stripeLoaded = ref(false)
    const stripeKey = ref('pk_test_51LT7wKJFortv1gMjl2dqqCIW1rd5f36UFlDoQfaQudXtOHo30UIOUwKlLifPsOeyCmLdza1FVi63V2nGl7gSSKlO00dstRzeEi')
    const instanceOptions = ref({
      // https://stripe.com/docs/js/initializing#init_stripe_js-options
    })
    const elementsOptions = ref({
      // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
    })
    const cardOptions = ref({
      // https://stripe.com/docs/stripe.js#element-options
      value: {
        postalCode: '12345',
      },
    })
    const card = ref()
    const elms = ref()

    const  pay =  () => {
      // Get stripe element
      const cardElement = card.value.stripeElement

      // Access instance methods, e.g. createToken()
      elms.value.instance.createToken(cardElement).then((result) => {
        // Handle result.error or result.token
        console.log(result)
      })
    };

onBeforeMount(()=>{
  const stripePromise = loadStripe(stripeKey.value)
  stripePromise.then(() => {
    stripeLoaded.value = true
  })
});

  onMounted(()=>{
   getScholarshipById('0623b4be-4da9-4a47-89e6-01de0fc2a2c8')
  });


    return {activeScholarship,
      payment_type,stripeLoaded,
      stripeKey,
      instanceOptions,
      elementsOptions,
      cardOptions,
      card,
      elms,
      pay}
  }
}
</script>

