import http from "@/http";

export const countriesAPI = async () => {
  const response = await http.get(`/public/countries`);
  return response;
};

export const registerAPI = async (data: object) => {
  return await http.post("/auth/register", data);
};
export const forgotPasswordAPI = async (data: object) => {
  return await http.post("/auth/forgot", data);
};
export const resetPasswordAPI = async (userId: string, data: object) => {
  return await http.post(`reset-password/${userId}`, data);
};
export const loginAPI = async (data: object) => {
  return await http.post("/auth/login", data);
};
