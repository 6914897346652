<template>
  <onboarding-layout
    title="Log in to your account"
    subtitle="Welcome back! Sign into your account to continue"
  >
    <template v-slot:form-content>
      <form method="POST" @submit.prevent="loginUser">
        <!-- Email Address -->
        <div>
          <label class="block font-medium text-sm text-gray-700" for="email">
            Email
          </label>

          <input
            v-model="email"
            class="text_field_input"
            id="email"
            type="email"
            name="email"
            required
            autofocus="autofocus"
            placeholder="Enter your email"
          />
        </div>

        <!-- Password -->
        <div class="mt-4 relative">
          <label class="block font-medium text-sm text-gray-700" for="password">
            Password
          </label>

          <div class="relative password_container">
            <input
              v-model="password"
              class="text_field_input"
              id="password"
              type="password"
              name="password"
              required
              autocomplete="do-not-autofill"
              placeholder="******"
            />
            <div
              onclick="document.getElementById('password').setAttribute('type','text'); document.getElementById('password').parentElement.classList.toggle('show-icon')"
              class="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              <svg
                class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                ></path>
                <path
                  d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                ></path>
              </svg>
            </div>

            <div
              onclick="document.getElementById('password').setAttribute('type','password');document.getElementById('password').parentElement.classList.toggle('show-icon')"
              class="absolute inset-y-0 right-0 pr-3 flex items-center hidden"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
                viewBox="0 0 16 16"
              >
                <path
                  d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"
                ></path>
                <path
                  d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"
                ></path>
                <path
                  d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"
                ></path>
              </svg>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between mt-5">
          <div class="flex items-center">
            <label for="remember_me" class="inline-flex items-center">
              <input
                id="remember_me"
                type="checkbox"
                class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                name="remember"
              />
              <span class="ml-2 text-sm text-gray-600">Remember me</span>
            </label>
          </div>

          <div class="text-sm">
            <router-link
              class="text-sm text-gray-600 hover:text-gray-900"
              to="/forgot-password"
            >
              Forgot your password?
            </router-link>
          </div>
        </div>

        <div class="flex items-center justify-end mt-4">
          <button type="submit" class="block-button">Sign in</button>
        </div>
      </form>
      <div class="my-4 text-center" data-v-03589122="">
        You dont have an account?
        <router-link
          class="font-bold text-gray-600 hover:text-gray-900"
          to="/register"
          data-v-03589122=""
        >
          Register
        </router-link>
      </div>
    </template>
  </onboarding-layout>
</template>

<script>
import { loginAPI } from "@/api/auth";
import {user} from "@/store/auth";
import { ref } from "vue";
import { useRouter } from "vue-router";
import onboardingLayout from "@/components/onboarding/onboardingLayout.vue";
import { notify } from "@kyvg/vue3-notification";

export default {
  components: { onboardingLayout },
  name: "LoginView",
  async setup() {
    const router = useRouter();
    const email = ref("");
    const password = ref("");

    const loginUser = async () => {
      if (!email.value && !password.value) return false;

      try {
        const { data } = await loginAPI({
          email: email.value,
          password: password.value,
        });
        if (!data.success) {
          throw new Error();
        }

        user.value = data.data.user;
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("user", JSON.stringify(data.data.user));

        router.replace("/dashboard");
      } catch (err) {
        notify({
          title: "Invalid username and password",
          type:'error'
        });
      }
    };
    return { loginUser, email, password };
  },
};
</script>

<style scoped></style>
