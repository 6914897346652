<template>
<div class="rounded-lg border border-purple-500 px-4 py-3 bg-white md:max-w-[60%] bg-white w-full">
    <h3 class="font-semibold">Get your public scholarship link</h3>
     <p class="text-gray-500 mt-2">All scholarships will be displayed on your scholarship page</p>
  <div class="flex justify-between mt-3 flex-col sm:flex-row ">
    <div class="grow">
      <label for="company-website" class="block text-sm font-medium text-gray-700">Company Website</label>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 border-r pr-1 flex items-center pointer-events-none">
        <span class="text-gray-500 sm:text-sm ">
          http://
        </span>
        </div>
        <input   type="text" :value="ui+path.fullPath" name="company-website" readonly id="link-url" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md" placeholder="www.example.com" />
      </div>
    </div>
    <div class="flex self-end mt-5 sm:mt-0">
      <button @click="copyLink" type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3 bg-black hover:bg-gray-700 focus:outline-none ">
        <span class="md:inline-block">Copy Link</span>
      </button>

      <button @click="share(scholarship.title,ui+path.fullPath)" type="button" class="inline-flex ml-3 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none ">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="22" viewBox="0 0 20 22" width="20"><path d="m6.59 12.51 6.83 3.98m-.01-10.98-6.82 3.98m12.41-5.49c0 1.65685-1.3431 3-3 3s-3-1.34315-3-3 1.3431-3 3-3 3 1.34315 3 3zm-12 7c0 1.6569-1.34315 3-3 3s-3-1.3431-3-3c0-1.65685 1.34315-3 3-3s3 1.34315 3 3zm12 7c0 1.6569-1.3431 3-3 3s-3-1.3431-3-3 1.3431-3 3-3 3 1.3431 3 3z" stroke="#667085" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></svg>
        <span class="md:inline-block">Share</span>
      </button>



    </div>

  </div>
</div>
</template>

<script>

import {copyTextToClipboard} from "@/helpers/input";
import router from "@/router";
import {ui} from "@/helpers/api";
import {share} from "@/helpers/share";
export default {
  name: "CopyPublicLink",
  props:['scholarship'],
  setup(props){
    const path = router.resolve({
      name: 'public-show-scholarship',
      params: { id: props.scholarship.id},
    });
    const copyLink = () => {
      copyTextToClipboard(document.getElementById('link-url').value);
    }
    return {copyLink,path,ui,share}
  }
}
</script>

<style scoped>

</style>