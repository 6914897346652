<template>
<div class="border rounded-lg p-5" v-if="scholarship" >
   <div class="flex ">
     <div class="w-[120px] h-[120px] shrink-0">
       <img :src="scholarship.image" class="w-full h-full object-cover">
     </div>
     <div class="ml-4 w-full">
       <div class="flex justify-between"><h3 class="text-gray-800 font-bold grow">{{scholarship.title}}</h3>
<!--         <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-success-800"> Top picks </span>-->
       </div>
       <div class="flex grow items-center mt-3 lg:mb-0">
         <RoundProfileImage :size="5" :url="scholarship.partner?.logo" :username="scholarship.partner.business_name"  />
         <span class="ml-3 text-sm text-gray-700">
         {{scholarship.partner.business_name}}
       </span></div>
       <div class="bg-gray-100 rounded-lg p-[8px] mt-4 hidden md:block"><p class="gray-500"> {{scholarship.summary}}</p></div>
     </div>

   </div>
  <div class="flex mt-4 justify-between flex-wrap">
    <div class="flex flex-col"><span class="text-[12px] text-gray-600">Amount</span><span>{{scholarship.currency.short_code}} {{commaSeparator(scholarship.amount)}}</span></div>
    <div class="flex flex-col"><span
        class="text-[12px] text-gray-600">Scholarships awarded</span><span>{{scholarship.winners}} winners</span></div>
    <div class="flex flex-col mt-4 md:mt-0 mr-40" ><span
        class="text-[12px] text-gray-600">Education level</span><span v-if="scholarship.eligibility && scholarship.eligibility.education_level ">{{scholarship.eligibility.education_level}}</span> <span v-else>Any</span></div>
  </div>

  <div class="flex mt-8 flex-col sm:flex-row">
    <button @click="$router.push({name:'public-show-scholarship', params:{id:scholarship.id}})"  type="button" class="ml-[12px] justify-center inline-flex items-center px-16 py-2 border  shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
      <span class="inline-block">View Scholarship</span>
    </button>
    <button v-if="scholarship.external_sponsorship" @click="$router.push({name:'sponsor-scholarship', params:{id:scholarship.id}})" type="button" class=" mt-4 md:mt-0 inline-flex ml-4 items-center justify-center  px-24 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
      <span class="inline-block">Donate</span>
    </button>

  </div>
</div>
</template>

<script>
import RoundProfileImage from "@/components/elements/Images/RoundProfileImage";
import {commaSeparator} from "@/helpers/display";
export default {
  name: "scholarshipDetailSummary",
  props:['scholarship'],
  components:{RoundProfileImage},
  setup(){
    return {commaSeparator}
  }
}
</script>

<style scoped>

</style>